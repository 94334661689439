export default class TransacaoHttpGateway {
    httpClient;

    basePath = "/gerenciamento-cartao-credito/transacao";

    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    async getAll() {
        const { data } = await this.httpClient.get(this.basePath);
        return data;
    }

    async getByCodigo(cd_transacao) {
        const { data } = await this.httpClient.get(`${this.basePath}/${cd_transacao}`);
        return data;
    }

    async getTransacoesCompras(cd_conta_bancaria = -1) {
        const { data } = await this.httpClient.get(`${this.basePath}/estornar`, {
            params: {
                cd_conta_bancaria,
            }
        });
        return data;
    }

    async getByMes(nr_mes = -1) {
        const { data } = await this.httpClient.get(this.basePath, {
            params: {
                nr_mes
            }
        });
        return data;
    }

    async create(body = {}) {
        const { data } = await this.httpClient.post(this.basePath, body);
        return data;
    }

    async update(id = -1, body = {}) {
        const { data } = await this.httpClient.patch(`${this.basePath}/${id}`, body);
        return data;
    }

    async remove(id = -1) {
        const { data } = await this.httpClient.delete(`${this.basePath}/${id}`);
        return data;
    }
}
