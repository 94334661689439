import Vue from "vue";
import Vuex from "vuex";

import { injectGatewaysStore, getChartsData, DateInstanceHelper } from "@helpers";

import {
    reservaEmergenciaStore as reservaEmergencia,
    calculationStore as calculation,
} from "@module/ReservaEmergencia";

import { authStore as auth } from "@module/Auth";

Vue.use(Vuex);

const value = localStorage.getItem("isHideValues");

const dateHelper = new DateInstanceHelper();
const currentYear = Number(dateHelper.getYear());

export default new Vuex.Store({
    plugins: [injectGatewaysStore],
    state: {
        currentYear,
        isLoading: false,
        keyStorage: "isHideValues",
        yearFilter: currentYear,
        isHideValues: value === "true",
        chartsData: {},
        sidebarIsClose: true,
    },
    mutations: {
        setIsLoading(state, payload) {
            state.isLoading = payload;
        },
        setIsHideValues(state, payload) {
            state.isHideValues = payload;
        },
        setChartsData(state, payload) {
            const copiaChartsData = { ...state.chartsData };
            copiaChartsData[state.yearFilter] = payload;
            state.chartsData = copiaChartsData;
        },
        setYearFilter(state, payload) {
            state.yearFilter = payload;
        },
        setSidebarIsClose(state, payload) {
            state.sidebarIsClose = payload;
        },
    },
    actions: {
        handleLoading(context, payload = false) {
            context.commit("setIsLoading", payload);
        },
        handleSidebarIsClose(context, payload = false) {
            context.commit("setSidebarIsClose", payload);
        },
        handleIsHideValues(context, payload = false) {
            context.commit("setIsHideValues", payload);
            localStorage.setItem("isHideValues", payload);
        },
        handleChartsData({ getters, commit }, force = false) {
            const { getYearFilter } = getters;
            const chartsDataIsTruthy = getters.getChartsData;
            if (!chartsDataIsTruthy || force) {
                const listAllItems = getters["reservaEmergencia/getListAllItems"];
                const reserveEmergencyValue = getters["calculation/getReserveEmergencyValue"];
                const chartsData = getChartsData(
                    getYearFilter,
                    listAllItems,
                    reserveEmergencyValue,
                );
                commit("setChartsData", chartsData);
            }
        },
        handleYearFilter(context, payload) {
            context.commit("setYearFilter", payload);
        },
    },
    getters: {
        getIsLoading: ({ isLoading }) => isLoading,
        getIsHideValues: ({ isHideValues }) => isHideValues,
        getChartsData: ({ chartsData, yearFilter }) => chartsData[yearFilter] || null,
        getYearFilter: ({ yearFilter }) => yearFilter,
        getSidebarIsClose: ({ sidebarIsClose }) => sidebarIsClose,
        getCurrentYear: (state) => state.currentYear,
    },
    modules: {
        reservaEmergencia,
        calculation,
        auth,
    },
});
