<template>
    <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="auto"
    >
        <template #activator="{ on, attrs }">
            <BaseTextField
                v-model="displayDateLocal"
                readonly
                v-bind="{ ...attrs, label: $attrs.label }"
                :hide-details="!validationEmpty"
                :validation-empty="validationEmpty"
                v-on="on"
            />
        </template>
        <v-date-picker
            ref="picker"
            v-model="vModel"
            locale="pt-BR"
            no-title
            :active-picker.sync="activePicker"
            @click:year="saveYear($event)"
        />
    </v-menu>
</template>

<script>
import { defineComponent } from "vue";

import { DateStringHelper } from "@helpers";

export default defineComponent({
    props: {
        value: {
            type: [Number, String],
            default: 0,
        },
        displayDate: {
            type: String,
            default: "",
        },
        validationEmpty: {
            type: Boolean,
            default: false,
        },
        closeOnContentClick: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            menu: false,
            activePicker: "YEAR",
        };
    },

    computed: {
        vModel() {
            return `${this.value}-01-01`;
        },
        displayDateLocal() {
            if (this.displayDate) return this.displayDate;
            let newValue = this.vModel;
            if (newValue) {
                const dateStringHelper = new DateStringHelper(newValue, "YYYY-MM-DD", "-");
                newValue = dateStringHelper.getYear();
            }
            return newValue;
        },
    },

    watch: {
        menu: {
            immediate: true,
            handler(val) {
                // eslint-disable-next-line no-unused-expressions
                val && this.setActivePickerToYear();
            },
        },
    },

    methods: {
        saveYear(year) {
            this.$refs.menu.save(year);

            // Reset activePicker to type YEAR
            this.setActivePickerToYear();

            // Close the menu/datepicker
            this.menu = false;

            this.$emit("input", year);
        },
        setActivePickerToYear() {
            setTimeout(() => {
                this.activePicker = "YEAR";
            });
        },
    },
});
</script>
