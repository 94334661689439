<template>
    <v-text-field
        v-bind="$attrs"
        outlined
        dense
        :rules="getRules"
        v-on="$listeners"
    >
        <template
            v-if="$slots['prepend-inner']"
            #prepend-inner
        >
            <slot name="prepend-inner" />
        </template>

        <slot v-else />
    </v-text-field>
</template>

<script>
export default {
    props: {
        validationEmpty: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        getRules() {
            let rules = [];
            if (this.validationEmpty) {
                rules.push((v) => !!v || `O campo ${this.$attrs.label} é obrigatório`);
            }
            if (this.$attrs.rules) {
                rules = [...rules, ...this.$attrs.rules];
            }
            return rules;
        },
    },
};
</script>
