<template>
    <v-btn
        v-bind="$attrs"
        v-on="{ ...events, ...$listeners }"
    >
        <template v-if="iconName">
            <v-icon v-show="isPrimary">
                {{ iconPrimary }}
            </v-icon>

            <v-icon v-show="!isPrimary">
                {{ iconSecondary }}
            </v-icon>
        </template>

        <slot v-else />
    </v-btn>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        iconName: {
            type: String,
            default: "",
        },
        suffixIcon: {
            type: String,
            default: "mdi",
        },
    },

    data() {
        return {
            events: {},
            iconPrimary: "",
            iconSecondary: "",
            isPrimary: true,
        };
    },

    computed: {
        getIconPrimary() {
            return `${this.suffixIcon}-${this.iconName}-outline`;
        },
        getIconSecondary() {
            return `${this.suffixIcon}-${this.iconName}`;
        },
    },

    mounted() {
        if (this.iconName) {
            this.iconPrimary = this.getIconPrimary;
            this.iconSecondary = this.getIconSecondary;
            this.events = {
                mouseenter: () => this.changeIcon(false),
                mouseleave: () => this.changeIcon(true),
            };
        }
    },

    methods: {
        changeIcon(isPrimary) {
            this.isPrimary = isPrimary;
        },
    },
});
</script>
