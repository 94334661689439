export default {
    namespaced: true,
    state: {
        arrPaginas: [],
    },
    mutations: {
        setArrPaginas(state, arrPaginas) {
            state.arrPaginas = arrPaginas;
        },
    },
    actions: {
        async fetchAll(context, sn_forcar_atualizar = false) {
            if (context.getters.getArrPaginasVazio || sn_forcar_atualizar === true) {
                const response = await this.$configuracoes.paginasHttpGateway.getAll();
                context.commit("setArrPaginas", response);
            }
        },
    },
    getters: {
        getArrPaginas: ({ arrPaginas }) => arrPaginas,
        getArrPaginasByPlanilha: ({ arrPaginas }) => (cd_planilha) => arrPaginas
            .filter(
                ({ cd_controle_migracao_planilha }) => cd_controle_migracao_planilha
                    === cd_planilha,
            ),
        getArrPaginasVazio: ({ arrPaginas }) => arrPaginas.length === 0,
    },
};
