<template>
    <BaseSheet>
        <v-data-table
            :headers="headers"
            :items="data"
            multi-sort
            :items-per-page="data.length"
        >
            <template #[`item.value`]="{ item }">
                {{ item.value | toCurrencyToLocaleString | hiddenValue }}
            </template>

            <template #[`item.threeMonths`]="{ item }">
                {{ item.threeMonths | toCurrencyToLocaleString | hiddenValue }}
            </template>

            <template #[`item.sixMonths`]="{ item }">
                {{ item.sixMonths | toCurrencyToLocaleString | hiddenValue }}
            </template>

            <template #[`item.twelveMonths`]="{ item }">
                {{ item.twelveMonths | toCurrencyToLocaleString | hiddenValue }}
            </template>

            <template #[`item.actions`]="{ item }">
                <BaseButton
                    icon
                    icon-name="pencil"
                    @click="editAction(item)"
                />

                <BaseButton
                    icon
                    icon-name="delete"
                    @click="deleteItem(item.id)"
                />
            </template>
        </v-data-table>
    </BaseSheet>
</template>

<script>
import { headersCalculationTable } from "../../constants";

export default {
    props: {
        data: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            headers: headersCalculationTable,
        };
    },

    async mounted() {
        try {
            this.$store.dispatch("handleLoading", true);
            await this.$store.dispatch("reservaEmergencia/getAll");
            this.$store.dispatch("handleLoading");
        } catch (e) {
            console.error(e);
            this.$toast.error("Não foi buscar a lista!");
        }
    },

    methods: {
        async editAction(item) {
            this.$store.dispatch("calculation/handleSaveModal", {
                isOpen: true,
                data: item,
            });
        },
        async deleteItem(id) {
            this.$store.dispatch("handleLoading", true);
            await this.$store.dispatch("calculation/delete", id);
            this.$store.dispatch("handleLoading");
        },
    },
};
</script>
