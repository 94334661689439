<template>
    <ContaBancariaNovaTransacaoSecao ds-titulo="Tipo">
        <v-radio-group
            v-model="vModel"
            color="vdPrimary"
            mandatory
            hide-details
            :disabled="snEditar === true"
        >
            <v-row>
                <ContaBancariaNovaTransacaoTipoCard
                    v-for="objTransacaoTipo of arrTransacoesTipo"
                    :key="objTransacaoTipo.cd_transacao_tipo"
                    :sn-desabilitar-adiantamento="snDesabilitarAdiantamento"
                    :sn-desabilitar-estorno="snDesabilitarEstorno"
                    :obj-transacao-tipo="objTransacaoTipo"
                    @change-value="(newValue) => vModel = newValue"
                />
            </v-row>
        </v-radio-group>
    </ContaBancariaNovaTransacaoSecao>
</template>

<script>
export default {
    props: {
        value: {
            type: [Number, String],
            default: "",
        },
        snEditar: {
            type: Boolean,
            default: false,
        },
        snDesabilitarAdiantamento: {
            type: Boolean,
            default: false,
        },
        snDesabilitarEstorno: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            arrTransacoesTipo: [],
        };
    },
    computed: {
        vModel: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },
    mounted() {
        this.getTransacoesTipo();
    },
    methods: {
        async getTransacoesTipo() {
            const arrTransacoesTipo = await this.$cartaoCredito.transacaoTipoHttpGateway.getAll();
            this.arrTransacoesTipo = arrTransacoesTipo;
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
