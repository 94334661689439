export default {
    namespaced: true,
    state: {
        arrCategorias: [],
    },
    mutations: {
        setArrCategorias(state, arrCategorias) {
            state.arrCategorias = arrCategorias;
        },
    },
    actions: {
        async fetchAll(context) {
            if (context.getters.getArrCategoriasVazio) {
                const response = await this.$configuracoes.categoriasHttpGateway.getAll();
                context.commit("setArrCategorias", response);
            }
        },
    },
    getters: {
        getArrCategorias: ({ arrCategorias }) => arrCategorias,
        getArrCategoriasByArrCdCategoria: ({ arrCategorias }) => (arrCdCategorias) => arrCategorias
            .filter(({ cd_categoria }) => arrCdCategorias.includes(cd_categoria)),
        getArrCategoriasVazio: ({ arrCategorias }) => arrCategorias.length === 0,
    },
};
