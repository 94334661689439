<template>
    <BaseModal
        v-model="sn_modal_aberto"
        max-width="550px"
    >
        <template #activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                color="vdPrimary"
                dark
                class="text-none"
                v-on="on"
            >
                <v-icon left>
                    mdi-plus
                </v-icon>

                Adicionar
            </v-btn>
        </template>

        <v-card>
            <BaseFormHeader
                ds-nome-tela="Histórico"
                :ds-nome-acao="ds_nome_acao"
            />
            <v-form
                ref="estudosForm"
                v-model="valid"
                style="padding: 1.5rem"
                lazy-validation
            >
                <v-row>
                    <v-col
                        cols="6"
                        class="pt-0"
                    >
                        <v-checkbox
                            v-model="sn_rendimento"
                            label="Rendimento"
                            hide-details
                            class="mt-0 pt-0"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                        cols="6"
                        class="pb-0"
                    >
                        <BaseTextField
                            v-model="payload.ds_descricao"
                            label="Descrição *"
                            validation-empty
                        />
                    </v-col>
                    <v-col
                        cols="6"
                        class="pb-0"
                    >
                        <BaseTextFieldMoney
                            ref="inputCurrency"
                            v-model="handleNrValor"
                            label="Valor *"
                            validation-empty
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                        cols="6"
                        class="pb-0"
                    >
                        <BaseDatePickerFormatted
                            v-model="handleDtData"
                            label="Data *"
                            validation-empty
                        />
                    </v-col>
                    <v-col
                        cols="6"
                        class="pb-0"
                    >
                        <BaseAutoComplete
                            v-model="handleTipo"
                            label="Tipo *"
                            item-value="cd_tipo"
                            item-text="ds_nome"
                            validation-empty
                            :items="arrTipos"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12">
                        <BaseTextArea
                            v-model="payload.ds_complemento"
                            label="Observações"
                            cols="2"
                            hide-details
                            rows="3"
                        />
                    </v-col>
                </v-row>
            </v-form>
            <BaseFormActions
                :sn-loading="sn_loading"
                @save="salvarAcao"
                @close="fecharModal"
            />
        </v-card>
    </BaseModal>
</template>

<script>
import { defineComponent } from "vue";
import { cleanCurrency } from "@helpers";

const defaultPayload = {
    ds_descricao: "",
    vl_valor: 0,
    dt_data: "",
    ds_complemento: "",
    sn_receita: false,
    sn_despesa: false,
    sn_rendimento: false,
    cd_cofrinho: -1,
};

export default defineComponent({
    props: {
        arrCartoes: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            payload: { ...defaultPayload },
            valid: true,
            sn_loading: false,
            sn_modal_aberto: false,
            ds_nome_acao: "Criar",
            vl_valor: "R$ 0,00",
            sn_rendimento: false,
            arrTipos: [
                {
                    cd_tipo: 1,
                    ds_nome: "Receita",
                },
                {
                    cd_tipo: 2,
                    ds_nome: "Despesa",
                },
            ],
        };
    },

    computed: {
        handleTipo: {
            get() {
                if (this.payload.sn_receita) {
                    return 1;
                }
                if (this.payload.sn_despesa) {
                    return 2;
                }
                return 0;
            },
            set(value) {
                this.payload.sn_receita = value === 1;
                this.payload.sn_despesa = value === 2;
            },
        },
        handleNrValor: {
            get() {
                return this.vl_valor;
            },
            set(value) {
                const cleanValue = cleanCurrency(value);
                this.vl_valor = value;
                this.payload.vl_valor = cleanValue;
            },
        },
        handleDtData: {
            get() {
                if (this.payload.dt_data) {
                    const [ano, mes, dia] = this.payload.dt_data.split("-");
                    return `${dia}/${mes}/${ano}`;
                }
                return "";
            },
            set(dt_data) {
                if (dt_data) {
                    const [dia, mes, ano] = dt_data.split("/");
                    const dt_data_formatada = `${ano}-${mes}-${dia}`;
                    this.payload.dt_data = dt_data_formatada;
                } else {
                    this.payload.dt_data = dt_data;
                }
            },
        },
    },

    watch: {
        sn_rendimento(value) {
            this.payload.sn_rendimento = value;
            if (value) {
                this.payload.ds_descricao = "Rendimentos";
                this.payload.sn_receita = true;
            }
        },
    },

    mounted() {
        this.payload.cd_cofrinho = +this.$route.params.cd_cofrinho;
    },

    methods: {
        fecharModal() {
            this.sn_modal_aberto = false;
            this.ds_nome_acao = "Criar";
            this.resetData();
            this.resetValidation();
        },

        getFormIsValid() {
            return this.$refs.estudosForm.validate();
        },

        resetValidation() {
            if (this.$refs.estudosForm) {
                this.$refs.estudosForm.resetValidation();
            }
        },

        async salvarAcao() {
            const formIsValid = this.getFormIsValid();
            if (formIsValid) {
                this.sn_loading = true;
                const ds_nome_acao = "criado";
                // if (this.payload.cd_estudo) {
                // await this.$cofrinhosHistoricosHttpGateway.update(
                //     this.payload.cd_estudo,
                //     this.payload,
                // );
                // ds_nome_acao = "editado";
                // } else {
                await this.$cofrinhosHistoricosHttpGateway.create(this.payload);
                // }
                this.$emit("atualizar-listagem");
                this.$toast.success(`Histórico ${ds_nome_acao} com sucesso!`);
                this.sn_loading = false;
                this.fecharModal();
            }
        },

        resetData() {
            this.payload = { ...defaultPayload };
            this.vl_valor = "R$ 0,00";
        },
    },
});
</script>
