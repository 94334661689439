<template>
    <div class="dashboard">
        <BaseHeaderPage class="dashboard__header" />

        <v-row>
            <v-col cols="3">
                <DashboardHeaderItem
                    icon="mdi-clock-star-four-points-outline"
                    title="Recorde"
                    color="vdPrimary"
                    :value="getDsTempo"
                />
            </v-col>
            <v-col cols="3">
                <DashboardHeaderItem
                    icon="mdi-chart-bell-curve"
                    title="Média geral"
                    color="indigo"
                    :value="getDsTempoMedia"
                />
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="6">
                <CuboMagicoMinimoDiaChart
                    :styles="{ width: '100%' }"
                    :data="objTempoMinimoDia.data"
                    :labels="objTempoMinimoDia.labels"
                />
            </v-col>

            <v-col cols="6">
                <CuboMagicoMaximoDiaChart
                    :styles="{ width: '100%' }"
                    :data="objTempoMaximoDia.data"
                    :labels="objTempoMaximoDia.labels"
                />
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="6">
                <CuboMagicoMediasDiaChart
                    :styles="{ width: '100%' }"
                    :data="objMediasTempoPorDia.data"
                    :labels="objMediasTempoPorDia.labels"
                />
            </v-col>

            <v-col cols="6">
                <CuboMagicoSolvesDiaChart
                    :styles="{ width: '100%', height: '100%' }"
                    :data="objSolvesDia.data"
                    :labels="objSolvesDia.labels"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import dayjs from "dayjs";
import durationPlugin from "dayjs/plugin/duration";

dayjs.extend(durationPlugin);

export default {
    data() {
        return {
            objMediasTempoPorDia: {
                labels: [],
                data: [],
            },
            objSolvesDia: {
                labels: [],
                data: [],
            },
            objTempoMinimoDia: {
                labels: [],
                data: [],
            },
            objTempoMaximoDia: {
                labels: [],
                data: [],
            },
            objRecorde: {
                ds_tempo: "",
            },
            objMediaGeral: {
                ds_tempo: "",
            },
        };
    },
    computed: {
        getDsTempo() {
            return this.getDsTempoFormatado(this.objRecorde.ds_tempo);
        },
        getDsTempoMedia() {
            return this.getDsTempoFormatado(this.objMediaGeral.ds_tempo);
        },
    },
    async mounted() {
        const data = await this.$cuboMagicoHttpGateway.dashboard();
        this.objMediasTempoPorDia = data.arrMediasTempoPorDia;
        this.objSolvesDia = data.arrTotalSolvesPorDia;
        this.objTempoMinimoDia = data.arrTempoMinimoPorDia;
        this.objTempoMaximoDia = data.arrTempoMaximoPorDia;
        this.objRecorde = data.objRecorde;
        this.objMediaGeral = data.objMediaGeral;
    },
    methods: {
        getDsTempoFormatado(ds_tempo) {
            const duration = dayjs.duration(Math.round(parseFloat(ds_tempo)));
            let format = "";
            // Verificar se há minutos e adicionar à string de formato
            if (duration.minutes() > 0) {
                format += "mm:";
            }
            // Sempre adicionar segundos e milissegundos à string de formato
            format += "ss.SSS";
            return duration.format(format);
        },
    },
};
</script>

<style lang="scss" scoped>
.dashboard {
    $ref: &;

    &__header {
        margin-bottom: 0.75rem;
    }
}
</style>
