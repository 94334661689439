<template>
    <BaseSheet>
        <BaseTable
            :headers="headers"
            :items="data"
            multi-sort
            :items-per-page="data.length"
        >
            <template #vl_valor="{ item }">
                {{ item.vl_valor | toCurrencyToLocaleString | hiddenValue }}
            </template>

            <template #dt_data="{ item }">
                {{ item.dt_data | formatDate | hiddenValue }}
            </template>

            <template #ds_complemento="{ currentItem }">
                {{ currentItem || "-" }}
            </template>

            <template #ds_tipo="{ item }">
                <span
                    v-if="item.sn_receita"
                    style="color: #0b8043"
                >
                    Receita
                </span>
                <span
                    v-else-if="item.sn_despesa"
                    style="color: #cc0000"
                >
                    Despesa
                </span>
            </template>
        </BaseTable>
    </BaseSheet>
</template>

<script>
export default {
    props: {
        data: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            headers: [
                {
                    text: "Código",
                    value: "cd_cofrinho_historico",
                    align: "center",
                    width: "90px",
                },
                {
                    text: "Descrição",
                    value: "ds_descricao",
                },
                {
                    text: "Valor",
                    value: "vl_valor",
                },
                {
                    text: "Data",
                    value: "dt_data",
                },
                {
                    text: "Complemento",
                    value: "ds_complemento",
                    width: "50%",
                },
                {
                    text: "Tipo",
                    value: "ds_tipo",
                    align: "center",
                },
            ]
            ,
        };
    },
};
</script>
