import Vue from "vue";
import VueRouter from "vue-router";

import { reservaEmergenciaRoutes } from "@module/ReservaEmergencia";

import { authRoutes, authStore } from "@module/Auth";

import { setTitlePage } from "@helpers";

Vue.use(VueRouter);

export const routes = [
    ...authRoutes,
    ...reservaEmergenciaRoutes,
    {
        path: "*",
        redirect: { name: "login" },
    },
];

Vue.prototype.$routesApp = routes;

const router = new VueRouter({
    mode: "history",
    base: import.meta.env.BASE_URL,
    routes,
});

router.beforeEach((to, from, next) => {
    setTitlePage(to);
    const isAuthenticated = authStore.state.userInfos;
    const isLoginRoute = to.name === "login";
    if (isLoginRoute && isAuthenticated) {
        next({ name: "emergency-reserve-dashboard" });
    } else if (!isLoginRoute && !isAuthenticated) {
        next({ name: "login" });
    } else {
        next();
    }
});

export default router;
