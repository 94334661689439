<template>
    <BaseDatePicker
        v-model="valueLocal"
        v-bind="$attrs"
        :display-date="displayDateLocal"
        :date-picker="{ type: 'month', color: 'vdPrimary' }"
    />
</template>

<script>
import { DateStringHelper } from "@helpers";

export default {
    props: {
        value: {
            type: [Date, String, Object],
            default: "",
        },
    },
    data() {
        return {
            valueLocal: null,
            displayDateLocal: null,
        };
    },

    watch: {
        valueLocal: {
            immediate: true,
            handler(newValue) {
                let tmp = null;
                if (newValue) {
                    const dateStringHelper = new DateStringHelper(newValue, "YYYY-MM", "-");
                    tmp = dateStringHelper.getDateFormatted("MM/YYYY");
                }
                this.displayDateLocal = tmp;
                this.$emit("input", tmp);
            },
        },
        value: {
            immediate: true,
            handler(newValue) {
                let tmp = newValue;
                if (newValue) {
                    const dateStringHelper = new DateStringHelper(newValue, "MM/YYYY");
                    tmp = dateStringHelper.getDateFormatted("YYYY-MM");
                }
                this.displayDateLocal = newValue;
                this.valueLocal = tmp;
            },
        },
    },
};
</script>
