<template>
    <BaseSheet
        v-if="viewFilters"
        class="pa-3 pb-4 mt-4"
    >
        <v-row class="ma-0">
            <v-col
                v-if="viewSearch"
                cols="4"
                xl="3"
            >
                <BaseTextField
                    v-model="search"
                    label="Pesquisar"
                    append-icon="mdi-magnify"
                    :persistent-hint="true"
                    hint="Filtra apenas por Descrição/Observação"
                />
            </v-col>

            <v-col
                v-if="viewMonthYear"
                cols="2"
                xl="2"
            >
                <BaseDatePickerMonth
                    v-model="monthYearComputed"
                    label="Mês/Ano"
                />
            </v-col>

            <v-col
                v-if="viewBankName"
                cols="3"
                xl="2"
            >
                <BaseAutoComplete
                    v-model="bankName"
                    :items="banks"
                    item-text="name"
                    item-value="name"
                    label="Nome do banco"
                />
            </v-col>

            <v-col
                v-if="viewActionName"
                cols="2"
                xl="2"
            >
                <BaseAutoComplete
                    v-model="actionName"
                    :items="actionsName"
                    label="Ação"
                />
            </v-col>
        </v-row>

        <footer class="w-100 d-flex justify-center">
            <BaseButton
                class="mr-4 text-none"
                color="error"
                outlined
                @click="clearFilters()"
            >
                Limpar
            </BaseButton>

            <BaseButton
                color="vdPrimary"
                class="text-none"
                :dark="!filtersIsEmpty"
                :disabled="filtersIsEmpty"
                @click="searchFilters()"
            >
                Buscar
            </BaseButton>
        </footer>
    </BaseSheet>
</template>

<script>
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

import { DateStringHelper } from "@helpers";

import {
    banks,
    actionNames,
} from "@module/ReservaEmergencia/constants";

export default defineComponent({
    props: {
        viewFilters: {
            type: Boolean,
            default: false,
        },
        value: {
            type: Object,
            default: () => ({}),
        },
        viewSearch: {
            type: Boolean,
            default: false,
        },
        viewMonthYear: {
            type: Boolean,
            default: false,
        },
        viewBankName: {
            type: Boolean,
            default: false,
        },
        viewActionName: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            banks,
            actionsName: actionNames,
            actionName: null,
            search: null,
            monthYear: null,
            bankName: null,
        };
    },

    computed: {
        ...mapGetters({
            getCurrentYear: "getCurrentYear",
            getYearFilter: "getYearFilter",
            getListAllItems: "reservaEmergencia/getListAllItems",
        }),
        filtersIsEmpty() {
            let isValid = 0;

            if (this.viewActionName && this.actionName) {
                isValid += 1;
            }

            if (this.viewSearch && this.search) {
                isValid += 1;
            }

            if (this.viewMonthYear && this.monthYearComputed) {
                isValid += 1;
            }

            if (this.viewBankName && this.bankName) {
                isValid += 1;
            }

            return isValid === 0;
        },
        hasOwnPropertyMonthYearInVModel() {
            // eslint-disable-next-line no-prototype-builtins
            return this.vModel.hasOwnProperty("monthYear", this.vModel);
        },
        monthYearComputed: {
            get() {
                if (this.hasOwnPropertyMonthYearInVModel) return this.vModel.monthYear;
                return this.monthYear;
            },
            set(value) {
                if (this.hasOwnPropertyMonthYearInVModel) {
                    this.vModel.monthYear = value;
                } else {
                    this.monthYear = value;
                }
            },
        },
        vModel: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
        yearComputed: {
            get() {
                return this.getYearFilter;
            },
            set(value) {
                this.$store.dispatch("handleYearFilter", value);
            },
        },
    },

    methods: {
        clearFilters() {
            this.dates = null;
            this.actionName = null;
            this.search = null;
            this.yearComputed = this.getCurrentYear;
            this.monthYearComputed = null;
            this.bankName = null;
            if (this.$listeners.clearAction) {
                this.$emit("clearAction");
            } else {
                const result = this.filterByYear(this.getListAllItems);
                this.$store.dispatch("reservaEmergencia/handleList", result);
                this.$store.dispatch("handleChartsData", true);
            }
        },
        searchFilters() {
            if (this.$listeners.searchAction) {
                this.$emit("searchAction", {
                    monthYear: this.monthYearComputed,
                });
            } else {
                let result = this.getListAllItems;

                if (this.viewMonthYear) {
                    result = this.filterByMonthYear();
                }

                if (this.viewBankName) {
                    result = this.filterByBankName(result);
                }

                if (this.viewActionName) {
                    result = this.filterByActionName(result);
                }

                if (this.viewSearch) {
                    result = this.filterBySearch(result);
                }

                this.$store.dispatch("reservaEmergencia/handleList", result);
                this.$store.dispatch("handleChartsData", true);
            }
        },
        filterByMonthYear() {
            if (this.monthYearComputed) {
                const dateStringHelper2 = new DateStringHelper(this.monthYearComputed, "MM/YYYY");
                const month = dateStringHelper2.getMonth();
                const year = dateStringHelper2.getYear();

                const result = this.getListAllItems.filter((item) => {
                    const dateStringHelper = new DateStringHelper(item.date);
                    const monthItem = String(Number(dateStringHelper.getMonth()));
                    const yearItem = dateStringHelper.getYear();
                    return (
                        monthItem.padStart(2, "0") === month.padStart(2, "0") && yearItem === year
                    );
                });
                return result;
            }
            return this.getListAllItems;
        },
        filterBySearch(array) {
            if (this.search) {
                const result = array.filter((item) => {
                    const string1 = item.description.toUpperCase();
                    const string2 = item.observation.toUpperCase();
                    const filterText = (this.search || "").toUpperCase();
                    return string1.includes(filterText) || string2.includes(filterText);
                });
                return result;
            }
            return array;
        },
        filterByBankName(array) {
            if (this.bankName) {
                const result = array.filter(({ bankName }) => bankName === this.bankName);
                return result;
            }
            return array;
        },
        filterByActionName(array) {
            if (this.actionName) {
                const result = array.filter(({ actionName }) => actionName === this.actionName);
                return result;
            }
            return array;
        },
    },
});
</script>
