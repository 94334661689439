<template>
    <BaseModal
        v-model="sn_modal_aberto"
        max-width="550px"
    >
        <template #activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                color="vdPrimary"
                dark
                class="text-none"
                v-on="on"
                @click="getArrCartoesTipos"
            >
                <v-icon left>
                    mdi-plus
                </v-icon>

                Adicionar
            </v-btn>
        </template>

        <v-card>
            <BaseFormHeader
                ds-nome-tela="Cartão"
                :ds-nome-acao="ds_nome_acao"
            />
            <v-form
                ref="contaBancariaCartoesForm"
                v-model="valid"
                style="padding: 1.5rem"
                lazy-validation
            >
                <v-row>
                    <v-col class="pb-0">
                        <BaseTextField
                            v-model="payload.ds_nome"
                            label="Nome *"
                            validation-empty
                        />
                    </v-col>
                    <v-col class="pb-0">
                        <BaseTextField
                            v-model="payload.nr_finais"
                            label="Numeros finais *"
                            validation-empty
                            :rules="[validacoesCaracteres, validacaoDuplicado]"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pb-0">
                        <BaseAutoComplete
                            v-model="payload.cd_cartao_credito_tipo"
                            label="Tipo *"
                            item-value="cd_cartao_credito_tipo"
                            item-text="ds_tipo"
                            validation-empty
                            :items="arrCartoesTipo"
                        />
                    </v-col>
                </v-row>
            </v-form>
            <BaseFormActions
                :sn-loading="sn_loading"
                @save="salvarAcao"
                @close="fecharModal"
            />
        </v-card>
    </BaseModal>
</template>

<script>
import { defineComponent } from "vue";
import { toCurrencyToLocaleString } from "@/modules/Main/helpers";

export default defineComponent({
    props: {
        arrCartoes: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            payload: {},
            valid: true,
            sn_loading: false,
            sn_modal_aberto: false,
            ds_nome_acao: "Criar",
            arrCartoesTipo: [],
            nr_finais_anterior: null,
        };
    },

    computed: {
        getSnEditar() {
            return this.ds_nome_acao === "Editar";
        },
    },

    mounted() {
        this.payload = {
            ...this.payload,
            cd_conta_bancaria: +this.$route.params.cd_conta_bancaria,
        };
    },

    methods: {
        // utilizado via ref
        abrirModal(objPayload = null) {
            this.getArrCartoesTipos();
            if (objPayload) {
                this.ds_nome_acao = "Editar";
                this.payload = {
                    ...objPayload,
                    cd_conta_bancaria: +this.$route.params.cd_conta_bancaria,
                };
                this.nr_finais_anterior = objPayload?.nr_finais;
                this.setValueInputCurrency(objPayload.nr_valor);
            }
            this.sn_modal_aberto = true;
        },
        fecharModal() {
            this.sn_modal_aberto = false;
            this.ds_nome_acao = "Criar";
            this.resetData();
            this.resetValidation();
        },

        getFormIsValid() {
            return this.$refs.contaBancariaCartoesForm.validate();
        },

        resetValidation() {
            if (this.$refs.contaBancariaCartoesForm) {
                this.$refs.contaBancariaCartoesForm.resetValidation();
            }
        },

        async salvarAcao() {
            const formIsValid = this.getFormIsValid();
            if (formIsValid) {
                this.sn_loading = true;
                let ds_nome_acao = "criado";
                if (this.payload.cd_cartao_credito) {
                    await this.$cartaoCreditoHttpGateway.update(
                        this.payload.cd_cartao_credito,
                        this.payload,
                    );
                    ds_nome_acao = "editado";
                } else {
                    await this.$cartaoCreditoHttpGateway.create(this.payload);
                }
                this.$emit("atualizar-listagem");
                this.$toast.success(`Cartão ${ds_nome_acao} com sucesso!`);
                this.sn_loading = false;
                this.fecharModal();
            }
        },

        resetData() {
            this.payload = {
                cd_conta_bancaria: +this.$route.params.cd_conta_bancaria,
            };
            this.setValueInputCurrency();
        },

        setValueInputCurrency(nr_valor = 0) {
            if (this.$refs.inputCurrency) {
                const input = this.$refs.inputCurrency.$el.querySelector("input");
                const nr_valor_formatado = toCurrencyToLocaleString(nr_valor);
                input.value = nr_valor_formatado;
            }
        },

        validacoesCaracteres(ds_valor) {
            return (ds_valor && ds_valor.length >= 4 && ds_valor.length <= 4) || "O campo deve ter apenas 4 caracteres";
        },

        getCartaoByNrFinais(nr_finais) {
            return this.arrCartoes.find((objCartao) => objCartao.nr_finais === nr_finais);
        },

        validacaoDuplicado(ds_valor) {
            const sn_edicao = this.getSnEditar === true;
            const sn_mesmo_anterior = ds_valor === this.nr_finais_anterior;
            const sn_valor_nao_alterado = sn_edicao && sn_mesmo_anterior;
            if (sn_valor_nao_alterado || !ds_valor) return true;
            const sn_existe = this.getCartaoByNrFinais(ds_valor);
            return !sn_existe || "Cartão duplicado";
        },

        async getArrCartoesTipos() {
            if (this.arrCartoesTipo.length === 0) {
                const arrCartoesTipo = await this.$cartaoCredito.tipoHttpGateway.getAll();
                this.arrCartoesTipo = arrCartoesTipo;
            }
        },
    },
});
</script>
