<template>
    <v-dialog
        v-model="vModel"
        v-bind="$attrs"
        content-class="vd-border-radius"
        v-on="$listeners"
    >
        <template #activator="{ on, attrs }">
            <slot
                name="activator"
                :attrs="attrs"
                :on="on"
            />
        </template>

        <slot />
    </v-dialog>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        value: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        vModel: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },
});
</script>

<style scoped></style>
