export default class HistoryDTO {
    id;

    setId(value) {
        this.id = value;
    }

    value;

    setValue(value) {
        this.value = value;
    }

    date;

    setDate(value) {
        this.date = value;
    }

    bankName;

    setBankName(value) {
        this.bankName = value;
    }

    description;

    setDescription(value) {
        this.description = value;
    }

    observation;

    setObservation(value) {
        this.observation = value;
    }

    actionName;

    setActionName(value) {
        this.actionName = value;
    }
}
