<template>
    <div>
        <BaseHeaderPage>
            <template #right>
                <div class="d-flex align-center justify-end w-100  h-100">
                    <div style="width: 150px;">
                        <BaseDatePickerYear
                            v-model="year"
                            label="Ano"
                        />
                    </div>
                </div>
            </template>
        </BaseHeaderPage>

        <div
            v-for="(categoria, index) in getData"
            :key="index"
            style="border: 1px solid #c1c1c1; margin: 8px 0; padding: 14px; border-radius: 8px"
        >
            <div style="margin-bottom: 14px; font-size: 18px; font-weight: bold">
                {{ categoria.ds_nome }}
            </div>
            <table
                class="myTable"
                border="1"
                style="
                    width: 100%;
                    border-collapse: collapse;
                    border-color: #c1c1c1;
                    border-radius: 8px;
                "
            >
                <thead>
                    <tr>
                        <th
                            v-for="(column, indexTh) in getColumns"
                            :key="column"
                            style="text-transform: capitalize"
                            :width="indexTh === 0 ? 'auto' : '7%'"
                        >
                            {{ column }}
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr
                        v-for="(lancamento, indexRow) in categoria.lancamentos"
                        :key="`row-${indexRow}`"
                    >
                        <td>
                            <div>{{ lancamento.ds_nome }}</div>
                        </td>
                        <td
                            v-for="nrMes in 12"
                            :key="`valor-mes-${nrMes}`"
                            :style="{
                                color:
                                    (lancamento.itemsIguais.find(
                                        (lancamento2) => lancamento2.nr_mes === nrMes
                                    )?.valor || 0) === 0
                                        ? '#c1c1c1'
                                        : '',
                            }"
                        >
                            <div
                                style="
                                    display: flex;
                                    justify-content: flex-start;
                                    flex-direction: column;
                                    height: 42px;
                                "
                            >
                                <span>
                                    {{
                                        lancamento.itemsIguais.find(
                                            (lancamento2) => lancamento2.nr_mes === nrMes
                                        )?.valor || 0 | toCurrencyToLocaleString
                                    }}
                                </span>
                                <span
                                    v-if="
                                        lancamento.itemsIguais.find(
                                            (lancamento2) => lancamento2.nr_mes === nrMes
                                        )?.nr_mes > 1 &&
                                            lancamento.itemsIguais.find(
                                                (lancamento2) => lancamento2.nr_mes === nrMes
                                            )?.variacao !== 0
                                    "
                                    style="font-size: 11px"
                                    :style="{
                                        color:
                                            lancamento.itemsIguais.find(
                                                (lancamento2) => lancamento2.nr_mes === nrMes
                                            )?.variacao > 0
                                                ? 'green'
                                                : 'red',
                                    }"
                                >
                                    <span>({{
                                        lancamento.itemsIguais.find(
                                            (lancamento2) => lancamento2.nr_mes === nrMes
                                        )?.variacao_real | toCurrencyToLocaleString
                                    }})</span>
                                    <span>
                                        {{
                                            lancamento.itemsIguais.find(
                                                (lancamento2) => lancamento2.nr_mes === nrMes
                                            )?.variacao | formatPercent
                                        }}
                                    </span>
                                </span>
                            </div>
                        </td>
                        <td :style="{ color: lancamento.total === 0 ? '#c1c1c1' : '' }">
                            {{ lancamento.total | toCurrencyToLocaleString }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import dayjs from "dayjs";

export default {
    filters: {
        formatPercent(value) {
            if (isNaN(value)) {
                return 0;
            }
            return `${(value * 100).toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
            })}%`;
        },
    },
    data: () => ({
        data: [],
        year: 0,
    }),

    computed: {
        getData() {
            const categorias = [];
            this.data.forEach((lancamento) => {
                const categoria = categorias.find(
                    (element) => element.ds_nome === lancamento.ds_nome,
                );
                if (categoria) {
                    categoria.lancamentos.push(lancamento);
                } else {
                    categorias.push({
                        ds_nome: lancamento.ds_nome,
                        total: 0,
                        lancamentos: [lancamento],
                    });
                }
            });
            const categoriasLancamentosFormatado = categorias.map((categoria) => {
                const novosLancamentosCategoria = [];
                categoria.lancamentos.forEach((lancamento) => {
                    let objLancamentoCategoria = novosLancamentosCategoria.find(
                        (lancamentoCategoria) => lancamentoCategoria.ds_nome === lancamento.ds_descricao,
                    );
                    const valor = Math.abs(lancamento.total_valor);
                    const nr_mes = Number(lancamento.mes_ano.split("-")[1]);
                    if (!objLancamentoCategoria) {
                        novosLancamentosCategoria.push({
                            ds_nome: lancamento.ds_descricao,
                            itemsIguais: this.factory(),
                        });
                    }
                    objLancamentoCategoria = novosLancamentosCategoria.find(
                        (lancamentoCategoria) => lancamentoCategoria.ds_nome === lancamento.ds_descricao,
                    );
                    const index = objLancamentoCategoria.itemsIguais.findIndex(
                        (element) => element.nr_mes === nr_mes,
                    );
                    objLancamentoCategoria.itemsIguais[index] = {
                        nr_mes,
                        valor,
                        real_valor: lancamento.total_valor,
                        variacao: 0,
                        variacao_real: 0,
                    };
                });
                novosLancamentosCategoria.push({
                    ds_nome: "Total",
                    itemsIguais: this.factory(),
                });
                return {
                    ...categoria,
                    lancamentos: novosLancamentosCategoria,
                };
            });
            const categoriasLancamentosFormatado2 = categoriasLancamentosFormatado.map(
                (categoria) => {
                    let teste = [...this.factory()];
                    let total2 = 0;
                    const lancamentosTotal = categoria.lancamentos.map((lancamento) => {
                        if (lancamento.ds_nome === "Total") {
                            const objReturn = {
                                ...lancamento,
                                total: total2,
                                itemsIguais: teste,
                            };
                            teste = [...this.factory()];
                            let oldItem = null;
                            const itemsIguais = objReturn.itemsIguais.map((element, index) => {
                                if (index > 0 && element.valor !== 0 && oldItem.valor !== 0) {
                                    element.variacao = (element.valor - oldItem.valor) / oldItem.valor;
                                    element.variacao_real = Math.abs(element.valor - oldItem.valor);
                                }
                                oldItem = element;
                                return element;
                            });
                            return {
                                ...objReturn,
                                itemsIguais,
                            };
                        }
                        let total = 0;
                        const itemsIguais = lancamento.itemsIguais.map((lancamento2, index) => {
                            const item = teste.find(
                                (element) => element.nr_mes === lancamento2.nr_mes,
                            );
                            if (item) {
                                item.valor += lancamento2.valor;
                            }
                            total += lancamento2.valor;
                            total2 += lancamento2.valor;
                            let variacao = 0;
                            let variacao_real = 0;
                            if (lancamento2.nr_mes > 1 && index > 0) {
                                const oldValue = lancamento.itemsIguais.at(index - 1)?.real_valor || 0;
                                if (oldValue !== 0 && +lancamento2.real_valor !== 0) {
                                    variacao = (lancamento2.real_valor - oldValue) / oldValue;
                                    variacao_real = Math.abs(lancamento2.real_valor - oldValue);
                                }
                            }
                            return {
                                ...lancamento2,
                                variacao,
                                variacao_real,
                            };
                        });
                        return {
                            ...lancamento,
                            total,
                            itemsIguais,
                        };
                    });
                    return {
                        ...categoria,
                        lancamentos: lancamentosTotal,
                    };
                },
            );
            const categoriasLancamentosFormatado3 = categoriasLancamentosFormatado2.map(
                (element) => {
                    const lancamentos = element.lancamentos.sort((a, b) => {
                        if (a.ds_nome === "Total" || b.ds_nome === "Total") {
                            return a.total - b.total;
                        }
                        return b.total - a.total;
                    });
                    return {
                        ...element,
                        lancamentos,
                    };
                },
            );
            return categoriasLancamentosFormatado3;
        },
        getColumns() {
            return [
                "descrição",
                "janeiro (1)",
                "fevereiro (2)",
                "março (3)",
                "abril (4)",
                "maio (5)",
                "junho (6)",
                "julho (7)",
                "agosto (8)",
                "setembro (9)",
                "outubro (10)",
                "novembro (11)",
                "dezembro (12)",
                "total",
            ];
        },
    },

    watch: {
        year(newYear) {
            this.getDataRequest(newYear);
        },
    },

    mounted() {
        this.year = dayjs().format("YYYY");
    },

    methods: {
        factory() {
            const months = [
                {
                    nr_mes: 1,
                    valor: 0,
                    variacao: 0,
                    variacao_real: 0,
                    real_valor: 0,
                },
                {
                    nr_mes: 2,
                    valor: 0,
                    variacao: 0,
                    variacao_real: 0,
                    real_valor: 0,
                },
                {
                    nr_mes: 3,
                    valor: 0,
                    variacao: 0,
                    variacao_real: 0,
                    real_valor: 0,
                },
                {
                    nr_mes: 4,
                    valor: 0,
                    variacao: 0,
                    variacao_real: 0,
                    real_valor: 0,
                },
                {
                    nr_mes: 5,
                    valor: 0,
                    variacao: 0,
                    variacao_real: 0,
                    real_valor: 0,
                },
                {
                    nr_mes: 6,
                    valor: 0,
                    variacao: 0,
                    variacao_real: 0,
                    real_valor: 0,
                },
                {
                    nr_mes: 7,
                    valor: 0,
                    variacao: 0,
                    variacao_real: 0,
                    real_valor: 0,
                },
                {
                    nr_mes: 8,
                    valor: 0,
                    variacao: 0,
                    variacao_real: 0,
                    real_valor: 0,
                },
                {
                    nr_mes: 9,
                    valor: 0,
                    variacao: 0,
                    variacao_real: 0,
                    real_valor: 0,
                },
                {
                    nr_mes: 10,
                    valor: 0,
                    variacao: 0,
                    variacao_real: 0,
                    real_valor: 0,
                },
                {
                    nr_mes: 11,
                    valor: 0,
                    variacao: 0,
                    variacao_real: 0,
                    real_valor: 0,
                },
                {
                    nr_mes: 12,
                    valor: 0,
                    variacao: 0,
                    variacao_real: 0,
                    real_valor: 0,
                },
            ];
            return months;
        },
        async getDataRequest(year) {
            const response = await this.$controleMensalLancamentosHttpGateway.dashboard(year);
            this.data = response;
        },
    },
};
</script>

<style lang="scss">
.myTable th,
td {
    padding: 8px;
    padding-left: 0;
}

.myTable td:first-of-type {
    padding-left: 8px;
}

.myTable th {
    font-size: 14px;
}

.myTable td {
    text-align: right;
}

.myTable td:first-child {
    text-align: left;
}
</style>
