import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(customParseFormat);

export default function formatDateWithTime(date) {
    if (!date) return "-";
    if (date instanceof Date) return dayjs(date).format("DD/MM/YYYY HH:mm");
    return dayjs(date.replace("Z", "")).format("DD/MM/YYYY HH:mm");
}
