import axios from "axios";

export default class AxiosAdapter {
    axiosInstance;

    constructor(config = {}) {
        this.axiosInstance = axios.create(config);
    }

    async get(path, config = {}) {
        return this.axiosInstance.get(path, config);
    }

    async post(path, data, config = {}) {
        return this.axiosInstance.post(path, data, config);
    }

    async put(path, data, config = {}) {
        return this.axiosInstance.put(path, data, config);
    }

    async patch(path, data, config = {}) {
        return this.axiosInstance.patch(path, data, config);
    }

    async delete(path) {
        return this.axiosInstance.delete(path);
    }
}
