<template>
    <v-badge
        color="success"
        icon="mdi-check"
        overlap
        bordered
        class="w-100"
        :value="sn_migrado"
    >
        <BaseButton
            :loading="sn_carregando"
            dark
            block
            color="vdPrimary"
            class="text-none"
            @click="migrarDadosPagina"
        >
            {{ dsNome }}
        </BaseButton>
    </v-badge>
</template>

<script>
export default {
    props: {
        cdControleMigracaoPlanilhaPaginas: {
            type: [String, Number],
            default: "",
        },
        dsNome: {
            type: String,
            default: "",
        },
        snMigrado: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            sn_carregando: false,
            sn_migrado: false,
        };
    },
    watch: {
        snMigrado(sn_migrado) {
            this.sn_migrado = sn_migrado;
        },
    },
    methods: {
        migracaoSheetsHttpGateway() {
            return this.$configuracoes.migracaoSheetsHttpGateway;
        },
        async migrarDadosPagina() {
            const { sn_migrado } = this;
            if (sn_migrado) {
                this.$emit(
                    "confirmar",
                    this.dsNome,
                    () => this.migrarDadosRequest(this.cdControleMigracaoPlanilhaPaginas),
                );
            } else {
                this.migrarDadosRequest(this.cdControleMigracaoPlanilhaPaginas);
            }
        },
        async migrarDadosRequest(cd_controle_migracao_planilha_paginas) {
            try {
                this.sn_carregando = true;
                const response = await this.migracaoSheetsHttpGateway().migrarDados(
                    cd_controle_migracao_planilha_paginas,
                );
                if (response.success === true) {
                    this.$toast.success(response.message);
                }
                this.sn_carregando = false;
                this.sn_migrado = true;
            } catch (e) {
                console.log(e);
                this.sn_carregando = false;
            }
        },
    },
};
</script>

<style lang="scss">
.migracao-secao {
    display: flex;
    flex-direction: column;
}

.migracao__titulo {
    margin-bottom: 1rem;
    line-height: 1;
}
</style>
