<template>
    <div class="importacao">
        <BaseHeaderPage class="importacao__header" />

        <BaseSheet class="pa-4">
            <v-file-input
                v-model="file"
                accept=".txt"
                label="Arquivo"
                hide-details
                class="mt-0 pt-0"
                outlined
                dense
            />

            <div
                v-if="file"
                class="mt-4"
            >
                <div class="d-flex flex-column">
                    <span>Nome arquivo: <strong>{{ file.name }}</strong></span>
                    <span>Data arquivo: <strong>{{ getDay }}</strong></span>
                </div>
                <v-divider class="my-3" />
                <h5 class="text-h5">
                    Importar as sessões:
                </h5>
                <v-checkbox
                    v-model="sn_selecionar_remover_todos"
                    label="Selecionar/Remover todos"
                    hide-details
                />
                <v-row>
                    <v-col
                        v-for="objsessao in objSessoes"
                        :key="objsessao.ds_nome"
                        cols="auto"
                    >
                        <v-checkbox
                            v-model="arrSessoesSelecionados"
                            hide-details
                            :label="objsessao.ds_nome"
                            :value="objsessao.ds_nome"
                            :disabled="sn_carregando"
                        />
                    </v-col>
                </v-row>
                <BaseButton
                    color="vdPrimary"
                    dark
                    class="text-none mt-6"
                    elevation="0"
                    :loading="sn_carregando"
                    @click="importar"
                >
                    Importar
                </BaseButton>
            </div>
        </BaseSheet>
    </div>
</template>

<script>
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";

dayjs.extend(duration);

function unixTimestampToDateFilter(value) {
    const objDayjs = dayjs(value * 1000);
    return {
        obj: objDayjs,
        result: objDayjs.format("DD/MM/YYYY HH:mm:ss"),
    };
}

export default {
    filters: {
        unixTimestampToDate(value) {
            return unixTimestampToDateFilter(value).result;
        },
        millisecondsToDate(value) {
            const objDayJS = dayjs(value);
            let format = "";
            if (objDayJS.get("minute") > 0) {
                format += "mm:";
            }
            format += "ss.SSS";
            return objDayJS.format(format);
        },
    },
    data() {
        return {
            arrSessoesSelecionados: [],
            arrNomesSessoes: [],
            sn_selecionar_remover_todos: false,
            file: null,
            objSessoes: [],
            sn_carregando: false,
        };
    },
    computed: {
        getDay() {
            const {
                year,
                month,
                day,
                hour,
                minute,
                seconds,
            } = this.getObjDateFromFileName;
            return `${day}/${month}/${year} ${hour}:${minute}:${seconds}`;
        },
        getDateFormatToBackend() {
            const {
                year,
                month,
                day,
                hour,
                minute,
                seconds,
            } = this.getObjDateFromFileName;
            return `${year}-${month}-${day}T${hour}:${minute}:${seconds}.000Z`;
        },
        getObjDateFromFileName() {
            const [, date, time] = this.file.name.split("_");
            const year = date.substring(0, 4);
            const month = date.substring(4, 6).padStart(2, "0");
            const day = date.substring(6).padStart(2, "0");
            const hour = time.substring(0, 2).padStart(2, "0");
            const minute = time.substring(2, 4).padStart(2, "0");
            const seconds = time.substring(4, 6).padStart(2, "0");
            return {
                year,
                month,
                day,
                hour,
                minute,
                seconds,
            };
        },
    },
    watch: {
        file(newValue) {
            if (newValue) {
                const fileReader = new FileReader();
                fileReader.onload = this.onload;
                fileReader.readAsText(newValue);
            }
        },
        sn_selecionar_remover_todos(newValue) {
            this.arrSessoesSelecionados = newValue ? [...this.arrNomesSessoes] : [];
        },
    },
    methods: {
        onload(event) {
            const resultInJSON = JSON.parse(event.target.result);
            const objKeys = Object.keys(resultInJSON);
            objKeys.forEach((element) => {
                const value = resultInJSON[element];
                if (!Array.isArray(value) || value.length === 0) {
                    delete resultInJSON[element];
                }
            });
            const arrSessions = [];
            const arrNomesSessoes = [];
            Object.keys(resultInJSON).forEach((key) => {
                const value = resultInJSON[key].reverse();
                const arrSolves = [];
                value.forEach((element, index) => {
                    arrSolves.push({
                        nr_solve: index + 1,
                        dt_data: dayjs(element[3] * 1000).format("YYYY-MM-DDTHH:mm:ss.000Z"),
                        nr_tempo: element[0][1],
                        ds_scramble: element[1],
                        ds_comentario: element[2],
                    });
                });
                arrNomesSessoes.push(key);
                arrSessions.push({
                    ds_nome: key,
                    arrSolves,
                });
            });
            this.arrNomesSessoes = arrNomesSessoes;
            this.objSessoes = arrSessions;
        },
        getSessions() {
            return this.objSessoes.filter(
                ({ ds_nome }) => this.arrSessoesSelecionados.includes(ds_nome),
            );
        },
        async importar() {
            try {
                this.sn_carregando = true;
                await this.$cuboMagicoHttpGateway.importacao({
                    dt_data: this.getDateFormatToBackend,
                    arrSessions: this.getSessions(),
                });
                this.arrSessoesSelecionados = [];
                this.file = null;
                this.objSessoes = [];
                this.sn_carregando = false;
                this.sn_selecionar_remover_todos = false;
                this.$toast.success("Importação feita com sucesso!");
            } catch (e) {
                this.$toast.error("Ocorreu um erro e não foi possível fazer a importação!");
                this.sn_carregando = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.importacao {
    $ref: &;

    &__header {
        margin-bottom: 0.75rem;
    }
}
</style>
