<template>
    <v-row class="dashboard__row">
        <v-col
            v-for="(item, index) in getItems"
            :key="`item-${index}`"
            :cols="getNumberColumns"
            :class="{ 'pl-0': index === 0, 'pr-0': index === getItems.length - 1 }"
        >
            <DashboardHeaderItem
                :hidden-values="hiddenValues"
                :icon="item.icon"
                :title="item.title"
                :color="item.color"
                :value="item.value"
                :is-currency="item.isCurrency"
            />
        </v-col>
    </v-row>
</template>

<script>
import { cards } from "../../constants";

export default {
    props: {
        entries: {
            type: Number,
            default: 0,
        },
        currentBalance: {
            type: Number,
            default: 0,
        },
        goalValue: {
            type: Number,
            default: 0,
        },
        hiddenValues: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        getItems() {
            return cards(this.currentBalance, this.entries, this.goalValue);
        },
        getNumberColumns() {
            return 12 / this.getItems.length;
        },
    },
};
</script>
