import addVariableGlobalVue from "./addVariableGlobalvue";
import gatewaysFactory from "./gatewaysFactory";
import injectAllGateways from "./injectAllGateways";

const { httpClient, ...listGateways } = gatewaysFactory();

function injectGatewaysStore(store) {
    const params = {
        callback: (key, value) => {
            // eslint-disable-next-line no-param-reassign
            store[key] = value;
        },
        listGateways,
    };
    injectAllGateways(params);
}

function injectGatewaysVue() {
    const params = {
        callback: addVariableGlobalVue,
        listGateways,
    };
    injectAllGateways(params);
}

export {
    injectGatewaysStore,
    injectGatewaysVue,
    httpClient,
};
