<template>
    <h2 class="titulo">
        <v-icon
            color="black"
            class="mr-2"
        >
            mdi-{{ dsIcone }}
        </v-icon>
        {{ dsNome }}
    </h2>
</template>

<script>
export default {
    props: {
        dsIcone: {
            type: String,
            required: true,
        },
        dsNome: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="scss">
.titulo {
    text-align: left;
    font-weight: 500;
    display: flex;
    align-items: center;
}
</style>
