<template>
    <v-dialog
        v-model="vModel"
        v-bind="$attrs"
        :content-class="getContentClass"
        data-cy="baseModalSave"
        v-on="$listeners"
    >
        <template #activator="{ on, attrs }">
            <slot
                v-if="customActivator"
                name="activator"
                :on="on"
                :attrs="attrs"
            />

            <BaseButton
                v-else
                v-bind="attrs"
                color="vdPrimary"
                dark
                data-cy="buttonAdd"
                v-on="on"
            >
                <v-icon left>
                    mdi-plus
                </v-icon>

                Adicionar
            </BaseButton>
        </template>

        <v-card>
            <header class="px-4 pt-4">
                <v-card-title class="pa-0">
                    <span
                        data-cy="modalSaveTitle"
                        class="text-h5"
                    >{{ actionName }} {{ nameView }}</span>
                </v-card-title>
                <v-card-text class="pa-0 text--secondary">
                    <small>* indica campo obrigatório</small>
                </v-card-text>
            </header>

            <v-form
                ref="form"
                v-model="valid"
                class="pa-4"
                lazy-validation
            >
                <slot />
            </v-form>

            <v-card-actions class="pt-0 pa-4">
                <v-spacer />
                <BaseButton
                    color="vdPrimary"
                    outlined
                    width="100px"
                    class="text-none"
                    @click="closeAction"
                >
                    Fechar
                </BaseButton>

                <BaseButton
                    color="vdPrimary"
                    :loading="isLoading"
                    dark
                    class="text-none"
                    width="100px"
                    data-cy="buttonSave"
                    @click="saveAction"
                >
                    Salvar
                </BaseButton>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        actionName: {
            type: String,
            default: "ActionNameDefault",
        },
        nameView: {
            type: String,
            default: "NameViewDefault",
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
        data: {
            type: Object,
            default: () => ({}),
        },
        customActivator: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            valid: false,
        };
    },
    computed: {
        vModel: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
        getContentClass() {
            return `vd-border-radius cyBaseModalSave ${this.$attrs["content-class"]}`;
        },
    },
    methods: {
        validate() {
            if (this.$refs.form) return this.$refs.form.validate();
            return null;
        },
        reset() {
            if (this.$refs.form) return this.$refs.form.reset();
            return null;
        },
        resetValidation() {
            if (this.$refs.form) return this.$refs.form.resetValidation();
            return null;
        },
        saveAction() {
            const formIsValid = this.validate();
            if (!formIsValid) return;
            this.$emit("save", {
                isEdit: this.actionName === "Editar",
                item: this.data,
            });
        },
        closeAction() {
            if (this.$listeners.close) {
                this.$emit("close");
                return;
            }
            this.resetValidation();

            this.vModel = false;
        },
    },
});
</script>
