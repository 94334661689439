export default class LancamentosHttpGateway {
    httpClient;

    basePath = "lancamentos";
    basePathDashboard = `${this.basePath}/dashboard`;

    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    async getTotalSemanal(objFiltros) {
        const { data } = await this.httpClient.get(`${this.basePathDashboard}/total-semanal`, {
            params: objFiltros
        });
        return data;
    }

    async findUtilizadoEstimativa(objFiltros) {
        const { data } = await this.httpClient.get(`${this.basePathDashboard}/utilizado-estimativa`, {
            params: objFiltros
        });
        return data;
    }

    async getComparacaoMensalEstimativa(objFiltros) {
        const { data } = await this.httpClient.get(`${this.basePathDashboard}/comparacao-mensal/estimativa`, {
            params: objFiltros
        });
        return data;
    }

    async getComparacaoMensalUtilizado(objFiltros) {
        const { data } = await this.httpClient.get(`${this.basePathDashboard}/comparacao-mensal/utilizado`, {
            params: objFiltros
        });
        return data;
    }

    async findByMesAtualMesAnteriorUtilizado(objFiltros) {
        const { data } = await this.httpClient.get(`${this.basePathDashboard}/utilizado`, {
            params: objFiltros
        });
        return data;
    }

    async getOverviewSemana(objFiltros) {
        const { data } = await this.httpClient.get(`${this.basePathDashboard}/overview`, {
            params: objFiltros
        });
        return data;
    }

    async getOverviewTotal(objFiltros) {
        const { data } = await this.httpClient.get(`${this.basePathDashboard}/overview/total`, {
            params: objFiltros
        });
        return data;
    }

    async getComparacaoSemanalUtilizado(objFiltros) {
        const { data } = await this.httpClient.get(`${this.basePathDashboard}/comparacao-semanal/utilizado`, {
            params: objFiltros
        });
        return data;
    }

    async getComparacaoSemanalDisponivel(objFiltros) {
        const { data } = await this.httpClient.get(`${this.basePathDashboard}/comparacao-semanal/disponivel`, {
            params: objFiltros
        });
        return data;
    }

    async getUtilizadoEstimativa(objFiltros) {
        const { data } = await this.httpClient.get(`${this.basePathDashboard}/utilizado-estimativa`, {
            params: objFiltros
        });
        return data;
    }

    async getAll() {
        const { data } = await this.httpClient.get(this.basePath);
        return data;
    }

    async findByMesAno(dt_mes_ano) {
        const { data } = await this.httpClient.get(`${this.basePath}/ano-mes`, {
            params: {
                dt_mes_ano
            }
        });
        return data;
    }

    async create(body = {}) {
        const { data } = await this.httpClient.post(this.basePath, body);
        return data;
    }

    async update(id = -1, body = {}) {
        const { data } = await this.httpClient.patch(`${this.basePath}/${id}`, body);
        return data;
    }

    async remove(id = -1) {
        const { data } = await this.httpClient.delete(`${this.basePath}/${id}`);
        return data;
    }
}
