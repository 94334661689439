export default class ControleMigracaoHttpGateway {
    httpClient;

    basePath = "controle-migracao";

    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    async getAll() {
        const { data } = await this.httpClient.get(this.basePath);
        return data;
    }

    async remove(id = -1) {
        const { data } = await this.httpClient.delete(`${this.basePath}/${id}`);
        return data;
    }
}
