<template>
    <v-navigation-drawer
        :mini-variant.sync="isOpen"
        color="white"
        class="app__sidebar"
        absolute
        width="280px"
    >
        <TheSidebarList />
    </v-navigation-drawer>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
    computed: {
        ...mapGetters(["getSidebarIsClose"]),
        isOpen: {
            get() {
                return this.getSidebarIsClose;
            },
            set(value) {
                this.$store.dispatch("handleSidebarIsClose", value);
            },
        },
    },
});
</script>

<style lang="scss" scoped>
.app__sidebar {
    &.v-navigation-drawer--absolute:not(.v-navigation-drawer--mini-variant) {
        z-index: 9999;
    }
    &::v-deep {
        .v-navigation-drawer__border {
            display: none;
        }
    }
}
</style>
