<template>
    <v-autocomplete
        v-bind="$attrs"
        dense
        :rules="getRules"
        outlined
        v-on="$listeners"
    >
        <template
            v-if="$scopedSlots.item"
            #item="data"
        >
            <slot
                name="item"
                :data="data"
                :item="data.item"
            />
        </template>
    </v-autocomplete>
</template>

<script>
export default {
    props: {
        validationEmpty: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        getRules() {
            let rules = [];
            if (this.$attrs.rules) {
                rules = [...rules, ...this.$attrs.rules];
            }
            if (this.validationEmpty) {
                rules.push((v) => !!v || `O campo ${this.$attrs.label} é obrigatório`);
            }
            return rules;
        },
    },
};
</script>
