<template>
    <BaseCardChart ds-titulo="Utilizado X Estimativa">
        <BaseChartBar
            :chart-data="getChartData"
            :chart-options="chartOptions"
            chart-id="utilizado-estimativa"
        />
    </BaseCardChart>
</template>

<script>
export default {
    props: {
        nrMesAno: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            objUtilizadoEstimativaCategorias: {
                objUtilizado: {
                    vl_mercado: 0,
                    vl_gasolina: 0,
                    vl_lazer: 0,
                },
                objEstimativa: {
                    vl_mercado: 0,
                    vl_gasolina: 0,
                    vl_lazer: 0,
                },
            },
            chartOptions: {
                plugins: {
                    datalabels: {
                        anchor: "end",
                        align: "bottom",
                    },
                },
            },
        };
    },
    computed: {
        getNrMesNrAno() {
            if (this.nrMesAno) {
                const [nr_mes, nr_ano] = this.nrMesAno.split("/");
                return {
                    nr_mes: +nr_mes,
                    nr_ano,
                };
            }
            return {
                nr_mes: 0,
                nr_ano: 0,
            };
        },
        getChartData() {
            return {
                labels: ["Mercado", "Gasolina", "Lazer"],
                datasets: [
                    {
                        label: "Utilizado",
                        backgroundColor: "#517BFB",
                        data: Object.values(this.objUtilizadoEstimativaCategorias.objUtilizado),
                    },
                    {
                        label: "Estimativa",
                        backgroundColor: "#FE2154",
                        data: Object.values(this.objUtilizadoEstimativaCategorias.objEstimativa),
                    },
                ],
            };
        },
    },
    watch: {
        nrMesAno: {
            immediate: true,
            handler(nr_mes_ano) {
                if (nr_mes_ano) {
                    this.getUtilizadoEstimativa();
                }
            },
        },
    },
    methods: {
        async getUtilizadoEstimativa() {
            const { nr_mes, nr_ano } = this.getNrMesNrAno;
            const objUtilizadoEstimativaCategorias = await this.$lancamentosHttpGateway
                .getUtilizadoEstimativa({
                    nr_mes,
                    nr_ano,
                });
            this.objUtilizadoEstimativaCategorias = objUtilizadoEstimativaCategorias;
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
