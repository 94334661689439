import dayjs from "dayjs";
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
dayjs.extend(isSameOrAfter);
import numeroMesNomeEnum from "../constants/contaBancariaFatura/numeroMesNomeEnum";

export default class FaturaHttpGateway {
    httpClient;

    basePath = "/gerenciamento-cartao-credito/fatura";

    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    async getAll(cd_conta_bancaria) {
        const { data } = await this.httpClient.get(this.basePath, {
            params: {
                cd_conta_bancaria
            }
        });
        return data;
    }

    async recalcularValores(cd_fatura) {
        const path = `${this.basePath}/${cd_fatura}/recalcular-valores`;
        const { data } = await this.httpClient.get(path);
        return data;
    }

    async atualizarStatus() {
        const { data } = await this.httpClient.get(`${this.basePath}/atualizar-status`);
        return data || "O status das faturas foram atualizados";
    }

    getNrMesAtual(arrFaturas = []) {
        const result = arrFaturas.find((objFatura) => objFatura.cd_fatura_status === 1);
        if (result) return result.nr_mes;
        if (arrFaturas.length > 0) return arrFaturas.at(-1).nr_mes;
        return null;
    }

    statusFactory(ds_nome, ds_cor, sn_atual = false) {
        const objStatus = {};
        objStatus.ds_nome_status = ds_nome;
        objStatus.ds_cor_status = ds_cor;
        objStatus.sn_atual = sn_atual;
        return objStatus;
    }

    getObjStatus(cd_fatura_status, dt_fechamento, sn_pago, sn_existe_atual = false) {
        const sn_fatura_fechada = cd_fatura_status === 2;
        if (sn_fatura_fechada && sn_pago === false) {
            return this.statusFactory("Atrasada", "error");
        };
        if (sn_fatura_fechada && sn_pago === true) {
            return this.statusFactory("Paga", "success");
        };
        const sn_fatura_aberta = cd_fatura_status === 1;
        if (sn_fatura_aberta && sn_existe_atual === true) {
            return this.statusFactory("Futura", "orange");
        };
        if (sn_fatura_aberta && dayjs().isBefore(dt_fechamento)) {
            return this.statusFactory("Atual", "vdPrimary", true);
        };
        return this.statusFactory("Atual", "vdPrimary");
    }

    getObjMes(ds_mes, ds_ano) {
        const ds_encurtado = numeroMesNomeEnum[ds_mes].substring(0, 3).toUpperCase();
        const ds_completo = numeroMesNomeEnum[ds_mes];
        return {
            ds_encurtado,
            ds_completo,
            nr_mes_formatado: String(ds_mes).padStart(2, '0'),
            ds_completo_ano: `${ds_encurtado}.${ds_ano}`,
            ds_mes_ano: this.getDsMesAno(ds_completo, ds_ano)
        };
    }

    getDsMesAno(ds_mes, ds_ano) {
        return `${ds_mes} de ${ds_ano}`;
    }

    async getFaturasFormatado(cd_conta_bancaria) {
        const arrFaturas = await this.getAll(cd_conta_bancaria);
        if (!arrFaturas || arrFaturas.length === 0) return [];
        // const arrFaturasOdenado = arrFaturas.sort((a, b) => a.nr_mes - b.nr_mes)
        let sn_existe_atual = false;
        return arrFaturas.map((objFatura, nr_indice) => {
            const ds_ano = dayjs(objFatura.dt_fechamento).get('year');
            const objStatus = this.getObjStatus(
                objFatura.cd_fatura_status,
                objFatura.dt_fechamento,
                objFatura.sn_pago,
                sn_existe_atual
            );
            if (objStatus.sn_atual === true) {
                sn_existe_atual = true;
            }
            const objMes = this.getObjMes(objFatura.nr_mes, ds_ano);
            return {
                ...objFatura,
                objStatus,
                objMes,
                nr_indice,
                ds_ano,
                vl_pagar: Math.abs(+objFatura.vl_antecipado - +objFatura.vl_gasto),
                sn_ativo: objStatus.ds_cor_status === "vdPrimary",
            };
        });
    }

    async getByMes(nr_mes = -1, cd_conta_bancaria) {
        const { data } = await this.httpClient.get(this.basePath, {
            params: {
                nr_mes,
                cd_conta_bancaria
            }
        });
        return data;
    }

    async getTransacoesByFatura(cd_fatura, cd_conta_bancaria) {
        const { data } = await this.httpClient.get(`${this.basePath}/transacao`, {
            params: {
                cd_fatura,
                cd_conta_bancaria
            }
        });
        return data;
    }

    async create(body = {}) {
        const { data } = await this.httpClient.post(this.basePath, body);
        return data;
    }

    async update(id = -1, body = {}) {
        const { data } = await this.httpClient.patch(`${this.basePath}/${id}`, body);
        return data;
    }

    async remove(id = -1) {
        const { data } = await this.httpClient.delete(`${this.basePath}/${id}`);
        return data;
    }
}
