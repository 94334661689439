<template>
    <BaseButton
        :color="color"
        dark
        v-bind="$attrs"
        v-on="$listeners"
        @click="vModel = !value"
    >
        <v-icon left>
            mdi-filter
        </v-icon>

        {{ getTextState }} filtros
    </BaseButton>
</template>

<script>
export default {
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        color: {
            type: String,
            default: "teal",
        },
    },

    computed: {
        vModel: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
        getTextState() {
            return this.value ? "Fechar" : "Abrir";
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
