/* eslint-disable class-methods-use-this */
import * as firebase from "firebase/firestore/lite";

export default class FirebaseAdapter {
    firestore;

    constructor(firestore) {
        this.firestore = firestore;
    }

    static getData(querySnapshot) {
        if (querySnapshot.forEach) {
            const data = [];
            querySnapshot.forEach((item) => {
                if (Array.isArray(data)) {
                    data.push({
                        id: item.id,
                        ...item.data(),
                    });
                }
            });
            return data;
        }
        return {
            id: querySnapshot.id,
            ...querySnapshot.data(),
        };
    }

    getQuery(path, constraints) {
        const ref = firebase.collection(this.firestore, path);
        if (constraints) {
            return firebase.query(ref, ...constraints);
        }
        return ref;
    }

    async get(path, constraints = null) {
        const query = this.getQuery(path, constraints);
        const querySnapshot = await firebase.getDocs(query);
        return FirebaseAdapter.getData(querySnapshot);
    }

    async getById(path) {
        const querySnapshot = await firebase.getDoc(path);
        return FirebaseAdapter.getData(querySnapshot);
    }

    async post(path, data, id) {
        const ref = firebase.doc(this.firestore, path, String(id));
        await firebase.setDoc(ref, data);
    }

    async put(path, id, data) {
        const ref = firebase.doc(this.firestore, path, id);
        await firebase.updateDoc(ref, data);
    }

    async delete(path, id) {
        const ref = firebase.doc(this.firestore, path, id);
        await firebase.deleteDoc(ref);
    }
}
