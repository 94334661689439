<template>
    <BaseSheet no-shadow>
        <v-tabs
            v-model="tab"
            color="vdPrimary"
            style="border-bottom: 2px solid #E4E4E4"
        >
            <v-tab
                v-for="objTab in arrTabs"
                :key="objTab.value"
                class="text-none"
            >
                {{ objTab.text }}
            </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
            <v-tab-item
                v-for="objTab in arrTabs"
                :key="objTab.value"
            >
                <ControleDiarioDashboardOverviewTab
                    :nr-mes-ano="nrMesAno"
                    :nr-semana="objTab.value"
                />
            </v-tab-item>
        </v-tabs-items>
    </BaseSheet>
</template>

<script>
import dayjs from "dayjs";
import { mapGetters } from "vuex";

export default {
    props: {
        nrMesAno: {
            type: String,
            default: "",
        },
    },

    data() {
        return {
            tab: null,
            arrTabs: [
                {
                    text: "Semana 1",
                    value: 1,
                },
                {
                    text: "Semana 2",
                    value: 2,
                },
                {
                    text: "Semana 3",
                    value: 3,
                },
                {
                    text: "Semana 4",
                    value: 4,
                },
                {
                    text: "Total",
                    value: 5,
                },
            ],
        };
    },

    computed: {
        ...mapGetters({
            getNrSemanaAtual: "rangeSemanas/getNrSemanaAtual",
        }),
    },

    watch: {
        nrMesAno: {
            immediate: true,
            async handler(nr_mes_ano) {
                if (nr_mes_ano) {
                    const [nr_mes, nr_ano] = nr_mes_ano.split("/");
                    const dt_mes_ano = `${nr_ano}-${nr_mes}`;
                    await this.$store.dispatch("rangeSemanas/fetchAll", {
                        force: true,
                        dt_mes_ano,
                    });
                    this.tab = this.getNrSemanaAtual;
                }
            },
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
