import { monthIndexes, shortMonthIndexes } from "@constants";

export default class DateStringHelper {
    date;

    formatInput;

    splitBy;

    partsString = {
        year: "",
        month: "",
        day: "",
    };

    constructor(date, formatInput = "DD/MM/YYYY", splitBy = "/") {
        this.date = date;
        this.formatInput = formatInput;
        this.splitBy = splitBy;
        this.getSplitString();
    }

    getSplitString() {
        const splitDate = this.date.split(this.splitBy);
        const splitFormatInput = this.formatInput.split(this.splitBy);
        const tmp = {
            YYYY: "",
            MM: "",
            DD: "",
        };
        const [first, second, three] = splitDate;
        tmp[splitFormatInput[0]] = first;
        tmp[splitFormatInput[1]] = second;
        tmp[splitFormatInput[2]] = three;
        const result = {
            year: tmp.YYYY,
            month: tmp.MM,
            day: tmp.DD,
        };
        this.partsString = result;
    }

    getDay() {
        return this.partsString.day;
    }

    getMonthWithoutZeroLeft() {
        const { month } = this.partsString;
        const regexRemoveZeroLeft = /(0)(.{1})/;
        return month.replace(regexRemoveZeroLeft, "$2");
    }

    getMonth(withoutZeroLeft = false) {
        const { month } = this.partsString;
        if (withoutZeroLeft) {
            return this.getMonthWithoutZeroLeft();
        }
        return month;
    }

    getFullMonth() {
        const monthIndex = this.getMonthWithoutZeroLeft();
        return monthIndexes[monthIndex];
    }

    getShortMonth() {
        const monthIndex = this.getMonthWithoutZeroLeft();
        return shortMonthIndexes[monthIndex];
    }

    getYear(lastTwo = false) {
        const { year } = this.partsString;
        if (lastTwo) return year.slice(year.length - 2);
        return year;
    }

    getDateFormatted(format) {
        const splitFormat = format.split(format.match(/(\/)|(-)/)[0]);
        const returnTypes = {
            year: false,
            month: false,
            day: false,
        };
        splitFormat.forEach((element) => {
            switch (element) {
            case "YYYY":
                returnTypes.year = true;
                break;

            case "MM":
                returnTypes.month = true;
                break;

            case "DD":
                returnTypes.day = true;
                break;

            default: break;
            }
        });
        let returnString = format;
        if (returnTypes.year) {
            returnString = returnString.replace("YYYY", this.getYear());
        }
        if (returnTypes.month) {
            returnString = returnString.replace("MM", this.getMonth());
        }
        if (returnTypes.day) {
            returnString = returnString.replace("DD", this.getDay());
        }
        return returnString;
    }
}
