<template>
    <v-container class="nova-transacao">
        <BaseHeaderPage :no-gutters="false" />

        <v-row>
            <v-col>
                <ContaBancariaNovaTransacaoForm
                    ref="contaBancariaNovaTransacaoForm"
                    v-model="objTransacao"
                    :vl-disponivel="objContaBancaria.vl_disponivel"
                    :vl-pagar="getObjFaturaAtual?.vl_pagar || 0"
                    :arr-faturas="arrFaturas"
                />
            </v-col>
            <v-col cols="auto">
                <v-row>
                    <v-col>
                        <ContaBancariaNovaTransacaoResumo
                            :obj-transacao="objTransacao"
                            @validate-form="validateForm"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <ContaBancariaFaturaSidebar
                            v-model="objContaBancaria"
                            :obj-fatura="getObjFaturaAtual"
                        />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import buscarFaturasMixin from "../mixins/buscarFaturasMixin";

export default {
    mixins: [buscarFaturasMixin],

    data() {
        return {
            arrFaturas: [],
            objTransacao: {
                cd_transacao_tipo: null,
                ds_descricao: "",
                dt_data: "",
                dt_processado: "",
                vl_valor: null,
                cd_fatura: null,
            },
            objContaBancaria: {
                vl_disponivel: 0,
            },
        };
    },

    computed: {
        getObjFaturaAtual() {
            return this.arrFaturas.find(
                ({ cd_fatura }) => cd_fatura === this.objTransacao.cd_fatura,
            );
        },
    },

    mounted() {
        this.buscarFaturas();
        const sn_edicao = this.$route.name === "conta-bancaria-editar-transacao";
        if (sn_edicao === true) {
            this.buscarTransacao();
        }
    },

    methods: {
        async buscarTransacao() {
            const { cd_transacao } = this.$route.params;
            const objTransacao = await this.$cartaoCredito.transacaoHttpGateway.getByCodigo(
                +cd_transacao,
            );
            this.objTransacao = { ...objTransacao };
        },
        async buscarFaturas() {
            const { cd_conta_bancaria } = this.$route.params;
            const { arrFaturas } = await this.buscarFaturasMixin(
                cd_conta_bancaria,
            );
            this.arrFaturas = arrFaturas;
        },
        validateForm(callbackValido = () => {}) {
            if (this.$refs.contaBancariaNovaTransacaoForm) {
                return this.$refs.contaBancariaNovaTransacaoForm.validate(callbackValido);
            }
            return false;
        },
    },
};
</script>

<style lang="scss" scoped>
.nova-transacao {
    display: grid;
    grid-template-rows: 95px 1fr;
    row-gap: 1rem;
    $header-height: 64px;
    $padding: 12px;
    $padding-y: $padding * 2;
    min-height: calc(100vh - #{$padding-y} - #{$header-height});
}
</style>
