export default {
    namespaced: true,
    state: {
        arrStatus: [],
    },
    mutations: {
        setArrStatus(state, arrStatus) {
            state.arrStatus = arrStatus;
        },
    },
    actions: {
        async fetchAll(context) {
            if (context.getters.getArrStatusVazio) {
                const response = await this.$configuracoes.statusHttpGateway.getAll();
                context.commit("setArrStatus", response);
            }
        },
    },
    getters: {
        getArrStatus: ({ arrStatus }) => arrStatus,
        getArrStatusByArrCdCategoria: ({ arrStatus }) => (arrCdCategorias) => arrStatus
            .filter(({ cd_status }) => arrCdCategorias.includes(cd_status)),
        getArrStatusVazio: ({ arrStatus }) => arrStatus.length === 0,
    },
};
