import * as firebase from "firebase/firestore/lite";

import HistoryAdapter from "./HistoryAdapter";

export default class HistoryHttpGateway {
    httpClient;

    basePath = "reserva-emergencia";

    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    static getPayload(body) {
        const historyAdapter = new HistoryAdapter();
        const result = historyAdapter.modelToDTO(body);
        return { ...result };
    }

    async update(body) {
        const payload = HistoryHttpGateway.getPayload(body);
        this.httpClient.put(this.basePath, payload.id, payload);
        return payload;
    }

    async getAll() {
        const constraints = [firebase.orderBy("date", "asc")];
        const response = await this.httpClient.get(this.basePath, constraints);
        const data = [];
        const historyAdapter = new HistoryAdapter();
        for (const item of response) {
            data.push(historyAdapter.dtoToModel(item));
        }
        return data;
    }

    async new(body, id) {
        const payload = HistoryHttpGateway.getPayload(body);
        this.httpClient.post(this.basePath, payload, id);
        return payload;
    }

    async delete(id) {
        this.httpClient.delete(this.basePath, id);
    }
}
