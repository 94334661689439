import { shortMonthIndexes } from "@constants";

export default class DateInstanceHelper {
    date;

    constructor(date = new Date()) {
        this.date = date;
    }

    getDay() {
        const day = this.date.getDate();
        const toString = String(day);
        const addZero = toString.padStart(2, "0");
        return addZero;
    }

    getShortMonth() {
        const monthIndex = this.getMonthWithoutZeroLeft();
        return shortMonthIndexes[monthIndex];
    }

    getMonthWithoutZeroLeft() {
        const month = this.getMonth();
        const regexRemoveZeroLeft = /(0)(.{1})/;
        return month.replace(regexRemoveZeroLeft, "$2");
    }

    getMonth() {
        const month = this.date.getMonth() + 1;
        const toString = String(month);
        const addZero = toString.padStart(2, "0");
        return addZero;
    }

    getYear() {
        return String(this.date.getFullYear());
    }

    getDateFormatted(format) {
        const year = format.replace("YYYY", this.getYear());
        const month = year.replace("MM", this.getMonth());
        const day = month.replace("DD", this.getDay());
        return day;
    }
}
