<template>
    <BaseModal
        v-model="sn_modal_aberto"
        max-width="550px"
        persistent
    >
        <template #activator="{ on, attrs }">
            <slot
                name="activator"
                :attrs="attrs"
                :on="on"
            />
        </template>

        <v-card>
            <BaseFormHeader
                ds-nome-tela="Cofrinho"
                :ds-nome-acao="ds_nome_acao"
            />
            <v-form
                ref="form"
                v-model="valid"
                class="cofrinho__form"
                lazy-validation
            >
                <v-row>
                    <v-col
                        class="pb-0"
                        cols="6"
                    >
                        <BaseTextField
                            v-model="payload.ds_nome"
                            label="Nome *"
                            validation-empty
                        />
                    </v-col>
                    <v-col
                        class="pb-0"
                        cols="6"
                    >
                        <BaseAutoComplete
                            v-model="payload.cd_cofrinho_banco"
                            label="Banco *"
                            item-value="cd_cofrinho_banco"
                            item-text="ds_nome"
                            validation-empty
                            :items="arrBancos"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pb-0">
                        <BaseTextField
                            v-model="payload.ds_imagem"
                            label="Imagem *"
                            validation-empty
                        />
                    </v-col>
                </v-row>
            </v-form>
            <BaseFormActions
                :sn-loading="sn_loading"
                @save="salvarAcao"
                @close="fecharModal"
            />
        </v-card>
    </BaseModal>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
    data() {
        return {
            payload: {
                ds_nome: "",
                cd_cofrinho_banco: "",
                ds_imagem: "",
            },
            valid: true,
            sn_loading: false,
            sn_modal_aberto: false,
            ds_nome_acao: "Criar",
            arrBancos: [],
        };
    },

    computed: {
        getSnCriar() {
            return this.ds_nome_acao === "Criar";
        },
    },

    mounted() {
        this.getBancos();
    },

    methods: {
        abrirModal(objPayload = null) {
            if (objPayload) {
                this.resetValidation();
                this.payload = { ...objPayload };
                this.ds_nome_acao = "Editar";
            }
            this.sn_modal_aberto = true;
        },
        fecharModal() {
            this.sn_modal_aberto = false;
            this.ds_nome_acao = "Criar";
            this.resetData();
            this.resetValidation();
        },

        getFormIsValid() {
            return this.$refs.form.validate();
        },

        resetValidation() {
            if (this.$refs.form) {
                this.$refs.form.resetValidation();
            }
        },
        async salvarAcao() {
            const formIsValid = this.getFormIsValid();
            if (formIsValid) {
                this.sn_loading = true;
                let ds_nome_acao = "criado";
                const payload = { ...this.payload };
                if (payload.cd_cofrinho) {
                    delete payload.vl_utilizado;
                    await this.$cofrinhosHttpGateway.update(
                        payload.cd_cofrinho,
                        payload,
                    );
                    ds_nome_acao = "editado";
                } else {
                    await this.$cofrinhosHttpGateway.create(payload);
                }
                this.$emit("atualizar-listagem");
                this.$toast.success(`Conta bancária ${ds_nome_acao} com sucesso!`);
                this.sn_loading = false;
                this.fecharModal();
            }
        },

        resetData() {
            this.payload = {
                ds_nome: "",
                cd_cofrinho_banco: "",
                cd_cofrinho: null,
                ds_imagem: "",
            };
        },

        getBancos() {
            if (this.arrBancos.length > 0) {
                return;
            }
            this.$bancosHttpGateway
                .getAll()
                .then((response) => {
                    this.arrBancos = response;
                })
                .catch((error) => {
                    console.log(error);
                });
        },
    },
});
</script>

<style lang="scss" scoped>
.cofrinho__form {
    padding: 1.5rem;
    margin: 0 auto;
}
</style>
