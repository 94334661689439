<template>
    <v-avatar
        rounded
        :size="getSize"
        :color="getDsCor.secondary"
    >
        <v-icon :color="getDsCor.primary">
            mdi-{{ getDsIcone }}
        </v-icon>
    </v-avatar>
</template>

<script>
export default {
    props: {
        snAntecipado: {
            type: Boolean,
            default: false,
            required: true,
        },
        snLarge: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        getDsIcone() {
            if (this.snAntecipado) return "credit-card-chip-outline";
            return "cart-outline";
        },
        getSize() {
            if (this.snLarge) return "64px";
            return "48px";
        },
        getDsCor() {
            if (this.snAntecipado) {
                return {
                    primary: "green darken-2",
                    secondary: "green lighten-4",
                };
            }
            return {
                primary: "#517BFB",
                secondary: "#517BFB40",
            };
        },
    },
};
</script>
