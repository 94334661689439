export const blue = {
    ds_texto: "#1155cc",
    ds_background: "#a4c2f4",
};
export const green = {
    ds_texto: "#0b8043",
    ds_background: "#b7e1cd",
};

export default {
    Recebido: {
        ds_texto: green.ds_texto,
        ds_background: green.ds_background,
    },
    Pago: {
        ds_texto: green.ds_texto,
        ds_background: green.ds_background,
    },
    "A receber": {
        ds_texto: blue.ds_texto,
        ds_background: blue.ds_background,
    },
    "A pagar": {
        ds_texto: blue.ds_texto,
        ds_background: blue.ds_background,
    },
};
