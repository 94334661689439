export default {
    namespaced: true,
    state: {
        arrPlanilhas: [],
    },
    mutations: {
        setArrPlanilhas(state, arrPlanilhas) {
            state.arrPlanilhas = arrPlanilhas;
        },
    },
    actions: {
        async fetchAll(context, sn_forcar_atualizar = false) {
            if (context.getters.getArrPlanilhasVazio || sn_forcar_atualizar === true) {
                const response = await this.$configuracoes.planilhasHttpGateway.getAll();
                context.commit("setArrPlanilhas", response);
            }
        },
    },
    getters: {
        getArrPlanilhas: ({ arrPlanilhas }) => arrPlanilhas,
        getArrPlanilhasVazio: ({ arrPlanilhas }) => arrPlanilhas.length === 0,
    },
};
