/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
import ContaBancariaHttpGateway from "./ContaBancariaHttpGateway";
import FaturaHttpGateway from "./FaturaHttpGateway";
import TransacaoHttpGateway from "./TransacaoHttpGateway";
import CartaoCreditoHttpGateway from "./CartaoCreditoHttpGateway";
import TransacaoTipoHttpGateway from "./TransacaoTipoHttpGateway";
import CartaoCreditoTipoHttpGateway from "./CartaoCreditoTipoHttpGateway";

const cartaoCreditoFactory = (httpClient) => ({
    contaBancariaHttpGateway: new ContaBancariaHttpGateway(httpClient),
    faturaHttpGateway: new FaturaHttpGateway(httpClient),
    transacaoHttpGateway: new TransacaoHttpGateway(httpClient),
    transacaoTipoHttpGateway: new TransacaoTipoHttpGateway(httpClient),
    tipoHttpGateway: new CartaoCreditoTipoHttpGateway(httpClient),
});

export {
    CartaoCreditoHttpGateway,
};

export default cartaoCreditoFactory;
