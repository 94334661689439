<template>
    <div
        class="transacao"
        v-on="$listeners"
    >
        <div class="transacao__esquerda">
            <ContaBancariaFaturaTransacaoAvatar :sn-antecipado="getSnAntecipado" />

            <div class="transacao__infos">
                <h4 class="font-weight-medium">
                    {{ dsDescricao }}
                </h4>
                <h5 class="text--secondary">
                    {{ getDsHora }}
                </h5>
            </div>
        </div>

        <h3>{{ vlValor | toCurrencyToLocaleString }}</h3>
    </div>
</template>

<script>
export default {
    props: {
        dsDescricao: {
            type: String,
            default: "",
            required: true,
        },
        dtData: {
            type: String,
            default: "",
            required: true,
        },
        vlValor: {
            type: [String, Number],
            default: 0,
            required: true,
        },
        cdTransacaoTipo: {
            type: Number,
            required: true,
        },
    },
    computed: {
        getDsHora() {
            const dt_data = this.dtData?.replace("0Z", "");
            return this.$dayjs(dt_data).format("HH:mm");
        },
        getSnAntecipado() {
            return this.cdTransacaoTipo === 4;
        },
    },
};
</script>

<style lang="scss" scoped>
.transacao {
    $ref: &;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    &__esquerda {
        display: flex;
        column-gap: 20px;

        #{$ref}__infos {
            display: flex;
            justify-content: center;
            flex-direction: column;
        }
    }
}
</style>
