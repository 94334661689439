<!-- eslint-disable vue/no-v-html -->
<template>
    <BaseModal
        v-model="sn_modal_aberto"
        max-width="350px"
    >
        <template #activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                color="vdPrimary"
                dark
                class="text-none"
                v-on="on"
            >
                <v-icon left>
                    mdi-plus
                </v-icon>

                Adicionar
            </v-btn>
        </template>

        <v-card>
            <BaseFormHeader
                ds-nome-tela="Objetivo"
                :ds-nome-acao="ds_nome_acao"
            />
            <v-form
                ref="form"
                v-model="valid"
                style="padding: 1.5rem"
                lazy-validation
            >
                <v-row>
                    <v-col
                        cols="12"
                    >
                        <BaseTextField
                            v-model="payload.ds_nome_aula"
                            color="vdPrimary"
                            label="Nome *"
                            validation-empty
                        />
                    </v-col>
                    <v-col
                        cols="12"
                    >
                        <BaseTimePickerMenu
                            v-model="payload.ds_tempo_aula"
                            label="Tempo aula"
                            validation-empty
                            use-seconds
                        />
                    </v-col>
                </v-row>
            </v-form>
            <BaseFormActions
                :sn-loading="sn_loading"
                @save="salvarAcao"
                @close="fecharModal"
            />
        </v-card>
    </BaseModal>
</template>

<script>
import { defineComponent } from "vue";

const defaultPayload = {
    ds_nome_aula: "",
    nr_tempo_aula: 0,
    ds_tempo_aula: "00:00:00",
};

export default defineComponent({
    data() {
        return {
            payload: { ...defaultPayload },
            valid: true,
            sn_loading: false,
            sn_modal_aberto: false,
            ds_nome_acao: "Criar",
        };
    },

    computed: {
        getSnEditar() {
            return this.ds_nome_acao === "Editar";
        },
    },

    methods: {
        // utilizado via ref
        abrirModal(objPayload = null) {
            if (objPayload) {
                this.ds_nome_acao = "Editar";
                this.payload = {
                    ...objPayload,
                };
            }
            this.sn_modal_aberto = true;
        },
        fecharModal() {
            this.sn_modal_aberto = false;
            this.ds_nome_acao = "Criar";
            this.resetData();
            this.resetValidation();
        },

        getFormIsValid() {
            return this.$refs.form.validate();
        },

        resetValidation() {
            if (this.$refs.form) {
                this.$refs.form.resetValidation();
            }
        },

        timeStringToSeconds(timeString) {
            // Verifica se o formato da string é HH:mm:ss
            const regex = /^(\d{2}):(\d{2}):(\d{2})$/;
            const match = timeString.match(regex);

            if (!match) {
                throw new Error("Formato de string inválido. Utilize HH:mm:ss");
            }

            // Extrai horas, minutos e segundos da string
            const [_, hours, minutes, seconds] = match;

            // Converte horas, minutos e segundos para segundos
            const totalSeconds = parseInt(hours, 10) * 3600 + parseInt(minutes, 10) * 60 + parseInt(seconds, 10);

            return totalSeconds;
        },

        async salvarAcao() {
            const formIsValid = this.getFormIsValid();
            if (formIsValid) {
                try {
                    this.sn_loading = true;
                    const ds_nome_acao = "criado";
                    this.payload.nr_tempo_aula = this.timeStringToSeconds(
                        this.payload.ds_tempo_aula,
                    );
                    await this.$estudosAulasHttpGateway.create(this.payload);
                    this.$emit("atualizar-listagem");
                    this.$toast.success(`Objetivo ${ds_nome_acao} com sucesso!`);
                    this.sn_loading = false;
                    this.fecharModal();
                } catch (e) {
                    this.sn_loading = false;
                }
            }
        },

        resetData() {
            this.payload = { ...defaultPayload };
        },
    },
});
</script>
