export default class EstudosAulasHttpGateway {
    httpClient;

    basePath = "/estudos/aulas";

    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    async getAll() {
        const { data } = await this.httpClient.get(this.basePath);
        return data;
    }

    async create(body = {}) {
        const { data } = await this.httpClient.post(this.basePath, body);
        return data;
    }
}
