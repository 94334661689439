<template>
    <div class="overview">
        <h3>
            {{ getDsRangeData }}
        </h3>

        <div class="overview__saldos">
            <ControleDiarioDashboardOverviewItem
                v-for="objOverview in arrOverview"
                :key="objOverview.ds_nome"
                :obj-categoria="objOverview"
            />
        </div>
    </div>
</template>

<script>
import { formatDate } from "@/modules/Main/helpers";

export default {
    props: {
        nrMesAno: {
            type: String,
            default: "",
        },
        nrSemana: {
            type: Number,
            default: -1,
        },
    },
    data() {
        return {
            arrOverview: [],
            objRangeSemanas: {
                dt_inicio: null,
                dt_final: null,
            },
        };
    },
    computed: {
        getDsRangeData() {
            if (this.objRangeSemanas.dt_inicio && this.objRangeSemanas.dt_final) {
                const dt_inicio_formatada = formatDate(this.objRangeSemanas.dt_inicio);
                const dt_final_formatada = formatDate(this.objRangeSemanas.dt_final);
                return `${dt_inicio_formatada} à ${dt_final_formatada}`;
            }
            return "Sem range de data cadastrado.";
        },
    },
    watch: {
        nrMesAno: {
            immediate: true,
            handler(nr_mes_ano) {
                if (nr_mes_ano) {
                    const [nr_mes, nr_ano] = nr_mes_ano.split("/");
                    if (this.nrSemana < 5) {
                        this.getOverviewSemana(nr_mes, nr_ano);
                    } else {
                        this.getOverviewTotal(nr_mes, nr_ano);
                    }
                }
            },
        },
    },
    methods: {
        async getOverviewSemana(nr_mes, nr_ano) {
            const params = {
                nr_mes: +nr_mes,
                nr_ano,
                nr_semana: this.nrSemana,
            };
            const response = await this.$lancamentosHttpGateway.getOverviewSemana(params);
            this.arrOverview = response.arrItems;
            this.objRangeSemanas = response.objRangeSemanas;
        },
        async getOverviewTotal(nr_mes, nr_ano) {
            const params = {
                nr_mes: +nr_mes,
                nr_ano,
            };
            const response = await this.$lancamentosHttpGateway.getOverviewTotal(params);
            this.arrOverview = response.arrItems;
            this.objRangeSemanas = response.objRangeSemanas;
        },
    },
};
</script>

<style lang="scss" scoped>
.overview {
    padding: 16px;
    width: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
    align-items: flex-start;
    row-gap: 1rem;

    &__saldos {
        display: flex;
        column-gap: 24px;
    }
}
</style>
