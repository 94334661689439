import { LoginView } from "@views/Auth";

import { TheOnly } from "@layouts";

export default [
    {
        path: "/",
        component: TheOnly,
        redirect() {
            return { name: "login" };
        },
        children: [
            {
                path: "",
                name: "login",
                component: LoginView,
                meta: {
                    icon: "mdi-login-variant",
                    viewMenu: true,
                    title: "Login",
                },
            },
        ],
    },
];
