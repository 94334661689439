<template>
    <BaseDatePicker
        v-model="valueLocal"
        v-bind="$attrs"
        :validation-empty="validationEmpty"
        color="vdPrimary"
    />
</template>

<script>
import { DateStringHelper } from "@helpers";
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        value: {
            type: String,
            default: null,
        },
        validationEmpty: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            valueLocal: "",
        };
    },

    watch: {
        valueLocal: {
            immediate: true,
            handler(newValue) {
                if (newValue) {
                    const dateStringHelper = new DateStringHelper(newValue, "YYYY-MM-DD", "-");
                    const valueFormatted = dateStringHelper.getDateFormatted("DD/MM/YYYY");
                    this.$emit("input", valueFormatted);
                } else {
                    this.$emit("input", newValue);
                }
            },
        },
        value: {
            immediate: true,
            handler(newValue) {
                if (newValue) {
                    const dateStringHelper = new DateStringHelper(newValue);
                    const valueFormatted = dateStringHelper.getDateFormatted("YYYY-MM-DD");
                    this.valueLocal = valueFormatted;
                } else {
                    this.valueLocal = newValue;
                }
            },
        },
    },
});
</script>
