<template>
    <BaseModal
        v-model="sn_modal_aberto"
        max-width="550px"
        eager
    >
        <template #activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                color="vdPrimary"
                dark
                v-on="on"
            >
                <v-icon left>
                    mdi-plus
                </v-icon>

                Adicionar
            </v-btn>
        </template>

        <v-card>
            <BaseFormHeader
                ds-nome-tela="Página"
                :ds-nome-acao="ds_nome_acao"
            />
            <v-form
                ref="configuracoesPaginasModalSalvarForm"
                v-model="valid"
                class="form"
                lazy-validation
            >
                <v-row>
                    <v-col class="pb-0">
                        <BaseAutoComplete
                            v-model="payload.cd_controle_migracao_planilha"
                            label="Planilha *"
                            validation-empty
                            item-text="ds_nome"
                            item-value="cd_controle_migracao_planilha"
                            :items="arrPlanilhas"
                        />
                    </v-col>
                    <v-col class="pb-0">
                        <BaseAutoComplete
                            v-model="payload.ds_nome"
                            label="Nome *"
                            validation-empty
                            autocomplete="off"
                            :items="getArrMeses"
                            :disabled="!payload.cd_controle_migracao_planilha"
                        />
                    </v-col>
                </v-row>
            </v-form>
            <BaseFormActions
                :sn-loading="sn_loading"
                @save="salvarAcao"
                @close="fecharModal"
            />
        </v-card>
    </BaseModal>
</template>

<script>
import { defineComponent } from "vue";
import { mapGetters } from "vuex";
import arrMeses from "../../constants/pagina/arrMeses.json";

export default defineComponent({
    props: {
        value: {
            type: Object,
            default: () => {},
        },
        isEdit: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            payload: {
                ds_nome: "",
                cd_controle_migracao_planilha: null,
            },
            sn_loading: false,
            sn_modal_aberto: false,
            ds_nome_acao: "Criar",
            valid: true,

        };
    },

    computed: {
        ...mapGetters({
            arrPaginas: "paginas/getArrPaginas",
            arrPlanilhas: "planilhas/getArrPlanilhas",
        }),
        getSnEditar() {
            return this.ds_nome_acao === "Editar";
        },
        getArrMeses() {
            return arrMeses.filter((ds_nome) => !this.arrPaginas.find(
                (objPagina) => objPagina.ds_nome === ds_nome
                    && objPagina.cd_controle_migracao_planilha
                        === this.payload.cd_controle_migracao_planilha,
            ));
        },
    },

    mounted() {
        this.$store.dispatch("planilhas/fetchAll");
    },

    methods: {
        abrirModal(objPayload = null) {
            if (objPayload) {
                this.ds_nome_acao = "Editar";
                this.payload = { ...objPayload };
            }
            this.sn_modal_aberto = true;
        },
        fecharModal() {
            this.sn_modal_aberto = false;
            this.ds_nome_acao = "Criar";
            this.resetData();
            this.resetValidation();
        },

        getFormIsValid() {
            return this.$refs.configuracoesPaginasModalSalvarForm.validate();
        },

        resetValidation() {
            if (this.$refs.configuracoesPaginasModalSalvarForm) {
                this.$refs.configuracoesPaginasModalSalvarForm.resetValidation();
            }
        },

        paginasHttpGateway() {
            return this.$configuracoes.paginasHttpGateway;
        },

        async salvarAcao() {
            const formIsValid = this.getFormIsValid();
            if (formIsValid) {
                this.sn_loading = true;
                let ds_nome_acao = "criado";
                if (this.payload.cd_controle_migracao_planilha_paginas) {
                    await this.paginasHttpGateway().update(
                        this.payload.cd_controle_migracao_planilha_paginas,
                        this.payload,
                    );
                    ds_nome_acao = "editado";
                } else {
                    await this.paginasHttpGateway().create(this.payload);
                }
                this.$store.dispatch("paginas/fetchAll", true);
                this.$toast.success(`Pagina ${ds_nome_acao} com sucesso!`);
                this.sn_loading = false;
                this.fecharModal();
            }
        },

        resetData() {
            this.payload = {
                ds_nome: "",
            };
        },
    },
});
</script>

<style lang="scss" scoped>
.form {
    padding: 1.5rem;
    margin: 0 auto;
}
</style>
