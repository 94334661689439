import Vue from "vue";
import formatDate from "./formatDate";
import formatDateWithTime from "./formatDateWithTime";
import toCurrency, { toCurrencyToLocaleString } from "./toCurrencyHelper";
import hiddenValue from "./hiddenValue";
import booleanToSimNao from "./booleanToSimNao";
import toPercent from "./toPercent";

const filters = {
    toPercent,
    toCurrency,
    formatDate,
    formatDateWithTime,
    toCurrencyToLocaleString,
    hiddenValue,
    booleanToSimNao,
};

export default function initFilters() {
    Object.keys(filters).forEach((key) => Vue.filter(key, filters[key]));
}
