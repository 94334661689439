<template>
    <div class="transacoes">
        <template v-if="getArrAgrupamentoTransacoes.length > 0">
            <div
                v-for="([dt_agrupamento, arrTransacoes]) in getArrAgrupamentoTransacoes"
                :key="dt_agrupamento"
            >
                <header class="transacoes__header">
                    <h4>{{ dt_agrupamento }}</h4>
                    <v-divider />
                </header>
                <div class="transacoes__lista">
                    <ContaBancariaFaturaTransacao
                        v-for="objTransacao in arrTransacoes"
                        :key="objTransacao.cd_transacao"
                        :ds-descricao="objTransacao.ds_descricao_formatado"
                        :dt-data="objTransacao.dt_data"
                        :vl-valor="objTransacao.vl_valor"
                        :cd-transacao-tipo="objTransacao.cd_transacao_tipo"
                        @click="abrirModalInfo(objTransacao)"
                    />
                </div>
            </div>
        </template>

        <div
            v-else
            class="transacoes--empty"
        >
            <NoData />
            <h4 class="text--secondary">
                Sem transações nessa fatura
            </h4>
        </div>
    </div>
</template>

<script>
import dayjs from "dayjs";

export default {
    props: {
        nrMes: {
            type: Number,
            default: -1,
        },
        cdFatura: {
            type: Number,
            default: -1,
        },
        cdContaBancaria: {
            type: [Number, String],
            default: -1,
        },
    },

    data() {
        return {
            arrTransacoesNaoFormatadas: [],
        };
    },

    computed: {
        getArrAgrupamentoTransacoes() {
            return this.getTransacoesAgrupadaPorData(this.arrTransacoesNaoFormatadas);
        },
    },

    mounted() {
        this.buscarTransacoes();
    },

    methods: {
        abrirModalInfo(objTransacao) {
            this.$emit("abrir-modal-info", objTransacao);
        },
        getTransacoesAgrupadaPorData(arrTransacoes = []) {
            const objTransacoesAgrupadoData = {};
            for (const objTransacao of arrTransacoes) {
                const dt_data = objTransacao.dt_data.replace("0Z", "");
                const dt_data_formatado = dayjs(dt_data).format("DD MMM").toUpperCase();
                const sn_existe = Object.prototype.hasOwnProperty.call(
                    objTransacoesAgrupadoData,
                    dt_data_formatado,
                );
                if (!sn_existe) {
                    objTransacoesAgrupadoData[dt_data_formatado] = [];
                }
                objTransacoesAgrupadoData[dt_data_formatado].push(objTransacao);
            }
            return Object.entries(objTransacoesAgrupadoData);
        },
        async getTransacoesByFatura() {
            return this.$cartaoCredito.faturaHttpGateway
                .getTransacoesByFatura(
                    this.cdFatura,
                    this.cdContaBancaria,
                );
        },
        async buscarTransacoes() {
            try {
                const arrTransacoes = await this.getTransacoesByFatura();
                if (arrTransacoes.length > 0) {
                    this.arrTransacoesNaoFormatadas = arrTransacoes;
                }
            } catch (e) {
                console.log(e);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.transacoes {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    row-gap: 16px;
    padding: 16px 24px;

    &--empty {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        row-gap: 12px;

        > svg {
            width: 150px;
            height: 150px;
        }

        > h4 {
            font-weight: 500;
        }
    }

    &__header {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;
        column-gap: 1rem;
    }

    &__lista {
        display: flex;
        flex-direction: column;
        row-gap: 16px
    }
}
</style>
