<template>
    <BaseCardChart ds-titulo="Comparação Semanal: Disponível">
        <BaseChartBar
            :chart-data="getChartData"
            chart-id="comparacao-semanal-disponivel"
            :chart-options="chartOptions"
        />
    </BaseCardChart>
</template>

<script>
import { toCurrencyToLocaleString } from "@/modules/Main/helpers";

export default {
    props: {
        nrMesAno: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            objCategoriasSemanasDisponivel: {
                mercado: {
                    semana1: 0,
                    semana2: 0,
                    semana3: 0,
                    semana4: 0,
                },
                gasolina: {
                    semana1: 0,
                    semana2: 0,
                    semana3: 0,
                    semana4: 0,
                },
                lazer: {
                    semana1: 0,
                    semana2: 0,
                    semana3: 0,
                    semana4: 0,
                },
            },
            chartOptions: {
                scales: {
                    x: {
                        stacked: true,
                    },
                    y: {
                        stacked: true,
                    },
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            footer: (tooltipItems) => {
                                const arrValores = tooltipItems.map(
                                    (tooltipItem) => tooltipItem.parsed.y,
                                );
                                const vl_soma = arrValores.reduce(
                                    (previous, current) => previous + current,
                                );
                                const vl_soma_formatado = toCurrencyToLocaleString(vl_soma);
                                return `Soma: ${vl_soma_formatado}`;
                            },
                        },
                    },
                },
            },
        };
    },
    computed: {
        getNrMesNrAno() {
            if (this.nrMesAno) {
                const [nr_mes, nr_ano] = this.nrMesAno.split("/");
                return {
                    nr_mes,
                    nr_ano,
                };
            }
            return {
                nr_mes: 0,
                nr_ano: 0,
            };
        },
        getChartData() {
            return {
                labels: ["Semana 1", "Semana 2", "Semana 3", "Semana 4"],
                datasets: [
                    {
                        label: "Mercado",
                        backgroundColor: "#517BFB",
                        data: Object.values(this.objCategoriasSemanasDisponivel.mercado),
                    },
                    {
                        label: "Gasolina",
                        backgroundColor: "#FE2154",
                        data: Object.values(this.objCategoriasSemanasDisponivel.gasolina),
                    },
                    {
                        label: "Lazer",
                        backgroundColor: "#AF5AF4",
                        data: Object.values(this.objCategoriasSemanasDisponivel.lazer),
                    },
                ],
            };
        },
    },
    watch: {
        nrMesAno: {
            immediate: true,
            handler(nr_mes_ano) {
                if (nr_mes_ano) {
                    const [nr_mes, nr_ano] = nr_mes_ano.split("/");
                    this.getComparacaoSemanalDisponivel(nr_mes, nr_ano);
                }
            },
        },
    },
    methods: {
        async getComparacaoSemanalDisponivel() {
            const { nr_mes, nr_ano } = this.getNrMesNrAno;
            const objSemanasDisponivel = await this.$lancamentosHttpGateway
                .getComparacaoSemanalDisponivel({
                    nr_mes,
                    nr_ano,
                });
            this.objCategoriasSemanasDisponivel = objSemanasDisponivel;
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
