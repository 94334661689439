import TheDefaultVue from "../Main/layouts/TheDefault.vue";
import DashboardView from "./views/DashboardView.vue";
import ItemsView from "./views/ItemsView.vue";

export default [
    {
        path: "/league-of-legends",
        name: "league-of-legends",
        component: TheDefaultVue,
        meta: {
            icon: "LeagueOfLegendsIcon",
            viewMenu: true,
            title: "League Of Legends",
            iconComponent: true,
        },
        redirect() {
            return { name: "league-of-legends-dashboard" };
        },
        children: [
            {
                path: "dashboard",
                name: "league-of-legends-dashboard",
                component: DashboardView,
                meta: {
                    icon: "mdi-view-dashboard",
                    viewMenu: true,
                    title: "Dashboard",
                },
            },
            {
                path: "items",
                name: "league-of-legends-items",
                component: ItemsView,
                meta: {
                    icon: "mdi-hand-coin",
                    viewMenu: true,
                    title: "Items",
                },
            },
        ],
    },
];
