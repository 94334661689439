import Vue from "vue";

import {
    mainRouter as router,
    mainStore as store,
} from "@module/Main";

import {
    initFilters,
    injectGatewaysVue,
    routesFactory,
    storeFactory,
} from "@helpers";

import App from "./App.vue";

import "./plugins/toastification";
import "./plugins/day";
import vuetify from "./plugins/vuetify";
import initTiptapVuetify from "./plugins/tiptap-vuetify";

Vue.config.productionTip = false;

injectGatewaysVue();
initFilters();
storeFactory();
routesFactory();
initTiptapVuetify(vuetify);

new Vue({
    store,
    router,
    vuetify,
    render: (h) => h(App),
}).$mount("#app");
