<template>
    <v-form
        ref="form"
        v-model="valid"
        class="lancamentos__form"
        lazy-validation
    >
        <v-row>
            <v-col
                class="pb-0"
                cols="12"
            >
                <BaseTextField
                    v-model="vModel.ds_descricao"
                    label="Descrição *"
                    validation-empty
                />
            </v-col>
        </v-row>

        <v-row>
            <v-col
                class="pb-0"
                cols="6"
            >
                <BaseTextFieldMoney
                    ref="inputCurrency"
                    v-model="handleNrValor"
                    label="Valor *"
                    validation-empty
                />
            </v-col>

            <v-col
                class="pb-0"
                cols="6"
            >
                <BaseDatePickerFormatted
                    v-model="handleDtData"
                    label="Data *"
                    validation-empty
                />
            </v-col>
        </v-row>

        <v-row>
            <v-col
                class="pb-0"
                cols="6"
            >
                <BaseAutoComplete
                    v-model="vModel.cd_categoria"
                    label="Categoria *"
                    item-value="cd_categoria"
                    item-text="ds_nome"
                    validation-empty
                    :loading="sn_loading_categorias"
                    :items="arrCategorias"
                />
            </v-col>

            <v-col
                class="pb-0"
                cols="6"
            >
                <BaseAutoComplete
                    v-model="vModel.cd_status"
                    label="Status *"
                    item-value="cd_status"
                    item-text="ds_nome"
                    validation-empty
                    :loading="sn_loading_status"
                    :disabled="!vModel.cd_categoria"
                    :items="arrStatus"
                />
            </v-col>
        </v-row>

        <v-row>
            <v-col cols="12">
                <BaseTextArea
                    v-model="vModel.ds_observacao"
                    label="Observações"
                    cols="2"
                    hide-details
                    rows="3"
                />
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

import { cleanCurrency, toCurrency } from "@helpers";

import arrCdStatus from "../../constants/arrCdStatusByCategoria";

export default defineComponent({
    name: "LancamentosModalSalvarForm",

    props: {
        value: {
            type: Object,
            default: () => {},
        },
        isEdit: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            valid: true,
            sn_loading_categorias: false,
            sn_loading_status: false,
            nr_valor: "R$ 0,00",
        };
    },

    computed: {
        ...mapGetters({
            getArrCategoriasByArrCdCategoria: "categorias/getArrCategoriasByArrCdCategoria",
            getArrStatusByArrCdCategoria: "status/getArrStatusByArrCdCategoria",
        }),
        arrCategorias() {
            return this.getArrCategoriasByArrCdCategoria([4, 5, 6, 7, 8]);
        },
        arrStatus() {
            const getArrCdStatus = this.vModel.cd_categoria === 4
                ? arrCdStatus.receitas : arrCdStatus.despesas;
            return this.getArrStatusByArrCdCategoria(getArrCdStatus);
        },
        vModel: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
        handleNrValor: {
            get() {
                return this.nr_valor;
            },
            set(value) {
                const cleanValue = cleanCurrency(value);
                this.nr_valor = value;
                this.vModel.nr_valor = cleanValue;
            },
        },
        handleDtData: {
            get() {
                if (this.vModel.dt_data) {
                    const [ano, mes, dia] = this.vModel.dt_data.split("-");
                    return `${dia}/${mes}/${ano}`;
                }
                return this.getDateDefault();
            },
            set(dt_data) {
                if (dt_data) {
                    const [dia, mes, ano] = dt_data.split("/");
                    const dt_data_formatada = `${ano}-${mes}-${dia}`;
                    this.vModel.dt_data = dt_data_formatada;
                } else {
                    this.vModel.dt_data = dt_data;
                }
            },
        },
    },

    mounted() {
        this.fetchCategorias();
        this.fetchStatus();
    },

    methods: {
        async fetchCategorias() {
            this.sn_loading_categorias = true;
            await this.$store.dispatch("categorias/fetchAll");
            this.sn_loading_categorias = false;
        },
        async fetchStatus() {
            this.sn_loading_status = true;
            await this.$store.dispatch("status/fetchAll");
            this.sn_loading_status = false;
        },
        getDateDefault() {
            return this.$dayjs().format("DD/MM/YYYY");
        },
        validate() {
            return this.$refs.form.validate();
        },
        resetValidation() {
            return this.$refs.form.resetValidation();
        },
        setValueInputCurrency(nr_valor = 0) {
            if (this.$refs.inputCurrency) {
                const input = this.$refs.inputCurrency.$el.querySelector("input");
                const nr_valor_formatado = toCurrency(nr_valor);
                input.value = nr_valor_formatado;
            }
        },
    },
});
</script>

<style lang="scss" scoped>
.lancamentos__form {
    padding: 1.5rem;
    margin: 0 auto;
}
</style>
