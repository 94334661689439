export default class CalculationModel {
    id;

    setId(value) {
        this.id = value;
    }

    category;

    setCategory(value) {
        this.category = value;
    }

    value;

    setValue(value) {
        this.value = value;
    }

    threeMonths;

    setThreeMonths(value) {
        this.threeMonths = value;
    }

    sixMonths;

    setSixMonths(value) {
        this.sixMonths = value;
    }

    twelveMonths;

    setTwelveMonths(value) {
        this.twelveMonths = value;
    }
}
