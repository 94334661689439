<template>
    <div class="dashboard">
        <header>
            <BaseHeaderPage>
                <template #right>
                    <div class="d-flex justify-end align-center h-100">
                        <BaseButtonFilter
                            v-model="sn_mostrar_filtros"
                            color="vdPrimary"
                        />
                    </div>
                </template>
            </BaseHeaderPage>

            <BaseFilters
                v-model="filters"
                :view-filters="sn_mostrar_filtros"
                view-month-year
                @clearAction="clearAction"
                @searchAction="searchAction"
            />
        </header>

        <ControleDiarioDashboardOverviewCard :nr-mes-ano="nr_mes_ano" />

        <div>
            <v-row>
                <v-col cols="6">
                    <ControleDiarioDashboardComparacaoSemanalUtilizado :nr-mes-ano="nr_mes_ano" />
                </v-col>
                <v-col cols="6">
                    <ControleDiarioDashboardComparacaoSemanalDisponivel :nr-mes-ano="nr_mes_ano" />
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="6">
                    <ControleDiarioDashboardComparacaoMensalEstimativa :nr-mes-ano="nr_mes_ano" />
                </v-col>
                <v-col cols="6">
                    <ControleDiarioDashboardComparacaoMensalUtilizado :nr-mes-ano="nr_mes_ano" />
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="6">
                    <ControleDiarioDashboardUtilizadoEstimativa :nr-mes-ano="nr_mes_ano" />
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import dayjs from "dayjs";
import { mapGetters } from "vuex";

export default {
    data() {
        return {
            sn_mostrar_filtros: false,
            filters: {
                monthYear: null,
            },
            nr_mes_ano: null,
        };
    },
    computed: {
        ...mapGetters({
            getIsHideValues: "getIsHideValues",
        }),
    },
    async mounted() {
        const nr_mes_ano = this.$dayjs().format("MM/YYYY");
        this.filters.monthYear = nr_mes_ano;
        this.nr_mes_ano = nr_mes_ano;
        this.tab = 0;
    },
    methods: {
        async searchAction(filters) {
            this.nr_mes_ano = filters.monthYear;
        },
        clearAction() {
            const nr_mes_ano = this.$dayjs().format("MM/YYYY");
            this.filters.monthYear = nr_mes_ano;
            this.nr_mes_ano = nr_mes_ano;
            this.fetchInitialDataDashboard();
        },
        async fetchInitialDataDashboard() {
            await this.searchAction({
                monthYear: this.$dayjs().format("MM/YYYY"),
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.dashboard {
    $ref: &;
    max-width: 1200px;
    height: 100%;
    margin: 0 auto;
    display: grid;
    grid-template-rows: auto 1fr;
    row-gap: 24px;
}
</style>
