<template>
    <div class="d-flex align-center justify-end h-100">
        <BaseButton
            color="orange"
            dark
            class="mr-4"
            @click="migrateData"
        >
            <v-icon left>
                mdi-sync
            </v-icon>

            Sincronizar dados planilha
        </BaseButton>

        <BaseButton
            color="primary"
            dark
            class="mr-4"
            @click="updateViewFilters"
        >
            <v-icon left>
                mdi-filter
            </v-icon>

            {{ getTextState }} Filtros
        </BaseButton>

        <ModalNewHistory
            v-model="newModalIsOpen"
            :action-name="newModal.actionName"
            :data="newModalData"
        />
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import { dataGoogleSheet } from "@helpers";

export default {
    props: {
        viewFilters: {
            type: Boolean,
            default: false,
        },
        newModal: {
            type: Object,
            default: () => ({
                isOpen: false,
                data: null,
                actionName: "Novo",
            }),
        },
    },

    computed: {
        ...mapGetters({
            getList: "reservaEmergencia/getList",
            getListAllItems: "reservaEmergencia/getListAllItems",
        }),
        getTextState() {
            return this.viewFilters ? "FECHAR" : "ABRIR";
        },
        newModalIsOpen: {
            get() {
                return this.newModal.isOpen;
            },
            set(value) {
                this.$emit("updateNewModalIsOpen", value);
            },
        },
        newModalData() {
            return this.newModal.data;
        },
    },

    methods: {
        async migrateData() {
            try {
                const spreadsheetID = "1w2Su4JSrc-PsJuF3dI2uScdJBDSvTSO68Ix1iElBV9U";
                const listAllItems = this.getListAllItems;
                const { fetchData, migrateDataHistory } = dataGoogleSheet;
                const response = await fetchData(
                    spreadsheetID,
                    migrateDataHistory,
                    {
                        currentList: listAllItems,
                    },
                );
                const existData = response.length > 0;
                if (existData) {
                    for await (const item of response) {
                        await this.$store.dispatch("reservaEmergencia/saveItem", item);
                    }
                    this.$toast.success("Dados migrados com sucesso!");
                } else {
                    this.$toast.info("Todos os dados já foram migrados!");
                }
            } catch (e) {
                console.error(e);
                this.$toast.error("Ocorreu algum erro e não foi possível migrar os dados!");
            }
        },
        updateViewFilters() {
            this.$emit("updateViewFilters");
        },
    },
};
</script>
