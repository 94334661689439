import { toCurrencyToLocaleString } from "@helpers";

const items = (fullValue, entries, goal = 0) => [
    {
        icon: "mdi-cash-plus",
        color: "green",
        title: "Valor Total Depositado",
        value: entries,
        isCurrency: true,
    },
    {
        icon: "mdi-cash-multiple",
        color: "blue",
        title: "Saldo Atual",
        value: fullValue,
        isCurrency: true,
    },
    {
        icon: "mdi-bullseye-arrow",
        color: "deep-purple",
        title: "Meta",
        value: `${toCurrencyToLocaleString(goal)} / ${((fullValue / goal) * 100).toFixed(2)}%`,
        isCurrency: false,
    },
];

export default items;
