<template>
    <BaseModal
        v-model="sn_modal_aberto"
        max-width="550px"
        eager
    >
        <template #activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                color="vdPrimary"
                dark
                v-on="on"
            >
                <v-icon left>
                    mdi-plus
                </v-icon>

                Adicionar
            </v-btn>
        </template>

        <v-card>
            <BaseFormHeader
                ds-nome-tela="Status"
                :ds-nome-acao="ds_nome_acao"
            />
            <ConfiguracoesStatusModalSalvarForm
                ref="configuracoesStatusModalSalvarForm"
                v-model="payload"
                :is-edit="getSnEditar"
            />
            <BaseFormActions
                :sn-loading="sn_loading"
                @save="salvarAcao"
                @close="fecharModal"
            />
        </v-card>
    </BaseModal>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
    data() {
        return {
            payload: {
                ds_nome: "",
            },
            sn_loading: false,
            sn_modal_aberto: false,
            ds_nome_acao: "Criar",
        };
    },

    computed: {
        getSnEditar() {
            return this.ds_nome_acao === "Editar";
        },
    },

    methods: {
        abrirModal(objPayload = null) {
            if (objPayload) {
                this.ds_nome_acao = "Editar";
                this.payload = { ...objPayload };
            }
            this.sn_modal_aberto = true;
        },
        fecharModal() {
            this.sn_modal_aberto = false;
            this.ds_nome_acao = "Criar";
            this.resetData();
            this.resetValidation();
        },

        getFormIsValid() {
            return this.$refs.configuracoesStatusModalSalvarForm.validate();
        },

        resetValidation() {
            if (this.$refs.configuracoesStatusModalSalvarForm) {
                this.$refs.configuracoesStatusModalSalvarForm.resetValidation();
            }
        },

        statusHttpGateway() {
            return this.$configuracoes.statusHttpGateway;
        },

        async salvarAcao() {
            const formIsValid = this.getFormIsValid();
            if (formIsValid) {
                this.sn_loading = true;
                let ds_nome_acao = "criado";
                if (this.payload.cd_status) {
                    const objStatus = await this.statusHttpGateway().update(
                        this.payload.cd_status,
                        this.payload,
                    );
                    this.$emit("atualizar", objStatus);
                    ds_nome_acao = "editado";
                } else {
                    const objStatus = await this.statusHttpGateway().create(this.payload);
                    this.$emit("adicionar", objStatus);
                }
                this.$toast.success(`Status ${ds_nome_acao} com sucesso!`);
                this.sn_loading = false;
                this.fecharModal();
            }
        },

        resetData() {
            this.payload = {
                ds_nome: "",
            };
        },
    },
});
</script>
