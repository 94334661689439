import {
    getAuth,
    signInWithEmailAndPassword,
    AuthErrorCodes,
    signOut,
} from "firebase/auth";

import {
    InvalidCredentialsError,
    GenericSignInError,
    GenericSignOutError,
    UserInvalidError,
} from "../utils";

export default class AuthHttpGateway {
    auth;

    constructor(app) {
        this.auth = getAuth(app);
    }

    async signIn(credentials) {
        try {
            const { email, password } = credentials;
            const userCredential = await signInWithEmailAndPassword(this.auth, email, password);
            return Promise.resolve(userCredential);
        } catch (e) {
            console.log(e);
            const {
                INVALID_EMAIL,
                INVALID_PASSWORD,
                USER_DELETED,
            } = AuthErrorCodes;
            const invalidCredentialsKey = [INVALID_EMAIL, INVALID_PASSWORD];
            const credentialsIsInvalid = invalidCredentialsKey.includes(e.code);
            if (credentialsIsInvalid) return new InvalidCredentialsError();
            const userIsInvalid = USER_DELETED === e.code;
            if (userIsInvalid) return new UserInvalidError();
            return Promise.reject(new GenericSignInError());
        }
    }

    async signOut() {
        try {
            await signOut(this.auth);
            return Promise.resolve();
        } catch (e) {
            const error = new GenericSignOutError();
            return Promise.reject(error);
        }
    }
}
