<template>
    <v-menu
        ref="menu"
        v-model="sn_aberto"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="vModel"
        transition="scale-transition"
        color="vdPrimary"
        offset-y
        :disabled="disabled"
        v-bind="$attrs"
        max-width="290px"
        min-width="290px"
    >
        <template #activator="{ on, attrs }">
            <BaseTextField
                v-model="vModel"
                :label="`${$attrs.label ?? 'Hora'} ${getSufixo}`"
                readonly
                :validation-empty="validationEmpty"
                outlined
                v-bind="attrs"
                :disabled="disabled"
                :hide-details="hideDetailsInput"
                v-on="on"
            />
        </template>
        <v-time-picker
            v-if="sn_aberto"
            v-model="vModel"
            full-width
            format="24hr"
            :use-seconds="useSeconds"
            @click:minute="closeClickInMinute"
            @click:second="closeClickInSecond"
        />
    </v-menu>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            default: "",
        },
        validationEmpty: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        useSeconds: {
            type: Boolean,
            default: false,
        },
        hideDetailsInput: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            sn_aberto: false,
            time: null,
        };
    },

    computed: {
        vModel: {
            get() {
                return this.value;
            },
            set(ds_valor) {
                this.$emit("input", ds_valor);
            },
        },
        getSufixo() {
            return this.validationEmpty === true ? "*" : "";
        },
    },
    methods: {
        closeClickInMinute() {
            if (this.useSeconds === false) {
                this.$refs.menu.save(this.vModel);
            }
        },
        closeClickInSecond() {
            if (this.useSeconds === true) {
                this.$refs.menu.save(this.vModel);
            }
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
