import TheDefaultVue from "../Main/layouts/TheDefault.vue";
import AnotacoesView from "./views/AnotacoesView.vue";
import ObjetivosView from "./views/ObjetivosView.vue";
import CursosView from "./views/CursosView.vue";
// import CursoView from "./views/CursoView.vue";
import TheView from "../Main/components/layout/TheView.vue";

export default [
    {
        path: "/estudos",
        name: "estudos",
        component: TheDefaultVue,
        meta: {
            icon: "mdi-book-open-variant",
            viewMenu: true,
            title: "Estudos",
        },
        redirect() {
            return { name: "estudos-cursos" };
        },
        children: [
            // {
            //     path: "anotacoes",
            //     name: "estudos-anotacoes",
            //     component: AnotacoesView,
            //     meta: {
            //         icon: "mdi-history",
            //         viewMenu: true,
            //         title: "Anotações",
            //     },
            // },
            // {
            //     path: "objetivos",
            //     name: "estudos-objetivos",
            //     component: ObjetivosView,
            //     meta: {
            //         icon: "mdi-bullseye-arrow",
            //         viewMenu: true,
            //         title: "Objetivos",
            //     },
            // },
            {
                path: "cursos",
                name: "estudos-cursos",
                component: TheView,
                meta: {
                    viewMenu: true,
                    title: "Cursos",
                },
                redirect() {
                    return { name: "estudos-cursos-default" };
                },
                children: [
                    {
                        path: "",
                        name: "estudos-cursos-default",
                        component: CursosView,
                        meta: {
                            icon: "mdi-bullseye-arrow",
                            viewMenu: true,
                            title: "Cursos",
                        },
                    },
                    {
                        path: "curso/:id",
                        name: "estudos-curso",
                        component: AnotacoesView,
                        meta: {
                            icon: "mdi-bullseye-arrow",
                            viewMenu: true,
                            title: "Curso",
                        },
                    },
                ],
            },
        ],
    },
];
