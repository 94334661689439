<template>
    <BaseModal
        v-model="vModel"
        max-width="650px"
    >
        <template #activator="{ on, attrs }">
            <slot
                :on="on"
                :attrs="attrs"
            />
        </template>

        <v-card>
            <ModalFormHistoryHeader :action-name="actionName" />
            <FormHistory
                ref="form"
                v-model="dataLocal"
                :is-edit="actionName === 'Editar'"
            />
            <ModalFormHistoryActions
                :is-loading="isLoading"
                @save="saveAction"
                @close="closeDialog"
            />
        </v-card>
    </BaseModal>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        actionName: {
            type: String,
            default: "Novo",
        },
        data: {
            type: Object,
            default: () => {},
        },
        isLoading: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            dataLocal: {},
        };
    },

    computed: {
        vModel: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
                if (!value) {
                    this.resetData();
                }
            },
        },
    },

    watch: {
        value() {
            this.resetValidation();
        },
        data: {
            immediate: true,
            handler(newData) {
                this.dataLocal = { ...newData };
            },
        },
        actionName: {
            immediate: true,
            handler(newValue) {
                return newValue;
            },
        },
    },

    methods: {
        closeDialog() {
            if (this.$listeners.closeDialog) {
                this.$emit("closeDialog");
            } else {
                this.vModel = false;
            }
        },

        getFormIsValid() {
            return this.$refs.form.validate();
        },

        resetValidation() {
            if (this.$refs.form) {
                this.$refs.form.resetValidation();
            }
        },

        saveAction() {
            const formIsValid = this.getFormIsValid();
            if (formIsValid) {
                this.updateData(this.dataLocal);
                this.$emit("saveAction");
            }
        },

        updateData(value) {
            this.$emit("updateData", value);
        },

        resetData() {
            this.updateData({});
            if (this.$refs.form) {
                this.$refs.form.setValueInputCurrency();
            }
        },
    },
});
</script>
