<template>
    <aside class="fatura__sidebar">
        <ContaBancariaFaturaContaBancaria v-model="vModel" />

        <ContaBancariaFaturaInfos :obj-fatura="objFatura" />
    </aside>
</template>

<script>

export default {
    props: {
        objFatura: {
            type: Object,
            default: () => ({}),
        },
        value: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        vModel: {
            get() {
                return this.value;
            },
            set(ds_valor) {
                this.$emit("input", ds_valor);
            },
        },
    },
};
</script>

<style lang="scss" scoped>
.fatura__sidebar {
    $ref: '.fatura';
    display: flex;
    flex-direction: column;
    row-gap: 1rem;

    #{$ref}__sheet {
        padding: 16px 24px;

        #{$ref}__informacao {
            margin-top: 4px;

            &:first-child {
                margin-top: 8px;
            }
        }
    }
}
</style>
