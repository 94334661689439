<template>
    <v-app-bar
        color="white"
        elevation="0"
        class="app__header"
    >
        <v-app-bar-nav-icon @click="handleSidebarIsClose" />

        <v-col
            cols="1"
            xl="1"
        >
            <v-img
                rounded="0"
                contain
                :src="require('@assets/logo.svg')"
                height="30px"
            />
        </v-col>

        <v-col
            v-if="getSnModuloReservaEmergencia"
            cols="2"
            xl="2"
        >
            <BaseDatePickerYear
                v-model="year"
                label="Ano"
            />
        </v-col>

        <div class="ml-auto d-flex align-center">
            <BaseButtonEye dark />

            <v-menu
                bottom
                min-width="200px"
                rounded
                offset-y
            >
                <template #activator="{ on }">
                    <v-list-item
                        v-on="on"
                    >
                        <v-list-item-avatar class="mx-auto">
                            <v-img src="https://avatars.githubusercontent.com/u/53385727?v=4" />
                        </v-list-item-avatar>
                    </v-list-item>
                </template>
                <v-card>
                    <v-list>
                        <v-list-item
                            v-for="(item, index) in items"
                            :key="index"
                            link
                            @click="item.clickEvent"
                        >
                            <v-list-item-title>{{ item.title }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-card>
            </v-menu>
        </div>
    </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";

import { DateInstanceHelper } from "@helpers";

export default {
    data() {
        return {
            year: null,
            user: {
                fullName: "Vitor Duggen",
                email: "zduggem@gmail.com",
            },
            items: [
                {
                    title: "Configurações",
                    clickEvent: () => this.redirectConfiguracoes(),
                },
                {
                    title: "Sair",
                    clickEvent: () => this.signOut(),
                },
            ],
        };
    },
    computed: {
        ...mapGetters({
            getSidebarIsClose: "getSidebarIsClose",
            getListAllItems: "reservaEmergencia/getListAllItems",
            listIsEmpty: "reservaEmergencia/listIsEmpty",
        }),
        getTitle() {
            return this.$route.meta?.title;
        },
        getIcon() {
            return this.$route.meta?.icon;
        },
        getSnModuloReservaEmergencia() {
            return this.$route.matched[0].name === "emergency-reserve";
        },
    },
    watch: {
        year(newYear) {
            this.$store.dispatch("handleYearFilter", newYear);
            this.$store.dispatch("reservaEmergencia/filterByYear");
        },
    },
    beforeMount() {
        const dateInstanceHelper = new DateInstanceHelper();
        this.year = dateInstanceHelper.getYear();
    },
    methods: {
        handleSidebarIsClose() {
            this.$store.dispatch("handleSidebarIsClose", !this.getSidebarIsClose);
        },
        async signOut() {
            try {
                await this.$authHttpGateway.signOut();
                this.$store.dispatch("auth/clearUserInfos");
                this.$router.push({ name: "login" });
            } catch (e) {
                this.$toast.error(`${e.name}: ${e.message}`);
            }
        },
        redirectConfiguracoes() {
            this.$router.push({ name: "configuracoes" });
        },
    },
};
</script>
