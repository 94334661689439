<template>
    <v-form
        ref="form"
        v-model="valid"
        class="form"
        lazy-validation
    >
        <v-row>
            <v-col
                class="pb-0"
                cols="12"
            >
                <BaseTextField
                    v-model="vModel.ds_nome"
                    label="Descrição *"
                    validation-empty
                />
            </v-col>
        </v-row>
    </v-form>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            default: () => {},
        },
        isEdit: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            valid: true,
        };
    },

    computed: {
        vModel: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },

    methods: {
        validate() {
            return this.$refs.form.validate();
        },
        resetValidation() {
            return this.$refs.form.resetValidation();
        },
    },
};
</script>

<style lang="scss" scoped>
.form {
    padding: 1.5rem;
    margin: 0 auto;
}
</style>
