export default class CartaoCreditoTipoHttpGateway {
    httpClient;

    basePath = "gerenciamento-cartao-credito/cartao-credito-tipo";

    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    async getAll() {
        const { data } = await this.httpClient.get(this.basePath);
        return data;
    }
}
