<template>
    <BaseTable
        :headers="headers"
        :items="arrStatus"
        :items-per-page="-1"
        ds-mensagem-vazio="Sem status"
        :view-delete="false"
        @editAction="(item) => $emit('editAction', item)"
    />
</template>

<script>
import headers from "../../constants/status/headers.json";

export default {
    props: {
        arrStatus: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            headers,
        };
    },
};
</script>
