import { data } from 'cypress/types/jquery';
<template>
    <v-container class="cofrinhos">
        <header>
            <BaseHeaderPage>
                <template #right>
                    <div class="d-flex align-center justify-end h-100">
                        <CofrinhosHistoricoModalSalvar @atualizar-listagem="atualizarListagem" />
                    </div>
                </template>
            </BaseHeaderPage>
        </header>

        <div>
            <v-row class="mb-6 mt-3 ma-0">
                <v-col
                    v-for="(item, index) in items"
                    :key="`item-${index}`"
                    class="py-0"
                    :class="{ 'pl-0': index === 0, 'pr-0': index === items.length - 1 }"
                >
                    <DashboardHeaderItem
                        :icon="item.icon"
                        :color="item.color"
                        :title="item.title"
                        :value="item.value"
                        :is-currency="item.isCurrency"
                        :hidden-values="getIsHideValues"
                    />
                </v-col>
            </v-row>

            <CofrinhosHistoricoTable :data="data" />
        </div>
    </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { toCurrencyToLocaleString } from "@/modules/Main/helpers";

export default {
    data() {
        return {
            data: [],
            objDataDashboard: {
                vl_entradas: 0,
                vl_saidas: 0,
                vl_rendimentos: 0,
                vl_saldo: 0,
                nr_porcentagem_saidas: 0,
            },
        };
    },
    computed: {
        ...mapGetters({
            getIsHideValues: "getIsHideValues",
        }),
        items() {
            const {
                nr_porcentagem_saidas,
                vl_entradas,
                vl_rendimentos,
                vl_saidas,
                vl_saldo,
            } = this.objDataDashboard;
            return [
                {
                    icon: "mdi-cash-plus",
                    color: "green",
                    title: "Entradas",
                    value: vl_entradas,
                    isCurrency: true,
                },
                {
                    icon: "mdi-cash-minus",
                    color: "red",
                    title: "Saídas",
                    value: `${toCurrencyToLocaleString(vl_saidas)} / ${nr_porcentagem_saidas.toFixed(2)}%`,
                    isCurrency: false,
                },
                {
                    icon: "mdi-cash-multiple",
                    color: "blue",
                    title: "Saldo atual",
                    value: vl_saldo,
                    isCurrency: true,
                },
                {
                    icon: "mdi-finance",
                    color: "purple",
                    title: "Rendimentos",
                    value: vl_rendimentos,
                    isCurrency: true,
                },
            ];
        },
    },
    mounted() {
        this.getHistoricos();
        this.getDataDashboard();
    },
    methods: {
        async getDataDashboard() {
            const { cd_cofrinho } = this.$route.params;
            const objDataDashboard = await this.$cofrinhosHttpGateway.getDataDashboard(cd_cofrinho);
            const { vl_entradas } = objDataDashboard;
            const { vl_saidas } = objDataDashboard;
            const vl_saldo = vl_entradas - vl_saidas;
            let nr_porcentagem_saidas = (vl_saidas / vl_entradas) * 100;
            if (vl_saidas <= 0 && vl_entradas <= 0) {
                nr_porcentagem_saidas = 0;
            }
            this.objDataDashboard = {
                ...objDataDashboard,
                nr_porcentagem_saidas,
                vl_saldo,
            };
        },
        async getHistoricos() {
            const { cd_cofrinho } = this.$route.params;
            const arrCofrinhosHistoricos = await this.$cofrinhosHistoricosHttpGateway.getAll(
                cd_cofrinho,
            );
            this.data = arrCofrinhosHistoricos;
        },
        atualizarListagem() {
            this.getHistoricos();
            this.getDataDashboard();
        },
    },
};
</script>

<style lang="scss" scoped>
.cofrinhos {
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
}
</style>
