import { shortMonths } from "@constants";
import DateStringHelper from "./DateStringHelper";
import { cleanCurrency } from "./toCurrencyHelper";

function getPercentGoal(percentGoal, sum, goalValue) {
    const percent = Number(percentGoal) ? Number(percentGoal) : 0;
    return Math.abs(((sum / goalValue) * 100) + percent);
}

function factoryObjectMonths(year) {
    const object = {};
    shortMonths.forEach((shortMonth, index) => {
        const monthYear = `${shortMonth}/${year}`;
        object[index + 1] = {
            id: index + 1,
            monthYear,
            valueEntry: 0,
            currentBalance: 0,
            valueOutput: 0,
            percentGoal: 0,
            diff: 0,
        };
    });
    return object;
}

function bootstrap(selectedYear, array = [], goalValue = 0) {
    let currentBalance = 0;
    function getCurrentBalance(params) {
        const {
            actionName,
            value,
        } = params;
        if (actionName === "Adicionado") {
            currentBalance += value;
        }
        if (actionName === "Retirado") {
            currentBalance += value;
        }
    }

    // Para o gráfico de entradas x saídas
    const totalEntriesOutputs = {
        entries: 0,
        outputs: 0,
    };
    function getTotalEntriesOutputs(params) {
        const {
            actionName,
            value,
        } = params;
        if (actionName === "Adicionado") {
            totalEntriesOutputs.entries += value;
        }
        if (actionName === "Retirado") {
            totalEntriesOutputs.outputs += value;
        }
    }

    /**
     * {
     *  label: number,
     *  backgroundColor: string,
     *  data: {
     *      Jan: number
     *  }
     * }
     */
    const entriesByMonth = {
        label: "Entradas",
        backgroundColor: "#7f3ed2",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    };
    const outputsByMonth = {
        label: "Saídas",
        backgroundColor: "#c22345",
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
    };
    function getEntriesByMonth(params) {
        const {
            actionName,
            numberMonth,
            value,
        } = params;
        if (actionName === "Adicionado") {
            entriesByMonth.data[numberMonth - 1] += value;
        } else if (actionName === "Retirado") {
            outputsByMonth.data[numberMonth - 1] += value;
        }
    }

    const numberMonthsEntries = {};
    function getNumberMonthsEntries(params) {
        const {
            actionName,
            value,
            shortMonth,
        } = params;
        const isEntry = actionName === "Adicionado";
        const existEntryValue = value > 0;
        const monthNotExists = !numberMonthsEntries[shortMonth];
        if (isEntry && existEntryValue && monthNotExists) {
            numberMonthsEntries[shortMonth] = 1;
        }
    }

    const evolutionByMonthYear = factoryObjectMonths(selectedYear);
    function getEvolutionByMonth(params) {
        const {
            numberMonth,
            actionName,
            value,
        } = params;
        if (actionName === "Adicionado") {
            evolutionByMonthYear[numberMonth].valueEntry += value;
        }
        if (actionName === "Retirado") {
            evolutionByMonthYear[numberMonth].valueOutput += value;
        }
        if (evolutionByMonthYear[numberMonth]) {
            let beforeCurrentBalance = 0;
            let beforePercentGoal = 0;
            const beforeMonth = evolutionByMonthYear[numberMonth - 1];
            if (beforeMonth) {
                beforeCurrentBalance = beforeMonth.currentBalance;
                beforePercentGoal = beforeMonth.percentGoal;
            }
            const currentItem = evolutionByMonthYear[numberMonth];
            const diff = currentItem.valueEntry + currentItem.valueOutput;
            const isFirst = numberMonth === 1;
            currentItem.currentBalance = isFirst ? currentBalance : diff + beforeCurrentBalance;
            currentItem.diff = diff;
            currentItem.percentGoal = isFirst
                ? getPercentGoal(0, currentBalance, goalValue)
                : getPercentGoal(beforePercentGoal, diff, goalValue);
        }
    }

    if (array) {
        array.forEach((element) => {
            const value = Number(cleanCurrency(element.value));
            const dateHelper = new DateStringHelper(element.date);
            const year = dateHelper.getYear();
            const numberMonth = Number(dateHelper.getMonth(true));
            const shortMonth = dateHelper.getShortMonth();
            const params = {
                actionName: element.actionName,
                value,
                year,
                shortMonth,
                numberMonth,
            };
            getCurrentBalance(params);
            if (Number(selectedYear) === Number(year)) {
                getTotalEntriesOutputs(params);
                getEntriesByMonth(params);
                getNumberMonthsEntries(params);
                getEvolutionByMonth(params);
            }
        });
    }

    const sum = totalEntriesOutputs.entries + totalEntriesOutputs.outputs;
    const average = sum === 0 ? 0 : sum / 12;
    const missingValue = currentBalance === 0 ? 0 : goalValue - currentBalance;

    return {
        shortMonths,
        average,
        missingValue,
        currentBalance,
        listTotalEntriesOutputs: Object.values(totalEntriesOutputs),
        listEntriesByMonth: entriesByMonth,
        listOutputsByMonth: outputsByMonth,
        numberMonthsEntries: Object.values(numberMonthsEntries).length,
        evolutionByMonthYear: Object.values(evolutionByMonthYear),
    };
}

export default function getChartsData(selectedYear, array = [], goalValue = 0) {
    return bootstrap(selectedYear, array, goalValue);
}
