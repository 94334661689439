import * as views from "./views";
import TheDefaultVue from "../Main/layouts/TheDefault.vue";

export default [
    {
        path: "/cubo-magico",
        name: "cubo-magico",
        component: TheDefaultVue,
        meta: {
            icon: "mdi-cube",
            viewMenu: true,
            title: "Cubo Mágico",
        },
        redirect() {
            return { name: "cubo-magico-dashboard" };
        },
        children: [
            {
                path: "dashboard",
                name: "cubo-magico-dashboard",
                component: views.DashboardView,
                meta: {
                    icon: "mdi-view-dashboard",
                    viewMenu: true,
                    title: "Dashboard",
                },
            },
            {
                path: "solves",
                name: "cubo-magico-solves",
                component: views.SolvesView,
                meta: {
                    icon: "mdi-check",
                    viewMenu: true,
                    title: "Solves",
                },
            },
            {
                path: "importacao",
                name: "cubo-magico-importacao",
                component: views.ImportacaoView,
                meta: {
                    icon: "mdi-import",
                    viewMenu: true,
                    title: "Importação",
                },
            },
        ],
    },
];
