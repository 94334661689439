<template>
    <div
        v-if="chartsData"
        class="dashboard"
    >
        <header>
            <BaseHeaderPage />

            <BaseFilters
                :view-filters="viewFilters"
                view-year
            />
        </header>

        <div class="dashboard__body">
            <DashboardHeader
                :entries="getEntries"
                :current-balance="getCurrentBalance"
                :goal-value="getGoalValue"
                :hidden-values="getIsHideValues"
            />

            <v-row class="dashboard__row">
                <v-col cols="3">
                    <DoughnutChart
                        :styles="{ width: '100%', height: '90%' }"
                        :data="getListTotalEntriesOutputs"
                    />
                </v-col>

                <v-col cols="9">
                    <LineChart
                        :styles="{ width: '100%', height: '90%' }"
                        :data="getDataByMonth"
                        :labels="getLabels"
                    />
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    data() {
        return {
            viewFilters: false,
        };
    },

    computed: {
        ...mapGetters({
            getIsHideValues: "getIsHideValues",
            chartsData: "getChartsData",
            reserveEmergencyValue: "calculation/getReserveEmergencyValue",
        }),
        getEntries() {
            const { listTotalEntriesOutputs = [] } = this.chartsData;
            if (listTotalEntriesOutputs.length > 0) {
                return listTotalEntriesOutputs[0];
            }
            return null;
        },
        getCurrentBalance() {
            return this.chartsData?.currentBalance || 0;
        },
        getListTotalEntriesOutputs() {
            return this.chartsData?.listTotalEntriesOutputs || null;
        },
        getDataByMonth() {
            return [
                this.chartsData?.listEntriesByMonth || {},
                this.chartsData?.listOutputsByMonth || {},
            ];
        },
        getLabels() {
            return this.chartsData?.shortMonths || [];
        },
        getGoalValue() {
            return this.reserveEmergencyValue;
        },
    },

    async mounted() {
        this.$store.dispatch("handleLoading", true);
        await this.fetchReserveEmergencyValue();
        await this.fetchAllReservaEmergencia();
        this.$store.dispatch("handleLoading");
    },

    methods: {
        async fetchAllReservaEmergencia() {
            await this.$store.dispatch("reservaEmergencia/getAll");
        },
        async fetchReserveEmergencyValue() {
            await this.$store.dispatch("calculation/getAll");
        },
    },
};
</script>

<style lang="scss" scoped>
.dashboard {
    $ref: &;
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr;

    &__body {
        #{$ref}__row {
            margin: 0;

            &:nth-child(2) {
                height: 350px;

                .col {
                    height: 100%;
                }
            }

            .col {
                &:first-child {
                    padding-left: 0;
                }

                &:last-child {
                    padding-right: 0;
                }
            }
        }
    }
}
</style>
