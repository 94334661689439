<template>
    <BaseModalSave
        ref="rangeSemanasSaveModal"
        v-model="dialog"
        width="450px"
        :action-name="getActionName"
        name-view="Range Semana"
        :is-loading="loading"
        @save="saveAction"
    >
        <v-row>
            <v-col class="pb-0">
                <BaseDatePicker
                    v-model="payload.dt_inicio"
                    label="Data Inicio *"
                    validation-empty
                />
            </v-col>

            <v-col class="pb-0">
                <BaseDatePicker
                    v-model="payload.dt_final"
                    label="Data Final *"
                    validation-empty
                />
            </v-col>
        </v-row>

        <v-row>
            <v-col class="pb-0">
                <BaseAutoComplete
                    v-model="payload.nr_semana"
                    :items="[1, 2, 3, 4]"
                    validation-empty
                    label="Nº Semana *"
                />
            </v-col>
        </v-row>
    </BaseModalSave>
</template>

<script>
export default {
    props: {
        payloadToEdit: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            payload: {
                cd_range_semana: null,
                dt_inicio: null,
                dt_final: null,
                nr_semana: null,
            },
            dialog: false,
            loading: false,
        };
    },
    computed: {
        getActionName() {
            return this.payload.cd_range_semana ? "Editar" : "Novo";
        },
    },
    watch: {
        payloadToEdit(newValue) {
            this.dialog = true;
            this.payload = newValue;
        },
    },
    methods: {
        async saveAction() {
            const dayjsDtInicio = this.$dayjs(this.payload.dt_inicio);
            const nr_dia_inicio = dayjsDtInicio.date();
            const nr_mes_inicio = dayjsDtInicio.month() + 1;
            const nr_ano_inicio = dayjsDtInicio.year();
            const dayjsDtFinal = this.$dayjs(this.payload.dt_final);
            const nr_dia_final = dayjsDtFinal.date();
            const nr_mes_final = dayjsDtFinal.month() + 1;
            const nr_ano_final = dayjsDtFinal.year();
            const payload = {
                nr_dia_inicio,
                nr_mes_inicio,
                nr_ano_inicio,
                nr_dia_final,
                nr_mes_final,
                nr_ano_final,
                nr_semana: this.payload.nr_semana,
            };
            this.loading = true;
            if (this.payload.cd_range_semana) {
                const response = await this.$rangeSemanasHttpGateway.update(
                    this.payload.cd_range_semana,
                    payload,
                );
                this.$emit("updateItemList", response);
            } else {
                const response = await this.$rangeSemanasHttpGateway.create(payload);
                this.$emit("incrementList", response);
            }
            this.loading = false;
            this.dialog = false;
            this.$refs.rangeSemanasSaveModal.reset();
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
