/* eslint-disable class-methods-use-this */
import CalculationDTO from "./CalculationDTO";
import CalculationModel from "./CalculationModel";

export default class CalculationAdapter {
    dtoToModel(value = new CalculationDTO()) {
        const calculationModel = new CalculationModel();
        calculationModel.setId(value.id);
        calculationModel.setCategory(value.category);
        calculationModel.setValue(Number(value.value));
        calculationModel.setThreeMonths(value.value * 3);
        calculationModel.setSixMonths(value.value * 6);
        calculationModel.setTwelveMonths(value.value * 12);
        return calculationModel;
    }

    modelToDTO(value = new CalculationModel()) {
        const calculationDTO = new CalculationDTO();
        calculationDTO.setCategory(value.category);
        calculationDTO.setValue(value.value);
        if (value.id) {
            calculationDTO.setId(value.id);
        } else {
            delete calculationDTO.id;
        }
        return calculationDTO;
    }
}
