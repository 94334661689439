export default class MigracaoSheetsHttpGateway {
    httpClient;

    basePath = "migracao-sheets";

    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    async migrarDados(cd_controle_migracao_planilha_paginas) {
        const { data } = await this.httpClient.post(this.basePath, {
            cd_controle_migracao_planilha_paginas,
        });
        return data;
    }
}
