<template>
    <BaseSheet
        width="100%"
        height="100%"
        control-show-hidden
        class="d-flex align-center justify-space-between flex-column pa-4"
    >
        <h3>Entradas X Saídas</h3>
        <template v-if="isEmpty">
            <Doughnut
                :chart-options="chartOptions"
                :chart-data="chartData"
                :chart-id="chartId"
                :dataset-id-key="datasetIdKey"
                :plugins="plugins"
                :css-classes="cssClasses"
                :styles="styles"
                :width="width"
                :height="height"
            />
        </template>

        <template v-else>
            <div
                class="d-flex align-center"
                style="height: 100%;"
            >
                Sem dados para exibir
            </div>
        </template>
    </BaseSheet>
</template>

<script>
import { Doughnut } from "vue-chartjs/legacy";

import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    CategoryScale,
} from "chart.js";

import { defineComponent } from "vue";

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

export default defineComponent({
    components: {
        Doughnut,
    },
    props: {
        chartId: {
            type: String,
            default: "doughnut-chart",
        },
        datasetIdKey: {
            type: String,
            default: "label",
        },
        width: {
            type: Number,
            default: 400,
        },
        height: {
            type: Number,
            default: 400,
        },
        cssClasses: {
            default: "",
            type: String,
        },
        styles: {
            type: Object,
            default: () => ({}),
        },
        plugins: {
            type: Object,
            default: () => ({}),
        },
        data: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            chartOptions: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    tooltip: {
                        callbacks: {
                            label(t) {
                                return Number(t.raw).toLocaleString("pt-BR", {
                                    style: "currency",
                                    currency: "BRL",
                                });
                            },
                        },
                    },
                },
            },
        };
    },
    computed: {
        chartData() {
            return {
                labels: ["Entradas", "Saídas"],
                datasets: [
                    {
                        backgroundColor: ["#4CAF50", "#F44336"],
                        data: this.data,
                    },
                ],
            };
        },
        isEmpty() {
            return this.data && this.data.length > 0;
        },
    },
});
</script>
