import TheDefaultVue from "../Main/layouts/TheDefault.vue";
import { HomeView } from "./views";

export default [
    {
        path: "/configuracoes",
        name: "configuracoes",
        component: TheDefaultVue,
        meta: {
            icon: "mdi-cog",
            viewMenu: true,
            title: "Configurações",
        },
        redirect() {
            return { name: "configuracoes-home" };
        },
        children: [
            {
                path: "",
                name: "configuracoes-home",
                component: HomeView,
                meta: {
                    icon: "mdi-cog",
                    viewMenu: false,
                    title: "Configurações",
                },
            },
        ],
    },
];
