<template>
    <BaseSheet
        v-if="viewFilters"
        class="pa-3 pb-4 mb-4"
    >
        <v-row class="ma-0">
            <v-col
                cols="4"
                xl="3"
            >
                <BaseTextField
                    v-model="search"
                    label="Pesquisar"
                    append-icon="mdi-magnify"
                    :persistent-hint="true"
                    hint="Filtra apenas por Descrição/Observação"
                />
            </v-col>

            <v-col
                cols="2"
                xl="2"
            >
                <BaseDatePickerMonth
                    v-model="monthYear"
                    label="Mês/Ano"
                />
            </v-col>

            <v-col
                cols="3"
                xl="2"
            >
                <BaseAutoComplete
                    v-model="bankName"
                    :items="banks"
                    item-text="name"
                    item-value="name"
                    label="Nome do banco"
                />
            </v-col>

            <v-col
                cols="2"
                xl="2"
            >
                <BaseAutoComplete
                    v-model="actionName"
                    :items="actionsName"
                    label="Ação"
                />
            </v-col>
        </v-row>

        <footer class="w-100 d-flex justify-center">
            <BaseButton
                class="mr-4"
                color="error"
                @click="clearFilters()"
            >
                Limpar
            </BaseButton>

            <BaseButton
                color="blue"
                :dark="!filtersIsEmpty"
                :disabled="filtersIsEmpty"
                @click="searchFilters()"
            >
                Buscar
            </BaseButton>
        </footer>
    </BaseSheet>
</template>

<script>
import { defineComponent } from "vue";
import { mapGetters } from "vuex";

import { DateStringHelper } from "@helpers";

import { banks, actionNames } from "../../constants";

export default defineComponent({
    props: {
        viewFilters: {
            type: Boolean,
            default: false,
        },
        value: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            banks,
            actionsName: actionNames,
            dates: null,
            actionName: null,
            search: null,
            monthYear: null,
            bankName: null,
        };
    },

    computed: {
        ...mapGetters({
            getListAllItems: "reservaEmergencia/getListAllItems",
        }),
        filtersIsEmpty() {
            return (
                this.dates === null
                && this.actionName === null
                && this.search === null
                && this.monthYear === null
                && this.bankName === null
            );
        },
    },

    methods: {
        clearFilters() {
            this.dates = null;
            this.actionName = null;
            this.search = null;
            this.monthYear = null;
            this.bankName = null;
            this.$store.dispatch("reservaEmergencia/handleList", this.getListAllItems);
        },
        searchFilters() {
            const result1 = this.filterByMonthYear();
            const result2 = this.filterByBankName(result1);
            const result3 = this.filterByActionName(result2);
            const result4 = this.filterBySearch(result3);
            this.$store.dispatch("reservaEmergencia/handleList", result4);
        },
        filterByMonthYear() {
            if (this.monthYear) {
                const dateStringHelper2 = new DateStringHelper(this.monthYear, "MM/YYYY");
                const month = dateStringHelper2.getMonth();
                const year = dateStringHelper2.getYear();

                const result = this.getListAllItems.filter((item) => {
                    const dateStringHelper = new DateStringHelper(item.date);
                    const monthItem = String(Number(dateStringHelper.getMonth()));
                    const yearItem = dateStringHelper.getYear();
                    return (
                        monthItem.padStart(2, "0") === month.padStart(2, "0") && yearItem === year
                    );
                });
                return result;
            }
            return this.getListAllItems;
        },
        filterBySearch(array) {
            if (this.search) {
                const result = array.filter((item) => {
                    const string1 = item.description.toUpperCase();
                    const string2 = item.observation.toUpperCase();
                    const filterText = (this.search || "").toUpperCase();
                    return string1.includes(filterText) || string2.includes(filterText);
                });
                return result;
            }
            return array;
        },
        filterByBankName(array) {
            if (this.bankName) {
                const result = array.filter(({ bankName }) => bankName === this.bankName);
                return result;
            }
            return array;
        },
        filterByActionName(array) {
            if (this.actionName) {
                const result = array.filter(({ actionName }) => actionName === this.actionName);
                return result;
            }
            return array;
        },
    },
});
</script>
