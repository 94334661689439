<template>
    <BaseSheet
        width="100%"
        height="100%"
        control-show-hidden
        class="d-flex align-center justify-space-between flex-column pa-4"
    >
        <h3>Solves por dia</h3>

        <template v-if="isEmpty">
            <Bar
                ref="barChart"
                :chart-options="chartOptions"
                :chart-data="chartData"
                :chart-id="chartId"
                :dataset-id-key="datasetIdKey"
                :plugins="plugins"
                :css-classes="cssClasses"
                :styles="styles"
                :width="width"
                :height="height"
            />
        </template>

        <template v-else>
            <div
                class="d-flex align-center"
                style="height: 100%;"
            >
                Sem dados para exibir
            </div>
        </template>
    </BaseSheet>
</template>

<script>
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
} from "chart.js";
import { Bar } from "vue-chartjs";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default {
    components: {
        Bar,
    },
    props: {
        chartId: {
            type: String,
            default: "cubo-magico-solves-dia",
        },
        datasetIdKey: {
            type: String,
            default: "label",
        },
        width: {
            type: Number,
            default: 400,
        },
        height: {
            type: Number,
            default: 400,
        },
        cssClasses: {
            default: "",
            type: String,
        },
        styles: {
            type: Object,
            default: () => ({}),
        },
        plugins: {
            type: Object,
            default: () => ({}),
        },
        data: {
            type: Array,
            default: () => [],
        },
        labels: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            chartOptions: {
                interaction: {
                    intersect: false,
                    mode: "index",
                },
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    legend: {
                        display: false,
                    },
                },
            },
        };
    },
    computed: {
        chartData() {
            return {
                labels: this.labels,
                datasets: this.data,
            };
        },
        isEmpty() {
            return this.data && this.data.length > 0;
        },
    },
};
</script>
