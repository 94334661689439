<template>
    <ContaBancariaNovaTransacaoSecao
        ds-titulo="Resumo da transação"
        width="350px"
    >
        <div class="d-flex justify-start flex-column">
            <h4 class="text-left mb-1">
                Informações gerais
            </h4>
            <ContaBancariaFaturaInformacao
                ds-nome="Descrição"
                :ds-valor="objTransacao.ds_descricao || '-'"
            />
            <ContaBancariaFaturaInformacao
                ds-nome="Tipo"
                :ds-valor="getDsTipo"
            />
            <ContaBancariaFaturaInformacao
                :ds-nome="getDsValor"
                :ds-valor="objTransacao.vl_valor || '-'"
                :sn-valor-monetario="!!objTransacao.vl_valor"
            />
            <h4 class="text-left mt-4 mb-1">
                Datas
            </h4>
            <ContaBancariaFaturaInformacao
                ds-nome="Transação"
                :ds-valor="objTransacao.dt_data || '-'"
                :sn-data-hora="!!objTransacao.dt_data"
            />
            <ContaBancariaFaturaInformacao
                ds-nome="Processado"
                :ds-valor="objTransacao.dt_processado || '-'"
                :sn-data-hora="!!objTransacao.dt_processado"
            />
            <template v-if="getSnCartaoCredito">
                <h4 class="text-left mt-4 mb-1">
                    Cartão de crédito
                </h4>
                <ContaBancariaFaturaInformacao
                    ds-nome="Nome"
                    :ds-valor="objTransacao.objCartaoCredito.ds_nome"
                />
                <ContaBancariaFaturaInformacao
                    ds-nome="Nº finais"
                    :ds-valor="objTransacao.objCartaoCredito.nr_finais"
                />
                <ContaBancariaFaturaInformacao
                    ds-nome="Tipo"
                    :ds-valor="objTransacao.objCartaoCredito.CartaoCreditoTipo.ds_tipo"
                />
            </template>
            <template v-if="getSnParcelamento(objTransacao.cd_transacao_tipo) === true">
                <h4 class="text-left mt-4 mb-1">
                    Parcelamento
                </h4>
                <ContaBancariaFaturaInformacao
                    ds-nome="Nº parcelas"
                    :ds-valor="objTransacao.nr_parcelas || '-'"
                />
                <ContaBancariaFaturaInformacao
                    ds-nome="Valor parcela"
                    :ds-valor="getVlParcela"
                    sn-valor-monetario
                />
            </template>
        </div>

        <footer class="mt-4">
            <BaseButton
                class="text-none"
                color="vdPrimary"
                :loading="sn_carregando"
                block
                dark
                @click="salvarAcao"
            >
                {{ getDsTexto }}
            </BaseButton>
        </footer>
    </ContaBancariaNovaTransacaoSecao>
</template>

<script>
import { cleanCurrency } from "@/modules/Main/helpers";
import getSnEstorno from "../../mixins/getSnEstorno";
import getSnParcelamento from "../../mixins/getSnParcelamento";
import getSnAdiantamento from "../../mixins/getSnAdiantamento";
import getMesFatura from "../../mixins/getMesFatura";
import codigoTipoEnum from "../../constants/contaBancariaNovaTransacao/codigoTipoEnum";

export default {
    mixins: [
        getMesFatura,
        getSnEstorno,
        getSnParcelamento,
        getSnAdiantamento,
    ],
    props: {
        objTransacao: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            sn_carregando: false,
        };
    },
    computed: {
        getDsValor() {
            return this.getSnParcelamento(this.objTransacao.cd_transacao_tipo) === true ? "Valor total" : "Valor";
        },
        getVlParcela() {
            const ds_valor = String(this.objTransacao.vl_valor);
            const ds_valor_formatado = cleanCurrency(ds_valor);
            const nr_valor = Number(ds_valor_formatado);
            const { nr_parcelas } = this.objTransacao;
            if (!nr_parcelas) return nr_valor;
            return nr_valor / nr_parcelas;
        },
        getDsTexto() {
            return this.getSnEditar === true ? "Editar" : "Criar";
        },
        getSnCartaoCredito() {
            const sn_estorno = this.getSnEstorno(this.objTransacao.cd_transacao_tipo);
            const sn_adiantamento = this.getSnAdiantamento(this.objTransacao.cd_transacao_tipo);
            return sn_estorno === false && sn_adiantamento === false
            && this.objTransacao.objCartaoCredito;
        },
        getSnEditar() {
            return !!this.objTransacao?.cd_transacao;
        },
        getDsTipo() {
            return codigoTipoEnum[this.objTransacao?.cd_transacao_tipo] || "";
        },
    },
    methods: {
        transacaoHttpGateway() {
            return this.$cartaoCredito.transacaoHttpGateway;
        },
        async criarTransacao() {
            const payload = { ...this.objTransacao };
            await this.transacaoHttpGateway().create(payload);
        },
        async editarTransacao() {
            const payload = { ...this.objTransacao };
            await this.transacaoHttpGateway().update(
                this.objTransacao.cd_transacao,
                payload,
            );
        },
        async formValido() {
            this.sn_carregando = true;
            const ds_nome_acao = "criada";
            try {
                if (this.getSnEditar === true) {
                    await this.editarTransacao();
                } else {
                    await this.criarTransacao();
                }
                this.$toast.success(`Transação ${ds_nome_acao} com sucesso!`);
                this.$router.push({
                    name: "conta-bancaria-faturas",
                    params: {
                        cd_conta_bancaria: this.$route.params.cd_conta_bancaria,
                    },
                });
                this.sn_carregando = false;
            } catch (e) {
                console.error(e);
                this.sn_carregando = false;
            }
        },
        salvarAcao() {
            this.$emit("validate-form", this.formValido);
        },
    },
};
</script>

<style lang="scss">
</style>
