<template>
    <BaseModal
        v-model="sn_modal_aberto"
        max-width="550px"
        persistent
    >
        <template #activator="{ on, attrs }">
            <slot
                name="activator"
                :attrs="attrs"
                :on="on"
            />
        </template>

        <v-card>
            <BaseFormHeader
                ds-nome-tela="Conta Bancária"
                :ds-nome-acao="ds_nome_acao"
            />
            <v-form
                ref="form"
                v-model="valid"
                class="conta-bancaria__form"
                lazy-validation
            >
                <v-row>
                    <v-col
                        class="pb-0"
                        cols="6"
                    >
                        <BaseTextField
                            v-model="payload.ds_nome"
                            label="Nome *"
                            validation-empty
                        />
                    </v-col>
                    <v-col
                        v-if="sn_mostrar_input === true"
                        class="pb-0"
                        cols="6"
                    >
                        <BaseTextFieldMoney
                            v-model="payload.vl_limite"
                            label="Valor limite"
                            :rules="[validacaoAlteracao]"
                        />
                    </v-col>
                </v-row>
            </v-form>
            <BaseFormActions
                :sn-loading="sn_loading"
                @save="salvarAcao"
                @close="fecharModal"
            />
        </v-card>
    </BaseModal>
</template>

<script>
import { defineComponent } from "vue";
import { cleanCurrency, toCurrencyToLocaleString } from "@/modules/Main/helpers";

export default defineComponent({
    data() {
        return {
            payload: {
                ds_nome: "",
                vl_limite: null,
                cd_conta_bancaria: null,
            },
            sn_mostrar_input: false,
            valid: true,
            sn_loading: false,
            sn_modal_aberto: false,
            ds_nome_acao: "Criar",
        };
    },

    computed: {
        getSnEditar() {
            return this.ds_nome_acao === "Editar";
        },
        getSnCriar() {
            return this.ds_nome_acao === "Criar";
        },
    },

    watch: {
        sn_modal_aberto(sn_aberto) {
            this.sn_mostrar_input = sn_aberto;
        },
    },

    methods: {
        abrirModal(objPayload = null) {
            if (objPayload) {
                this.resetValidation();
                this.payload = {
                    ...objPayload,
                    vl_limite_atual: +objPayload.vl_limite,
                    vl_limite: toCurrencyToLocaleString(objPayload.vl_limite),
                };
                this.ds_nome_acao = "Editar";
            }
            this.sn_modal_aberto = true;
        },
        fecharModal() {
            this.sn_modal_aberto = false;
            this.ds_nome_acao = "Criar";
            this.resetData();
            this.resetValidation();
        },

        getFormIsValid() {
            return this.$refs.form.validate();
        },

        resetValidation() {
            if (this.$refs.form) {
                this.$refs.form.resetValidation();
            }
        },
        contaBancariaHttpGateway() {
            return this.$cartaoCredito.contaBancariaHttpGateway;
        },
        async salvarAcao() {
            const formIsValid = this.getFormIsValid();
            if (formIsValid) {
                this.sn_loading = true;
                let ds_nome_acao = "criado";
                const payload = {
                    ...this.payload,
                    vl_limite: +cleanCurrency(this.payload.vl_limite),
                };
                if (payload.cd_conta_bancaria) {
                    delete payload.vl_utilizado;
                    await this.contaBancariaHttpGateway().update(
                        payload.cd_conta_bancaria,
                        payload,
                    );
                    ds_nome_acao = "editado";
                } else {
                    await this.contaBancariaHttpGateway().create(payload);
                }
                this.$emit("atualizar-listagem");
                this.$toast.success(`Conta bancária ${ds_nome_acao} com sucesso!`);
                this.sn_loading = false;
                this.fecharModal();
            }
        },

        resetData() {
            this.payload = {
                ds_nome: "",
                vl_limite: null,
                cd_conta_bancaria: null,
            };
            this.setValueInputCurrency();
        },

        setValueInputCurrency(nr_valor = 0) {
            if (this.$refs.inputCurrency) {
                const input = this.$refs.inputCurrency.$el.querySelector("input");
                const nr_valor_formatado = toCurrencyToLocaleString(nr_valor);
                input.value = nr_valor_formatado;
            }
        },

        validacaoAlteracao(ds_valor) {
            if (this.getSnCriar === true) return true;
            const vl_limite_atual_formatado = toCurrencyToLocaleString(
                this.payload.vl_limite_atual,
            );
            return (
                +cleanCurrency(ds_valor) >= this.payload.vl_limite_atual
                || `O novo limite não pode ser menor do que o atual (atual: ${vl_limite_atual_formatado})`
            );
        },
    },
});
</script>

<style lang="scss" scoped>
.conta-bancaria__form {
    padding: 1.5rem;
    margin: 0 auto;
}
</style>
