<!-- eslint-disable vue/no-v-html -->
<template>
    <BaseModal
        v-model="sn_modal_aberto"
        max-width="1200px"
    >
        <template #activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                color="vdPrimary"
                dark
                class="text-none"
                v-on="on"
                @click="$emit('ver-aulas')"
            >
                <v-icon left>
                    mdi-plus
                </v-icon>

                Adicionar
            </v-btn>
        </template>

        <v-card>
            <BaseFormHeader
                ds-nome-tela="Registro"
                :ds-nome-acao="ds_nome_acao"
            />
            <v-form
                ref="estudosForm"
                v-model="valid"
                style="padding: 1.5rem"
                lazy-validation
            >
                <v-row>
                    <v-col
                        v-if="snAulas"
                        cols="5"
                        class="pb-0"
                    >
                        <BaseAutoComplete
                            v-model="payload.cd_aula"
                            label="Aula *"
                            validation-empty
                            item-value="cd_estudo_aula"
                            item-text="ds_nome_formatado"
                            :items="arrAulas"
                        />
                    </v-col>
                    <v-col class="pb-0">
                        <HistoricoModalSalvarTimePicker
                            v-model="payload.ds_tempo_assistido"
                            label="Tempo assistido"
                            :disabled="!payload.cd_aula"
                            :tempo-diario-formatado="getTempoDiarioFormatado"
                            :tempo-diario-segundos="getTempoDiario"
                            :nr-tempo-assistido="getTempoAssistido"
                            :nr-tempo-total="getTempoTotal"
                        />
                        <div class="d-flex">
                            <BaseTimePickerDifference
                                v-model="payload.ds_tempo_assistido"
                                :time1="getUltimoTempo"
                                :disabled="!payload.cd_aula"
                            />
                        </div>
                    </v-col>
                    <v-col
                        cols="2"
                        class="pb-0"
                    >
                        <BaseDatePicker
                            v-model="payload.dt_data"
                            label="Data *"
                            validation-empty
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="pb-0">
                        <v-row>
                            <v-col>
                                <EditorVuetify v-model="payload.ds_anotacao" />
                            </v-col>

                            <v-col>
                                <h2 class="vdPrimary--text">
                                    Resultado
                                </h2>
                                <v-divider class="mb-4 mt-1" />
                                <div v-html="payload.ds_anotacao" />
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-form>
            <BaseFormActions
                :sn-loading="sn_loading"
                @save="salvarAcao"
                @close="fecharModal"
            />
        </v-card>
    </BaseModal>
</template>

<script>
import dayjs from "dayjs";
import { defineComponent } from "vue";
import { NUMERO_DIAS_SEMANA } from "../views/AnotacoesView.vue";

const defaultPayload = {
    cd_aula: null,
    ds_anotacao: "",
    ds_tempo_assistido: "00:00:00",
    nr_tempo_assistido: 0,
    dt_data: dayjs().format("YYYY-MM-DD"),
};

export default defineComponent({
    props: {
        arrAulas: {
            type: Array,
            default: () => null,
        },
        cdAula: {
            type: Number,
            default: () => null,
        },
        snAulas: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            payload: { ...defaultPayload },
            valid: true,
            sn_loading: false,
            sn_modal_aberto: false,
            ds_nome_acao: "Criar",
            nr_finais_anterior: null,
        };
    },

    computed: {
        getUltimoTempo() {
            let nr_tempo_assistido = 0;
            if (this.payload.cd_aula) {
                this.arrAulas.forEach((aula) => {
                    // eslint-disable-next-line eqeqeq
                    if (aula.cd_estudo_aula == this.payload.cd_aula) {
                        nr_tempo_assistido = aula.nr_tempo_assistido;
                    }
                });
            }
            return nr_tempo_assistido;
        },
        getSnEditar() {
            return this.ds_nome_acao === "Editar";
        },
        getTempoTotal() {
            let nr_tempo = 0;
            if (this.payload.cd_aula) {
                this.arrAulas.forEach((aula) => {
                    // eslint-disable-next-line eqeqeq
                    if (aula.cd_estudo_aula == this.payload.cd_aula) {
                        nr_tempo = aula.nr_tempo;
                    }
                });
            }
            return nr_tempo;
        },
        getTempoDiario() {
            let nr_tempo = 0;
            if (this.payload.cd_aula) {
                this.arrAulas.forEach((aula) => {
                    // eslint-disable-next-line eqeqeq
                    if (aula.cd_estudo_aula == this.payload.cd_aula) {
                        nr_tempo = Math.round(aula.nr_tempo / NUMERO_DIAS_SEMANA);
                    }
                });
            }
            return nr_tempo;
        },
        getTempoDiarioFormatado() {
            return this.formatTime(this.getTempoDiario);
        },
        getTempoAssistido() {
            let nr_tempo = 0;
            if (this.payload.cd_aula) {
                this.arrAulas.forEach((aula) => {
                    // eslint-disable-next-line eqeqeq
                    if (aula.cd_estudo_aula == this.payload.cd_aula) {
                        nr_tempo = aula.nr_tempo_assistido;
                    }
                });
            }
            return nr_tempo;
        },
    },

    watch: {
        cdAula(cd_aula) {
            if (cd_aula) {
                this.payload.cd_aula = cd_aula;
            } else {
                this.payload.cd_aula = null;
            }
        },
    },

    methods: {
        formatTime(secondsInput, withDots = false) {
            const durationDayjs = dayjs.duration(secondsInput, "seconds");
            const hours = String(Math.floor(durationDayjs.asHours())).padStart(2, "0");
            const minutes = String(durationDayjs.minutes()).padStart(2, "0");
            const seconds = String(durationDayjs.seconds()).padStart(2, "0");
            let result = `${hours}h ${minutes}m ${seconds}s`;
            if (withDots) {
                result = `${hours}:${minutes}:${seconds}`;
            }
            return result;
        },

        // utilizado via ref
        abrirModal(objPayload = null) {
            if (objPayload) {
                this.ds_nome_acao = "Editar";
                this.payload = {
                    ...objPayload,
                };
            }
            this.sn_modal_aberto = true;
        },
        fecharModal() {
            this.sn_modal_aberto = false;
            this.ds_nome_acao = "Criar";
            this.resetData();
            this.resetValidation();
        },

        getFormIsValid() {
            return this.$refs.estudosForm.validate();
        },

        resetValidation() {
            if (this.$refs.estudosForm) {
                this.$refs.estudosForm.resetValidation();
            }
        },

        timeStringToSeconds(timeString) {
            // Verifica se o formato da string é HH:mm:ss
            const regex = /^(\d{2}):(\d{2}):(\d{2})$/;
            const match = timeString.match(regex);

            if (!match) {
                throw new Error("Formato de string inválido. Utilize HH:mm:ss");
            }

            // Extrai horas, minutos e segundos da string
            const [, hours, minutes, seconds] = match;

            // Converte horas, minutos e segundos para segundos
            // eslint-disable-next-line max-len
            const totalSeconds = parseInt(hours, 10) * 3600 + parseInt(minutes, 10) * 60 + parseInt(seconds, 10);

            return totalSeconds;
        },

        async salvarAcao() {
            const formIsValid = this.getFormIsValid();
            if (formIsValid) {
                try {
                    this.sn_loading = true;
                    const ds_nome_acao = "criado";
                    this.payload.nr_tempo_assistido = this.timeStringToSeconds(
                        this.payload.ds_tempo_assistido,
                    );
                    await this.$estudosAnotacoesHttpGateway.create(this.payload);
                    this.$emit("atualizar-listagem");
                    this.$toast.success(`Registro ${ds_nome_acao} com sucesso!`);
                    this.sn_loading = false;
                    this.fecharModal();
                } catch (e) {
                    this.sn_loading = false;
                }
            }
        },

        resetData() {
            this.payload = { ...defaultPayload };
        },
    },
});
</script>
