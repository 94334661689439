<template>
    <header class="px-6 pt-6">
        <v-card-title class="pa-0">
            <span class="text-h5">{{ dsNomeAcao }} {{ dsNomeTela }}</span>
        </v-card-title>
        <v-card-text class="pa-0 text--secondary">
            <small>* indica campo obrigatório</small>
        </v-card-text>
    </header>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        dsNomeAcao: {
            type: String,
            default: "Novo",
        },
        dsNomeTela: {
            type: String,
            default: "",
        },
    },
});
</script>
