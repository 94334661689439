<template>
    <div class="lancamentos">
        <BaseHeaderPage class="lancamentos__header">
            <template #right>
                <div
                    class="d-flex justify-end align-center h-100"
                    style="column-gap: 16px"
                >
                    <BaseButtonFilter
                        v-model="viewFilters"
                        color="vdPrimary"
                    />

                    <RangeSemanasSaveModal
                        :payload-to-edit="payloadToEdit"
                        @incrementList="incrementList"
                        @updateItemList="updateItemList"
                    />
                </div>
            </template>
        </BaseHeaderPage>

        <BaseFilters
            v-model="filters"
            :view-filters="viewFilters"
            class="mb-4"
            view-month-year
            @clearAction="clearAction"
            @searchAction="searchAction"
        />

        <BaseSheet>
            <BaseTable
                :headers="headers"
                :items="rangeSemanaItems"
                :items-per-page="-1"
                :view-actions="true"
                :view-delete="false"
                @editAction="editAction"
            >
                <template #dt_inicio="{ item }">
                    {{ getData(item.nr_dia_inicio, item.nr_mes_inicio, item.nr_ano_inicio) }}
                </template>

                <template #dt_final="{ item }">
                    {{ getData(item.nr_dia_final, item.nr_mes_final, item.nr_ano_final) }}
                </template>
            </BaseTable>
        </BaseSheet>
    </div>
</template>

<script>
import { addTwoZerosStart } from "@helpers";
import { mapGetters } from "vuex";
import headers from "../constants/RangeSemanas/headers.json";

export default {
    data() {
        return {
            headers,
            payloadToEdit: {},
            viewFilters: false,
            filters: {
                monthYear: "",
            },
        };
    },

    computed: {
        ...mapGetters({
            rangeSemanaItems: "rangeSemanas/getItems",
        }),
    },

    mounted() {
        this.clearAction();
    },

    methods: {
        incrementList(objRangeSemana) {
            const tmp = this.rangeSemanaItems;
            tmp.push(objRangeSemana);
            this.$store.dispatch("rangeSemanas/updateItems", tmp);
        },
        getData(nr_dia, nr_mes, nr_ano) {
            return `${addTwoZerosStart(nr_dia)}/${addTwoZerosStart(nr_mes)}/${addTwoZerosStart(nr_ano)}`;
        },
        editAction(item) {
            this.payloadToEdit = {
                ...item,
                dt_inicio: `${item.nr_ano_inicio}-${addTwoZerosStart(item.nr_mes_inicio)}-${addTwoZerosStart(item.nr_dia_inicio)}`,
                dt_final: `${item.nr_ano_final}-${addTwoZerosStart(item.nr_mes_final)}-${addTwoZerosStart(item.nr_dia_final)}`,
            };
        },
        updateItemList(objRangeSemana) {
            const result = this.data.map((currentObjRangeSemana) => {
                if (currentObjRangeSemana.cd_range_semana === objRangeSemana.cd_range_semana) {
                    return objRangeSemana;
                }
                return currentObjRangeSemana;
            });
            this.$store.dispatch("rangeSemanas/updateItems", result);
        },
        clearAction() {
            const monthYear = this.$dayjs().format("MM/YYYY");
            this.filters.monthYear = monthYear;
            this.searchAction({ monthYear });
        },
        async searchAction({ monthYear }) {
            const [nr_mes, nr_ano] = monthYear.split("/");
            const dt_mes_ano = this.$dayjs(`${nr_ano}-${nr_mes}-01`).format("YYYY-MM");
            const dt_mes_ano_anterior = this.$dayjs(`${nr_ano}-${nr_mes}-01`).subtract(1, "month").format("YYYY-MM");
            this.$store.dispatch("rangeSemanas/fetchAll", {
                force: true,
                dt_mes_ano,
                dt_mes_ano_anterior,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.lancamentos {
    $ref: &;

    &__header {
        margin-bottom: 0.75rem;
    }

    &__list {
        display: grid;
        gap: 1rem;
        justify-content: center;
        grid-template-columns: repeat(auto-fill, 250px);
    }
}
</style>
