<template>
    <BaseTable
        :headers="headers"
        :items="arrPlanilhas"
        :items-per-page="-1"
        ds-mensagem-vazio="Sem planilhas"
        :view-delete="false"
        @editAction="(item) => $emit('editAction', item)"
    />
</template>

<script>
import { mapGetters } from "vuex";
import headers from "../../constants/planilha/headers.json";

export default {
    data() {
        return {
            headers,
        };
    },
    computed: {
        ...mapGetters({
            arrPlanilhas: "planilhas/getArrPlanilhas",
        }),
    },
};
</script>
