function getURL(spreadsheetID, sheetName = null) {
    const base = `https://docs.google.com/spreadsheets/d/${spreadsheetID}/gviz/tq?`;
    const sheetNameParam = sheetName ? `&sheet=${sheetName}` : "";
    return `${base}${sheetNameParam}`;
}

async function fetchDataGoogleSheets(spreadsheetID, sheetName = null) {
    const url = getURL(spreadsheetID, sheetName);
    return fetch(url)
        .then((res) => res.text())
        .then((text) => JSON.parse(text.substr(47).slice(0, -2)));
}

function transformData(row) {
    const historyModel = {};
    historyModel.description = row.c[1]?.v;
    historyModel.value = row.c[2].v;
    historyModel.date = row.c[3].f || "";
    historyModel.observation = row.c[4]?.v || "";
    historyModel.actionName = row.c[5].v;
    historyModel.bankName = row.c[7].v;
    historyModel.id = row.c[8].v;
    return historyModel;
}

function transformDataCalculation(row) {
    const calculationModel = {};
    calculationModel.category = row.c[0].v;
    calculationModel.value = row.c[1].v;
    calculationModel.id = row.c[5].v;
    return calculationModel;
}

export function syncDataCalculation(data, params) {
    const { currentList } = params;
    const newData = [];
    for (const row of data.table.rows) {
        const id = row.c[5].v;
        if (id) {
            const result = currentList.filter((item) => item.id === id);
            const itemAlreadyExists = result.length > 0;
            if (!itemAlreadyExists) {
                const convertedData = transformDataCalculation(row);
                newData.push(convertedData);
            }
        }
    }
    return newData;
}

export function migrateDataHistory(data, params) {
    const { currentList } = params;
    const newData = [];
    for (const row of data.table.rows) {
        const id = row.c[8].v;
        if (id) {
            const result = currentList.filter((item) => item.id === id);
            const itemAlreadyExists = result.length > 0;
            if (!itemAlreadyExists) {
                const convertedData = transformData(row);
                newData.push(convertedData);
            }
        }
    }
    return newData;
}

export function migrateControleDiario(data) {
    const newData = [];
    for (const row of data.table.rows) {
        const [day, month, year] = row.c[4].f ? row.c[4].f.split("/") : [];
        const date = `${year}-${month}-${day}`;
        const payload = {
            ds_descricao: row.c[0]?.v,
            nr_valor: row.c[1]?.v,
            ds_osbservacao: row.c[2]?.v || "",
            cd_categoria: row.c[3]?.v,
            dt_data: date,
            cd_status: row.c[5].v === "Recebido" ? 2 : 1,
        };
        newData.push(payload);
    }
    return newData;
}

export function converterDadosRangeSemanas(data) {
    const newData = [];
    for (const row of data.table.rows) {
        console.log(row);
        const [nr_ano_inicio, nr_mes_inicio, nr_dia_inicio] = row.c[0].v.split("-");
        const [nr_ano_final, nr_mes_final, nr_dia_final] = row.c[1].v.split("-");
        const payload = {
            nr_ano_inicio: Number(nr_ano_inicio),
            nr_mes_inicio: Number(nr_mes_inicio),
            nr_dia_inicio: Number(nr_dia_inicio),
            nr_ano_final: Number(nr_ano_final),
            nr_mes_final: Number(nr_mes_final),
            nr_dia_final: Number(nr_dia_final),
            nr_semana: Number(row.c[2]?.v),
        };
        newData.push(payload);
    }
    return newData;
}

export async function fetchData(spreadsheetID, callback, params) {
    const data = await fetchDataGoogleSheets(spreadsheetID, params?.sheetName);
    return callback(data, params);
}
