<template>
    <div>
        <h2 class="migracao__titulo">
            {{ dsTitulo }}
        </h2>

        <v-row>
            <v-col
                v-for="objPagina in getArrPaginasOrdenado"
                :key="objPagina.cd_controle_migracao_planilha_paginas"
            >
                <ConfiguracoesMigracaoGoogleSheetSecaoButton
                    :cd-controle-migracao-planilha-paginas="
                        objPagina.cd_controle_migracao_planilha_paginas
                    "
                    :ds-nome="objPagina.ds_nome"
                    :sn-migrado="getSnMigrado(objPagina.cd_controle_migracao_planilha_paginas)"
                    @confirmar="confirmar"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    props: {
        arrPlanilhasMigradas: {
            type: Array,
            default: () => [],
        },
        cdPlanilha: {
            type: [String, Number],
            default: "",
        },
        dsTitulo: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            sn_carregando: false,
        };
    },
    computed: {
        ...mapGetters({
            getArrPaginasByPlanilha: "paginas/getArrPaginasByPlanilha",
        }),
        getArrPaginasOrdenado() {
            const copiaArrPlanilhas = this.getArrPaginasByPlanilha(this.cdPlanilha) || [];
            return copiaArrPlanilhas.sort((a, b) => {
                const nr_mes_a = +a.ds_nome.split("- ")[1];
                const nr_mes_b = +b.ds_nome.split("- ")[1];
                return nr_mes_a - nr_mes_b;
            });
        },
    },
    methods: {
        confirmar(ds_nome, callbackConfirmar) {
            this.$emit(
                "confirmar",
                ds_nome,
                callbackConfirmar,
            );
        },
        getSnMigrado(cd_controle_migracao_planilha_paginas) {
            const objPaginaResultado = this.arrPlanilhasMigradas.find(
                (objPagina) => objPagina.cd_controle_migracao_planilha_paginas
                    === cd_controle_migracao_planilha_paginas,
            );
            return !!objPaginaResultado;
        },
    },
};
</script>

<style lang="scss">
.migracao__titulo {
    border-bottom: 1px solid rgba(128, 128, 128, 0.216);
    margin-bottom: 1rem;
    padding-bottom: .75rem;

    &:last-child {
        border-bottom: none;
    }
}
</style>
