<template>
    <div
        v-if="getList"
        class="calculation"
    >
        <header>
            <BaseHeaderPage>
                <template #right>
                    <div class="d-flex align-center justify-end flex-wrap h-100">
                        <BaseButton
                            color="orange"
                            dark
                            class="mr-4"
                            @click="syncDataGoogleSheet"
                        >
                            <v-icon left>
                                mdi-sync
                            </v-icon>

                            Sincronizar dados planilha
                        </BaseButton>

                        <CalculationSave />
                    </div>
                </template>
            </BaseHeaderPage>

            <BaseFilters
                :view-filters="viewFilters"
                view-year
            />
        </header>

        <div>
            <v-row class="mb-6 mt-3 ma-0">
                <v-col
                    v-for="(item, index) in items"
                    :key="`item-${index}`"
                    class="py-0"
                    :class="{ 'pl-0': index === 0, 'pr-0': index === items.length - 1 }"
                >
                    <DashboardHeaderItem
                        :icon="item.icon"
                        :color="item.color"
                        :title="item.title"
                        :value="item.value"
                        :is-currency="item.isCurrency"
                        :hidden-values="getIsHideValues"
                    />
                </v-col>
            </v-row>

            <CalculationTable :data="getList" />
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import { dataGoogleSheet } from "@helpers";

export default {
    data() {
        return {
            viewFilters: false,
            missingValue: 0,
        };
    },
    computed: {
        ...mapGetters({
            getList: "calculation/getList",
            total: "calculation/getTotal",
            getIsHideValues: "getIsHideValues",
        }),
        items() {
            const {
                all,
                three,
                six,
                twelve,
            } = this.total;
            return [
                {
                    icon: "mdi-numeric-1",
                    color: "cyan",
                    title: "Total Unitário",
                    value: all,
                    isCurrency: true,
                },
                {
                    icon: "mdi-numeric-3",
                    color: "green",
                    title: "Total 3 Meses",
                    value: three,
                    isCurrency: true,
                },
                {
                    icon: "mdi-numeric-6",
                    color: "orange",
                    title: "Total 6 Meses",
                    value: six,
                    isCurrency: true,
                },
                {
                    icon: ["mdi-numeric-1", "mdi-numeric-2"],
                    color: "purple",
                    title: "Total 12 Meses",
                    value: twelve,
                    isCurrency: true,
                },
            ];
        },
    },
    async mounted() {
        this.$store.dispatch("handleLoading", true);
        await this.fetchAll();
        this.$store.dispatch("handleLoading");
    },
    methods: {
        async fetchAll() {
            await this.$store.dispatch("calculation/getAll");
        },
        async syncDataGoogleSheet() {
            try {
                const spreadsheetID = "1BoQ2-Kyclejcn0fVJN5w6rpQuepzsBTlYbYXvdordrM";
                const { fetchData, syncDataCalculation } = dataGoogleSheet;
                const response = await fetchData(
                    spreadsheetID,
                    syncDataCalculation,
                    {
                        currentList: this.getList,
                    },
                );
                const existData = response.length > 0;
                if (existData) {
                    for await (const item of response) {
                        await this.$store.dispatch("calculation/create", item);
                    }
                    this.$toast.success("Dados sincronizados com sucesso!");
                } else {
                    this.$toast.info("Todos os dados já foram sincronizados!");
                }
            } catch (e) {
                console.error(e);
                this.$toast.error("Ocorreu algum erro e não foi possível sincronizar os dados!");
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.calculation {
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
}
</style>
