<template>
    <BaseFormHeader
        :ds-nome-acao="dsNomeAcao"
        ds-nome-tela="Histórico"
    />
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        actionName: {
            type: String,
            default: "Novo",
        },
    },
});
</script>
