<template>
    <v-menu
        ref="menu"
        v-model="menu2"
        :close-on-content-click="false"
        :nudge-right="40"
        transition="scale-transition"
        offset-y
        min-width="580px"
        max-width="580px"
    >
        <template #activator="{ on, attrs }">
            <v-chip
                v-bind="attrs"
                :disabled="disabled"
                v-on="on"
            >
                Diferença entre tempos
            </v-chip>
        </template>
        <div class="d-flex">
            <div
                class="d-flex flex-column"
                style="width: 290px;"
            >
                <h3 style="background-color: white; text-align: center;">
                    Ultimo tempo assistido
                </h3>
                <v-time-picker
                    v-if="menu2"
                    v-model="time3"
                    full-width
                    format="24hr"
                    use-seconds
                />
            </div>
            <div
                class="d-flex flex-column"
                style="width: 290px;"
            >
                <h3 style="background-color: white; text-align: center;">
                    Tempo assistido
                </h3>
                <v-time-picker
                    v-if="menu2"
                    v-model="time2"
                    full-width
                    format="24hr"
                    use-seconds
                />
            </div>
        </div>
        <div
            class="d-flex justify-center py-4"
            style="background-color: white"
        >
            <v-btn
                text
                color="primary"
                @click="clickSave"
            >
                Salvar
            </v-btn>
        </div>
    </v-menu>
</template>

<script>
import dayjs from "dayjs";

export default {
    props: {
        value: {
            type: String,
            default: "",
        },
        time1: {
            type: Number,
            default: 0,
        },
        disabled: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            time3: null,
            time2: null,
            menu2: false,
        };
    },
    computed: {
        vModel: {
            get() {
                return this.value;
            },
            set(ds_valor) {
                this.$emit("input", ds_valor);
            },
        },
    },
    watch: {
        time1(newValue) {
            if (newValue) {
                this.time3 = this.formatTime(newValue);
                this.time2 = this.formatTime(newValue);
            }
        },
    },
    methods: {
        timeToSeconds(time) {
            const [hours, minutes, seconds] = time.split(":").map(Number);
            return hours * 3600 + minutes * 60 + seconds;
        },

        timeDifferenceInSeconds(time1, time2) {
            const seconds1 = this.timeToSeconds(time1);
            const seconds2 = this.timeToSeconds(time2);
            return Math.abs(seconds1 - seconds2);
        },

        formatTime(secondsInput) {
            const durationDayjs = dayjs.duration(secondsInput, "seconds");
            const hours = String(Math.floor(durationDayjs.asHours())).padStart(2, "0");
            const minutes = String(durationDayjs.minutes()).padStart(2, "0");
            const seconds = String(durationDayjs.seconds()).padStart(2, "0");
            return `${hours}:${minutes}:${seconds}`;
        },

        clickSave() {
            this.vModel = this.formatTime(this.timeDifferenceInSeconds(this.time3, this.time2));
            this.menu2 = false;
        },
    },
};
</script>

<style>
.v-picker {
    border-radius: 0px;
}
</style>
