<template>
    <v-card-text class="px-1 py-0">
        <v-container>
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
            >
                <v-row>
                    <v-col
                        class="pb-0"
                        cols="12"
                    >
                        <BaseTextField
                            v-model="vModel.description"
                            label="Descrição *"
                            validation-empty
                        />
                    </v-col>

                    <v-col
                        cols="12"
                        sm="6"
                        md="3"
                    >
                        <BaseTextFieldMoney
                            ref="inputCurrency"
                            v-model="handleValue"
                            label="Valor *"
                            validation-empty
                        />
                    </v-col>

                    <v-col
                        cols="12"
                        sm="6"
                        md="3"
                    >
                        <BaseDatePickerFormatted
                            v-model="vModel.date"
                            label="Data *"
                            validation-empty
                        />
                    </v-col>

                    <v-col
                        cols="12"
                        sm="6"
                        md="3"
                    >
                        <BaseAutoComplete
                            v-model="vModel.bankName"
                            label="Banco *"
                            item-value="name"
                            item-text="name"
                            validation-empty
                            :items="listBanks"
                        />
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="3"
                    >
                        <BaseAutoComplete
                            v-model="vModel.actionName"
                            label="Ação *"
                            item-value="name"
                            item-text="name"
                            validation-empty
                            :items="actionNames"
                        />
                    </v-col>

                    <v-col cols="12">
                        <BaseTextArea
                            v-model="vModel.observation"
                            label="Observações"
                            cols="2"
                            hide-details
                        />
                    </v-col>
                </v-row>
            </v-form>
        </v-container>
    </v-card-text>
</template>

<script>
import { defineComponent } from "vue";

import { cleanCurrency, DateInstanceHelper } from "@helpers";

import { banks, actionNames } from "../../constants";

export default defineComponent({
    name: "FormHistory",

    props: {
        value: {
            type: Object,
            default: () => {},
        },
        isEdit: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            actionNames,
            listBanks: banks,
            valid: true,
            valueLocal: "R$ 0,00",
        };
    },

    computed: {
        vModel: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
        handleValue: {
            get() {
                return this.valueLocal;
            },
            set(value) {
                const cleanValue = cleanCurrency(value);
                if (!this.isEdit) {
                    const valueIsPositive = cleanValue > 0;
                    const actionName = valueIsPositive ? "Adicionado" : "Retirado";
                    this.vModel.actionName = actionName;
                }
                this.valueLocal = value;
                this.vModel.value = cleanValue;
            },
        },
    },

    watch: {
        value: {
            immediate: true,
            handler(newValue) {
                if (!newValue || !newValue.date) {
                    this.vModel.date = this.getDateDefault();
                }

                if (newValue && newValue.value) {
                    this.valueLocal = newValue.value;
                    this.setValueInputCurrency(newValue.value);
                }
            },
        },
    },

    methods: {
        getDateDefault() {
            const dateInstanceHelper = new DateInstanceHelper();
            return dateInstanceHelper.getDateFormatted("DD/MM/YYYY");
        },
        validate() {
            return this.$refs.form.validate();
        },
        resetValidation() {
            return this.$refs.form.resetValidation();
        },
        setValueInputCurrency(value = "R$ 0,00") {
            if (this.$refs.inputCurrency) {
                const input = this.$refs.inputCurrency.$el.querySelector("input");
                input.value = value;
            }
        },
    },
});
</script>
