import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";

dayjs.extend(isBetween);

export function validarFormato(dt_data) {
    const REGEX_VALIDATE_YEAR_MONTH_DAY = /(.{1,4})-(.{1,2})-(.{1,2})$/;
    const ds_mensagem = `Formato inválido, o formato aceito é YYYY-MM-DD, a data enviada foi ${dt_data}`;
    if (!dt_data || !dt_data?.match(REGEX_VALIDATE_YEAR_MONTH_DAY)) throw new Error(ds_mensagem);
}

export function getData(nr_ano, nr_mes, nr_dia) {
    const nr_mes_formatado = String(nr_mes).padStart(2, "0");
    const nr_dia_formatado = String(nr_dia).padStart(2, "0");
    return `${nr_ano}-${nr_mes_formatado}-${nr_dia_formatado}`;
}

export default {
    namespaced: true,
    state: {
        items: [],
    },
    mutations: {
        setItems(state, payload) {
            state.items = payload;
        },
    },
    actions: {
        async fetchAll(context, payload) {
            if (context.getters.getItemsIsEmpty || payload.force) {
                const response = await this.$rangeSemanasHttpGateway.findAllByRangeData(
                    payload.dt_mes_ano,
                );
                context.commit("setItems", response);
            }
        },
        updateItems(context, payload) {
            context.commit("setItems", payload);
        },
    },
    getters: {
        getItems: ({ items }) => items,
        getItemsIsEmpty: ({ items }) => items.length === 0,
        getNrSemanaAtual(state, { getNrSemanaByData }) {
            const nr_semana = getNrSemanaByData(dayjs().format("YYYY-MM-DD"));
            if (nr_semana === "N/A") return 4;
            return nr_semana - 1;
        },
        getNrSemanaByData({ items }) {
            return (dt_data) => {
                validarFormato(dt_data);
                const objRangeSemanaResultado = items.find((objRangeSemana) => {
                    const {
                        nr_dia_inicio,
                        nr_mes_inicio,
                        nr_ano_inicio,
                        nr_dia_final,
                        nr_mes_final,
                        nr_ano_final,
                    } = objRangeSemana;
                    const dt_inicio = getData(
                        nr_ano_inicio,
                        nr_mes_inicio,
                        nr_dia_inicio,
                    );
                    const dt_final = getData(
                        nr_ano_final,
                        nr_mes_final,
                        nr_dia_final,
                    );
                    return dayjs(dt_data).isBetween(dt_inicio, dt_final, null, "[]");
                });
                if (!objRangeSemanaResultado) return "N/A";
                return objRangeSemanaResultado.nr_semana;
            };
        },
    },
};
