export default function getReportMonthly(items = []) {
    const data = {
        totalExpensesPaid: 0,
        totalExpenses: 0,
        totalInvestments: 0,
    };
    for (const item of items) {
        if (item.isPay) {
            data.totalExpensesPaid += item.value;
        }
        if (item?.category_id?.name === "Investimentos") {
            data.totalInvestments += item.value;
        }
    }
    return data;
}
