<template>
    <ContaBancariaNovaTransacaoSecao ds-titulo="Datas">
        <v-row>
            <v-col
                v-show="snEditar === false"
                cols="6"
                class="pb-0"
            >
                <v-checkbox
                    v-model="sn_hoje_transacao"
                    label="Hoje"
                    hide-details
                    class="mt-0 pt-0"
                />
            </v-col>
            <v-col
                cols="6"
                class="pb-0 ml-auto"
            >
                <v-radio-group
                    v-model="ds_data_pre_definidas"
                    row
                    hide-details
                    class="mt-0 pt-0"
                >
                    <v-radio
                        value="HOJE_PROCESSADO"
                        label="Hoje"
                        hide-details
                        class="mt-0 pt-0"
                    />
                    <v-radio
                        label="Copiar da transação"
                        value="COPIAR_TRANSACAO"
                        hide-details
                        class="mt-0 pt-0"
                    />
                </v-radio-group>
            </v-col>
        </v-row>

        <v-row>
            <v-col
                class="pb-0"
                cols="4"
            >
                <BaseDatePickerFormatted
                    v-model="ds_data_transacao"
                    label="Data da transação *"
                    validation-empty
                    :disabled="sn_hoje_transacao === true || snEditar === true"
                />
            </v-col>
            <v-col
                class="pb-0"
                cols="2"
            >
                <BaseTimePickerMenu
                    v-model="ds_hora_transacao"
                    :disabled="getSnHoraTransacaoDesabilitado"
                    validation-empty
                />
            </v-col>

            <v-col
                class="pb-0"
                cols="4"
            >
                <BaseDatePickerFormatted
                    v-model="ds_data_processado"
                    label="Data processado"
                    :date-picker="{ min: getDataMinimaProcessado }"
                    :disabled="getSnDataProcessadoDesabilitado"
                />
            </v-col>
            <v-col
                class="pb-0"
                cols="2"
            >
                <BaseTimePickerMenu
                    v-model="ds_hora_processado"
                    :disabled="getSnHojeProcessadoDesabilitado"
                />
            </v-col>
        </v-row>
    </ContaBancariaNovaTransacaoSecao>
</template>

<script>
import getSnRangeInicioMesFechamentoFatura from "../../mixins/getSnRangeInicioMesFechamentoFatura";

const FORMATO_DATA_BACKEND = "YYYY-MM-DDTHH:mm";
const FORMATO_DATA_INPUT_SEM_HORAS = "DD/MM/YYYY";
const FORMATADO_INPUT_HORAS = "HH:mm";

export default {
    props: {
        value: {
            type: Object,
            default: () => ({}),
        },
        snEditar: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            sn_hoje_processado: false,
            sn_hoje_transacao: false,
            ds_data_pre_definidas: "",
        };
    },

    computed: {
        getDayJS() {
            const dayjs = this.$dayjs().clone();
            const nr_dia = dayjs.get("date");
            const sn_range_inicio_mes_fechamento_fatura = getSnRangeInicioMesFechamentoFatura(
                nr_dia,
            );
            if (sn_range_inicio_mes_fechamento_fatura) {
                dayjs.subtract(1, "month");
            }
            return dayjs;
        },
        getMinDate() {
            return this.getDayJS
                .set("date", 6)
                .format("YYYY-MM-DD");
        },
        getMaxDate() {
            return this.getDayJS
                .add(1, "month")
                .set("date", 5)
                .format("YYYY-MM-DD");
        },
        getSnExisteDataProcessado() {
            return this.snEditar === true && !!this.vModel.dt_processado;
        },
        getSnDataProcessadoDesabilitado() {
            return (
                !this.ds_data_transacao
                || !this.ds_hora_transacao
                || this.getSnHojeProcessado === true
                || this.getSnCopiarTransacao === true
            );
        },
        getSnHojeProcessadoDesabilitado() {
            return this.getSnDataProcessadoDesabilitado || !this.ds_data_processado;
        },
        getDataMinimaProcessado() {
            if (!this.ds_data_transacao) return "";
            const [dia, mes, ano] = this.ds_data_transacao.split("/");
            return `${ano}-${mes}-${dia}`;
        },
        vModel: {
            get() {
                return this.value;
            },
            set(objTransacao) {
                this.$emit("input", objTransacao);
            },
        },
        getSnHoraTransacaoDesabilitado() {
            return (
                this.sn_hoje_transacao === true
                || this.snEditar === true
                || !this.ds_data_transacao
            );
        },
        ds_data_transacao: {
            get() {
                const { ds_data } = this.getObjData(this.vModel.dt_data);
                return ds_data;
            },
            set(ds_data) {
                this.vModel.dt_data = this.getDataFormatado(
                    ds_data,
                    this.ds_hora_transacao,
                );
                if (this.getSnCopiarTransacao === true) {
                    this.vModel.dt_processado = this.getDataFormatado(
                        ds_data,
                        this.ds_hora_transacao,
                    );
                }
            },
        },
        ds_hora_transacao: {
            get() {
                const { ds_hora } = this.getObjData(this.vModel.dt_data);
                return ds_hora;
            },
            set(ds_hora) {
                this.vModel.dt_data = this.getDataFormatado(
                    this.ds_data_transacao,
                    ds_hora,
                );
                if (this.getSnCopiarTransacao === true) {
                    this.vModel.dt_processado = this.getDataFormatado(
                        this.ds_data_transacao,
                        ds_hora,
                    );
                }
            },
        },
        ds_data_processado: {
            get() {
                const { ds_data } = this.getObjData(this.vModel.dt_processado);
                return ds_data;
            },
            set(ds_data) {
                this.vModel.dt_processado = this.getDataFormatado(
                    ds_data,
                    this.ds_hora_processado,
                );
            },
        },
        ds_hora_processado: {
            get() {
                const { ds_hora } = this.getObjData(this.vModel.dt_processado);
                return ds_hora;
            },
            set(ds_hora) {
                this.vModel.dt_processado = this.getDataFormatado(
                    this.ds_data_processado,
                    ds_hora,
                );
            },
        },
        getSnHojeProcessado() {
            return this.ds_data_pre_definidas === "HOJE_PROCESSADO";
        },
        getSnCopiarTransacao() {
            return this.ds_data_pre_definidas === "COPIAR_TRANSACAO";
        },
    },

    watch: {
        sn_hoje_transacao(sn_hoje_transacao) {
            if (sn_hoje_transacao) {
                const dayjs = this.$dayjs().clone();
                this.vModel.dt_data = dayjs.format(FORMATO_DATA_BACKEND);
                this.ds_data_transacao = dayjs.format(FORMATO_DATA_INPUT_SEM_HORAS);
                this.ds_hora_transacao = dayjs.format(FORMATADO_INPUT_HORAS);
            }
        },
        ds_data_pre_definidas() {
            if (this.getSnCopiarTransacao) {
                const { ds_data, ds_hora } = this.getObjData(this.vModel.dt_data);
                this.vModel.dt_processado = this.vModel.dt_data;
                this.ds_data_processado = ds_data;
                this.ds_hora_processado = ds_hora;
            }
            if (this.getSnHojeProcessado) {
                const dayjs = this.$dayjs().clone();
                this.vModel.dt_processado = dayjs.format(FORMATO_DATA_BACKEND);
                this.ds_data_processado = dayjs.format(FORMATO_DATA_INPUT_SEM_HORAS);
                this.ds_hora_processado = dayjs.format(FORMATADO_INPUT_HORAS);
            }
        },
    },

    methods: {
        getDsDataFormatado(ds_data) {
            if (!ds_data) return "";
            const [nr_dia, nr_mes, nr_ano] = ds_data.split("/");
            return `${nr_ano}-${nr_mes}-${nr_dia}`;
        },
        getDsHoraFormatado(ds_hora) {
            return ds_hora ? `T${ds_hora}:00.000Z` : "";
        },
        getDataFormatado(ds_data, ds_hora) {
            const ds_data_formatado = this.getDsDataFormatado(ds_data);
            const ds_hora_formatado = this.getDsHoraFormatado(ds_hora);
            return `${ds_data_formatado}${ds_hora_formatado}`;
        },
        converterStringDiaMesAno(ds_data) {
            const [nr_ano, nr_mes, nr_dia] = ds_data.split("-");
            return `${nr_dia}/${nr_mes}/${nr_ano}`;
        },
        getObjData(dt_data) {
            const objData = {
                ds_data: "",
                ds_hora: "",
            };
            if (!dt_data) return objData;
            const [ds_data, ds_time] = dt_data.split("T");
            if (ds_data) {
                const ds_data_formatado = this.converterStringDiaMesAno(ds_data);
                objData.ds_data = ds_data_formatado;
            }
            if (ds_time) {
                const REGEX_HORAS = /^(\d{2}:\d{2})/;
                const [, ds_hora] = ds_time.match(REGEX_HORAS);
                objData.ds_hora = ds_hora;
            }
            return objData;
        },
    },
};
</script>
