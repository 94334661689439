import Vue from "vue";
import { TiptapVuetifyPlugin } from "tiptap-vuetify";
import "tiptap-vuetify/dist/main.css";

export default function initTiptapVuetify(vuetify) {
    Vue.use(TiptapVuetifyPlugin, {
        vuetify,
        iconsGroup: "mdi",
    });
}
