<template>
    <v-menu
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        color="vdPrimary"
        offset-y
        min-width="auto"
        :disabled="disabled"
    >
        <template #activator="{ on, attrs }">
            <BaseTextField
                v-bind="{ ...attrs, label: $attrs.label }"
                v-model="displayDateLocal"
                readonly
                color="vdPrimary"
                :hide-details="!validationEmpty"
                :validation-empty="validationEmpty"
                :disabled="disabled"
                v-on="on"
            />
        </template>
        <v-date-picker
            v-model="vModel"
            locale="pt-BR"
            v-bind="{ ...datePicker }"
            class="vd-time-picker"
            @input="menu = false"
        />
    </v-menu>
</template>

<script>
import { defineComponent } from "vue";

import { DateStringHelper } from "@helpers";

export default defineComponent({
    props: {
        value: {
            type: [Date, String, Object],
            default: "",
        },
        datePicker: {
            type: Object,
            default: () => ({}),
        },
        displayDate: {
            type: String,
            default: "",
        },
        validationEmpty: {
            type: Boolean,
            default: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            menu: false,
        };
    },

    computed: {
        vModel: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
        displayDateLocal: {
            get() {
                if (this.displayDate) return this.displayDate;
                let newValue = this.vModel;
                if (newValue) {
                    const dateStringHelper = new DateStringHelper(newValue, "YYYY-MM-DD", "-");
                    newValue = dateStringHelper.getDateFormatted("DD/MM/YYYY");
                }
                return newValue;
            },
            set() {},
        },
    },
});
</script>

<style>
.vd-time-picker .v-picker__body {
    min-height: 337px;
}
</style>
