export default class ControleMensalLancamentosHttpGateway {
    httpClient;

    basePath = "/controle-mensal/lancamentos";

    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    async getAll() {
        const { data } = await this.httpClient.get(this.basePath);
        return data;
    }

    async getByMesAno(dt_mes_ano) {
        const { data } = await this.httpClient.get(this.basePath, {
            params: {
                dt_mes_ano
            }
        });
        return data;
    }

    async create(body = {}) {
        const { data } = await this.httpClient.post(this.basePath, body);
        return data;
    }

    async update(id = -1, body = {}) {
        const { data } = await this.httpClient.patch(`${this.basePath}/${id}`, body);
        return data;
    }

    async remove(id = -1) {
        const { data } = await this.httpClient.delete(`${this.basePath}/${id}`);
        return data;
    }

    async dashboard(nr_ano) {
        const { data } = await this.httpClient.get(`${this.basePath}/dashboard`, {
            params: {
                nr_ano
            }
        });
        return data;
    }
}
