<template>
    <div class="lancamentos">
        <BaseHeaderPage class="lancamentos__header">
            <template #right>
                <div class="lancamentos__actions">
                    <BaseButtonFilter
                        v-model="viewFilters"
                        color="vdPrimary"
                    />

                    <LancamentosModalSalvar
                        ref="lancamentosModalSalvar"
                        @atualizarLancamento="atualizarLancamento"
                        @adicionarLancamento="adicionarLancamento"
                    />
                </div>
            </template>
        </BaseHeaderPage>

        <BaseFilters
            v-model="filters"
            :view-filters="viewFilters"
            class="mb-4"
            view-month-year
            @clearAction="clearAction"
            @searchAction="searchAction"
        />

        <BaseSheet>
            <BaseTable
                :headers="headers"
                :items="arrLancamentos"
                :items-per-page="-1"
                ds-mensagem-vazio="Sem lançamentos nesse mês"
                @editAction="editAction"
                @deleteAction="deleteAction"
            >
                <template #nr_valor="{ currentItem }">
                    {{ currentItem | toCurrencyToLocaleString }}
                </template>

                <template #ds_status="{ currentItem }">
                    <v-chip
                        :color="categoriasCores[currentItem].ds_background"
                        :text-color="categoriasCores[currentItem].ds_texto"
                    >
                        {{ currentItem }}
                    </v-chip>
                </template>
            </BaseTable>
        </BaseSheet>
    </div>
</template>

<script>
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import headers from "../constants/headers.json";
import categoriasCores from "@/modules/ControleMensal/constants/categoriasCores";
import { formatDate } from "@/modules/Main/helpers";
import { getData, validarFormato } from "../rangeSemanas.store";

dayjs.extend(utc);

export default {
    data() {
        return {
            headers,
            categoriasCores,
            arrLancamentos: [],
            viewFilters: false,
            filters: {
                monthYear: "",
            },
            arrSemanas: [],
        };
    },

    async mounted() {
        this.clearAction();
    },

    methods: {
        editAction(objPayload) {
            this.$refs.lancamentosModalSalvar.abrirModal({
                ...objPayload,
                nr_valor: Number(objPayload.nr_valor).toFixed(2),
                dt_data: dayjs(objPayload.dt_data).utc().format("YYYY-MM-DD"),
            });
        },
        clearAction() {
            const monthYear = this.$dayjs().format("MM/YYYY");
            this.filters.monthYear = monthYear;
            this.searchAction({ monthYear });
        },
        async searchAction({ monthYear }) {
            const [nr_mes, nr_ano] = monthYear.split("/");
            const dt_mes_ano = this.$dayjs(`${nr_ano}-${nr_mes}-01`).format("YYYY-MM");
            this.arrSemanas = await this.$rangeSemanasHttpGateway.findAllByRangeData(
                dt_mes_ano,
            );
            const response = await this.$lancamentosHttpGateway.findByMesAno(dt_mes_ano);
            this.setArrLancamentos(response);
        },
        async deleteAction(item) {
            await this.$lancamentosHttpGateway.remove(item.cd_lancamento);
            const arrLancamentos = this.arrLancamentos.filter(
                ({ cd_lancamento }) => cd_lancamento !== item.cd_lancamento,
            );
            this.setArrLancamentos(arrLancamentos);
            this.$toast.success("Lançamento deletado com sucesso!");
        },
        adicionarLancamento(objLancamento) {
            const copiaArrLancamentos = [...this.arrLancamentos];
            copiaArrLancamentos.push(objLancamento);
            this.setArrLancamentos(copiaArrLancamentos);
        },
        atualizarLancamento(objLancamento) {
            const copiaArrLancamentos = [...this.arrLancamentos];
            const arrLancamentos = copiaArrLancamentos.map((objLancamentoAtual) => {
                if (objLancamentoAtual.cd_lancamento === objLancamento.cd_lancamento) {
                    return objLancamento;
                }
                return objLancamentoAtual;
            });
            this.setArrLancamentos(arrLancamentos);
        },
        getNrSemanaByData(dt_data) {
            validarFormato(dt_data);
            const objRangeSemanaResultado = this.arrSemanas.find((objRangeSemana) => {
                const {
                    nr_dia_inicio,
                    nr_mes_inicio,
                    nr_ano_inicio,
                    nr_dia_final,
                    nr_mes_final,
                    nr_ano_final,
                } = objRangeSemana;
                const dt_inicio = getData(
                    nr_ano_inicio,
                    nr_mes_inicio,
                    nr_dia_inicio,
                );
                const dt_final = getData(
                    nr_ano_final,
                    nr_mes_final,
                    nr_dia_final,
                );
                return dayjs(dt_data).isBetween(dt_inicio, dt_final, null, "[]");
            });
            if (!objRangeSemanaResultado) return "N/A";
            return objRangeSemanaResultado.nr_semana;
        },
        getDsSemana(ds_data) {
            const ds_data_formatado = ds_data.replace(".000Z", "");
            const objDayjs = this.$dayjs(ds_data_formatado);
            const ds_data_ano_mes_dia = objDayjs.format("YYYY-MM-DD");
            const nr_semana = this.getNrSemanaByData(ds_data_ano_mes_dia);
            return `${nr_semana}/4`;
        },
        setArrLancamentos(arrLancamentos = []) {
            const arrLancamentosOrdenado = arrLancamentos.sort(
                (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
            );
            this.arrLancamentos = arrLancamentosOrdenado.map((objLancamento) => ({
                ...objLancamento,
                nr_semana: this.getDsSemana(objLancamento.dt_data),
                dt_data: formatDate(objLancamento.dt_data),
                ds_categoria: objLancamento.Categorias.ds_nome,
                ds_observacao: objLancamento?.ds_observacao || "-",
                ds_status: objLancamento.Status.ds_nome,
            }));
        },
    },
};
</script>

<style lang="scss" scoped>
.lancamentos {
    $ref: &;

    &__header {
        margin-bottom: 0.75rem;

        #{$ref}__actions {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            column-gap: 16px;
            height: 100%;
        }
    }

    &__list {
        display: grid;
        gap: 1rem;
        justify-content: center;
        grid-template-columns: repeat(auto-fill, 250px);
    }
}
</style>
