<template>
    <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        class="nova-transacao__form"
    >
        <ContaBancariaNovaTransacaoTipo
            v-model="cd_transacao_tipo"
            :sn-editar="getSnEditar"
            :sn-desabilitar-adiantamento="getSnDesabilitarAdiantamento"
            :sn-desabilitar-estorno="getSnDesabilitarEstorno"
        />

        <ContaBancariaNovaTransacaoInformacoes
            v-model="vModel"
            :sn-editar="getSnEditar"
            :vl-disponivel="vlDisponivel"
            :vl-pagar="vlPagar"
            :arr-faturas="arrFaturas"
            :arr-transacoes-estorno="arrTransacoesEstorno"
        />

        <ContaBancariaNovaTransacaoData
            v-model="vModel"
            :sn-editar="getSnEditar"
        />
    </v-form>
</template>

<script>
import getSnEstorno from "../../mixins/getSnEstorno";

export default {
    mixins: [getSnEstorno],

    props: {
        value: {
            type: Object,
            default: () => ({}),
        },
        vlDisponivel: {
            type: Number,
            default: 0,
        },
        vlPagar: {
            type: Number,
            default: 0,
        },
        arrFaturas: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            valid: true,
            arrTransacoesEstorno: [],
            sn_buscou_transacoes_estorno: false,
        };
    },

    computed: {
        getSnDesabilitarAdiantamento() {
            return this.getSnFaturasVazio === true && this.vlPagar === 0;
        },
        getSnDesabilitarEstorno() {
            return this.getSnFaturasVazio === true && this.getSnTransacoesVazio === true;
        },
        getSnTransacoesVazio() {
            return this.arrTransacoesEstorno.length === 0;
        },
        getSnFaturasVazio() {
            return this.arrFaturas.length === 0;
        },
        getSnTransacao() {
            return this.getSnEstorno(this.cd_transacao_tipo) === true;
        },
        getSnEditar() {
            return !!this.vModel.cd_transacao;
        },
        vModel: {
            get() {
                return this.value;
            },
            set(objTransacao) {
                this.$emit("input", objTransacao);
            },
        },
        cd_transacao_tipo: {
            get() {
                return this.vModel.cd_transacao_tipo;
            },
            set(cd_transacao_tipo) {
                const sn_estorno = cd_transacao_tipo === 2;
                if (sn_estorno === true) {
                    this.vModel = {
                        ...this.vModel,
                        nr_mes: this.$dayjs().clone().get("month") + 2,
                        cd_conta_bancaria: this.$route.params.cd_conta_bancaria,
                    };
                }
                const sn_adiantamento = cd_transacao_tipo === 4;
                if (sn_adiantamento === true) {
                    this.vModel.ds_descricao = "Pagamento recebido";
                }
                const sn_parcelamento = cd_transacao_tipo === 3;
                if (sn_parcelamento === true) {
                    this.vModel.cd_fatura = null;
                }
                this.resetValidation();
                this.vModel = {
                    ...this.vModel,
                    cd_transacao_tipo,
                };
            },
        },
    },

    mounted() {
        this.resetValidation();
        const { cd_conta_bancaria } = this.$route.params;
        this.vModel.cd_conta_bancaria = +cd_conta_bancaria;
        if (this.sn_buscou_transacoes_estorno === false) {
            this.buscarTransacoesEstorno();
        }
    },

    methods: {
        async buscarTransacoesEstorno() {
            const { cd_conta_bancaria } = this.$route.params;
            const arrTransacoesEstorno = await this.$cartaoCredito.transacaoHttpGateway
                .getTransacoesCompras(
                    cd_conta_bancaria,
                );
            this.arrTransacoesEstorno = arrTransacoesEstorno;
            this.sn_buscou_transacoes_estorno = true;
        },
        validate(callbackValido = () => {}) {
            const sn_form_valido = this.$refs.form.validate();
            if (sn_form_valido === true) {
                callbackValido();
            }
            return sn_form_valido;
        },
        resetValidation() {
            if (this.$refs.form) {
                this.$refs.form.resetValidation();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.nova-transacao__form {
    display: flex;
    flex-direction: column;
    row-gap: 1.5rem;
}
</style>
