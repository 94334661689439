import Vue from "vue";
import { DateStringHelper } from "@helpers";
import { HistoryAdapter } from "./api/History";

export default {
    namespaced: true,
    state: {
        list: [],
        listAllItems: [],
        isDatabaseData: false,
    },
    getters: {
        getList: (state) => state.list,
        getListAllItems: (state) => state.listAllItems,
        listIsEmpty: (state) => state.list && state.list.length === 0,
        listIsEmptyAllItems: ({ listAllItems }) => listAllItems && listAllItems.length === 0,
    },
    mutations: {
        setList(state, payload) {
            state.list = payload;
        },
        setListAllItems(state, payload) {
            state.listAllItems = payload;
        },
        setIsDatabaseData(state, payload) {
            state.isDatabaseData = payload;
        },
    },
    actions: {
        async getAll({
            getters,
            commit,
            state,
            dispatch,
        }) {
            try {
                if (getters.listIsEmpty && !state.isDatabaseData) {
                    const response = await this.$historyHttpGateway.getAll();
                    commit("setListAllItems", response);
                    commit("setIsDatabaseData", true);
                    dispatch("filterByYear");
                }
                Promise.resolve();
            } catch (e) {
                Promise.reject(e);
            }
        },
        async deleteItem({ getters, commit, dispatch }, idItem) {
            try {
                const result = getters.getListAllItems.filter((item) => item.id !== idItem);
                await this.$historyHttpGateway.delete(idItem);
                commit("setListAllItems", result);
                dispatch("filterByYear");
                Vue.$toast.success("Item deletado com sucesso!");
                Promise.resolve();
            } catch (e) {
                Vue.$toast.error("Não foi possível deletar o item!");
                Promise.reject(e);
            }
        },
        async saveItem({ commit, getters, dispatch }, payload) {
            try {
                const response = await this.$historyHttpGateway.new(payload, payload.id);
                const historyAdapter = new HistoryAdapter();
                const result = historyAdapter.dtoToModel(response);
                const incrementList = [...getters.getListAllItems, result];
                commit("setListAllItems", incrementList);
                dispatch("filterByYear");
                Vue.$toast.success("Item criado com sucesso!");
                Promise.resolve();
            } catch (e) {
                Vue.$toast.error("Não foi possível criar o item!");
                Promise.reject(e);
            }
        },
        async updateItem({ commit, getters, dispatch }, payload) {
            try {
                const response = await this.$historyHttpGateway.update(payload);
                const historyAdapter = new HistoryAdapter();
                const dtoToModel = historyAdapter.dtoToModel(response);
                const result = getters.getListAllItems.map((item) => {
                    if (item.id === payload.id) return dtoToModel;
                    return item;
                });
                commit("setListAllItems", result);
                dispatch("filterByYear");
                Vue.$toast.success("Item editado com sucesso!");
                Promise.resolve();
            } catch (e) {
                Vue.$toast.error("Não foi possível editar o item!");
                Promise.reject(e);
            }
        },
        handleList({ commit }, payload) {
            commit("setList", payload);
        },
        handleListAllItems({ commit }, payload) {
            commit("setListAllItems", payload);
        },
        incrementList({ dispatch, state }, payload) {
            dispatch("handleList", [payload, ...state.list]);
        },
        resetFilters({ commit, state }) {
            commit("setList", state.listAllItems);
        },
        filterByYear({
            commit,
            getters,
            dispatch,
            rootGetters,
        }) {
            const { getListAllItems, listIsEmptyAllItems } = getters;
            const { getYearFilter } = rootGetters;
            let result = getListAllItems;
            if (getYearFilter && !listIsEmptyAllItems) {
                result = getListAllItems.filter(({ date }) => {
                    const dateStringHelper = new DateStringHelper(date);
                    const year = dateStringHelper.getYear();
                    return Number(year) === Number(getYearFilter);
                });
            }
            commit("setList", result);
            dispatch("handleChartsData", true, { root: true });
        },
    },
};
