export default class CalculationDTO {
    id;

    setId(value) {
        this.id = value;
    }

    category;

    setCategory(value) {
        this.category = value;
    }

    value;

    setValue(value) {
        this.value = value;
    }
}
