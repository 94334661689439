<template>
    <div>
        <BaseHeaderPage
            title-page="Status"
            :view-breadcrumb="false"
            :view-current-date="false"
            :view-icon-title="false"
            class="status__header"
        >
            <template #right>
                <div class="status__actions">
                    <ConfiguracoesStatusModalSalvar
                        ref="configuracoesStatusModalSalvar"
                        @atualizar="getAll"
                        @adicionar="getAll"
                    />
                </div>
            </template>
        </BaseHeaderPage>

        <ConfiguracoesStatusTable
            :arr-status="arrStatus"
            @editAction="editAction"
        />
    </div>
</template>

<script>
export default {
    data() {
        return {
            arrStatus: [],
            viewFilters: false,
            filters: {
                monthYear: "",
            },
        };
    },

    computed: {
        getArrStatusReceitas() {
            return this.arrStatus.slice(0, 1);
        },
        getArrStatus() {
            return this.arrStatus.slice(1);
        },
    },

    async mounted() {
        this.getAll();
    },

    methods: {
        statusHttpGateway() {
            return this.$configuracoes.statusHttpGateway;
        },
        editAction(objPayload) {
            this.$refs.configuracoesStatusModalSalvar.abrirModal(objPayload);
        },
        async getAll() {
            const response = await this.statusHttpGateway().getAll();
            this.arrStatus = response;
        },
    },
};
</script>

<style lang="scss" scoped>
.status {
    $ref: &;

    &__header {
        margin-bottom: 0.75rem;

        #{$ref}__actions {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            column-gap: 16px;
            height: 100%;
        }
    }
}
</style>
