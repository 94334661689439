<template>
    <ModalFormHistory
        v-model="vModel"
        :action-name="actionNameLocal"
        :data="dataLocal"
        :is-loading="loading"
        @saveAction="handleClick"
        @updateData="setDataLocal"
    >
        <template #default="{ attrs, on }">
            <v-btn
                v-bind="attrs"
                color="green"
                dark
                v-on="on"
                @click="setActionName('Novo')"
            >
                <v-icon left>
                    mdi-plus
                </v-icon>

                Adicionar
            </v-btn>
        </template>
    </ModalFormHistory>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";

export default Vue.extend({
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        actionName: {
            type: String,
            default: "Novo",
        },
        data: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            actionNameLocal: "Novo",
            dataLocal: {},
            loading: false,
        };
    },

    computed: {
        ...mapGetters({
            listAllItems: "reservaEmergencia/getListAllItems",
        }),
        vModel: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
        getId() {
            if (this.listAllItems.length > 0) {
                return String(Number(this.listAllItems.at(-1).id) + 1);
            }
            return "1";
        },
    },

    watch: {
        actionName: {
            immediate: true,
            handler(newValue) {
                this.setActionName(newValue);
            },
        },
        data: {
            immediate: true,
            handler(newData) {
                this.dataLocal = { ...newData };
            },
        },
    },

    methods: {
        async handleClick() {
            this.loading = true;
            if (this.dataLocal.id) {
                await this.updateInfos();
            } else {
                await this.saveInfos();
            }
            this.loading = false;
            this.vModel = false;
        },

        async saveInfos() {
            const id = this.getId;
            const data = {
                ...this.dataLocal,
                id,
            };
            await this.$store.dispatch("reservaEmergencia/saveItem", data);
        },

        async updateInfos() {
            await this.$store.dispatch("reservaEmergencia/updateItem", this.dataLocal);
        },

        setActionName(actionName) {
            this.actionNameLocal = actionName;
            if (actionName === "Editar") {
                this.setDataLocal(this.data);
            } else {
                this.setDataLocal({});
            }
        },

        setDataLocal(value) {
            this.dataLocal = value;
        },
    },
});
</script>
