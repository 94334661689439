<template>
    <BaseCardChart ds-titulo="Comparação Mensal: Estimativa">
        <BaseChartBar
            :chart-data="getChartData"
            chart-id="comparacao-mensal-estimativa"
            :chart-options="chartOptions"
        />
    </BaseCardChart>
</template>

<script>
import { toCurrencyToLocaleString } from "@/modules/Main/helpers";

export default {
    props: {
        nrMesAno: {
            type: String,
            default: "",
        },
    },
    data() {
        return {
            objCategoriasSemanasEstimativa: {
                objMercado: {
                    vl_mes_atual: 0,
                    vl_mes_anterior: 0,
                },
                objGasolina: {
                    vl_mes_atual: 0,
                    vl_mes_anterior: 0,
                },
                objLazer: {
                    vl_mes_atual: 0,
                    vl_mes_anterior: 0,
                },
            },
            chartOptions: {
                scales: {
                    x: {
                        stacked: true,
                    },
                    y: {
                        stacked: true,
                    },
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            footer: (tooltipItems) => {
                                const arrValores = tooltipItems.map(
                                    (tooltipItem) => tooltipItem.parsed.y,
                                );
                                const vl_soma = arrValores.reduce(
                                    (previous, current) => previous + current,
                                );
                                const vl_soma_formatado = toCurrencyToLocaleString(vl_soma);
                                return `Soma: ${vl_soma_formatado}`;
                            },
                        },
                    },
                    datalabels: {
                        anchor: "end",
                        align: "bottom",
                    },
                },
            },
        };
    },
    computed: {
        getNrMesNrAno() {
            if (this.nrMesAno) {
                const [nr_mes, nr_ano] = this.nrMesAno.split("/");
                return {
                    nr_mes: +nr_mes,
                    nr_ano,
                };
            }
            return {
                nr_mes: 0,
                nr_ano: 0,
            };
        },
        getChartData() {
            return {
                labels: ["Mês Atual", "Mês Anterior"],
                datasets: [
                    {
                        label: "Mercado",
                        backgroundColor: "#517BFB",
                        data: Object.values(this.objCategoriasSemanasEstimativa.objMercado),
                    },
                    {
                        label: "Gasolina",
                        backgroundColor: "#FE2154",
                        data: Object.values(this.objCategoriasSemanasEstimativa.objGasolina),
                    },
                    {
                        label: "Lazer",
                        backgroundColor: "#AF5AF4",
                        data: Object.values(this.objCategoriasSemanasEstimativa.objLazer),
                    },
                ],
            };
        },
    },
    watch: {
        nrMesAno: {
            immediate: true,
            handler(nr_mes_ano) {
                if (nr_mes_ano) {
                    this.getComparacaoMensalEstimativa();
                }
            },
        },
    },
    methods: {
        async getComparacaoMensalEstimativa() {
            const { nr_mes, nr_ano } = this.getNrMesNrAno;
            const objSemanasEstimativa = await this.$lancamentosHttpGateway
                .getComparacaoMensalEstimativa({
                    nr_mes,
                    nr_ano,
                });
            this.objCategoriasSemanasEstimativa = objSemanasEstimativa;
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
