<template>
    <div
        v-if="chartsData"
        class="evolution"
    >
        <header>
            <BaseHeaderPage />

            <BaseFilters
                :view-filters="viewFilters"
                view-year
            />
        </header>

        <div>
            <v-row class="mb-6 mt-3 ma-0">
                <v-col
                    v-for="(item, index) in items"
                    :key="`item-${index}`"
                    class="py-0"
                    :class="{ 'pl-0': index === 0, 'pr-0': index === items.length - 1 }"
                >
                    <DashboardHeaderItem
                        :icon="item.icon"
                        :color="item.color"
                        :title="item.title"
                        :value="item.value"
                        :is-currency="item.isCurrency"
                        :hidden-values="getIsHideValues"
                    />
                </v-col>
            </v-row>

            <EvolutionTable :data="chartsData.evolutionByMonthYear" />
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import { toCurrencyToLocaleString } from "@helpers";

export default {
    data() {
        return {
            viewFilters: false,
            missingValue: 0,
        };
    },
    computed: {
        ...mapGetters({
            chartsData: "getChartsData",
            reserveEmergencyValue: "calculation/getReserveEmergencyValue",
            getIsHideValues: "getIsHideValues",
        }),
        items() {
            const valueCurrency = toCurrencyToLocaleString(this.chartsData.missingValue);
            const percent = `${((this.chartsData.missingValue / this.reserveEmergencyValue) * 100).toFixed(2)}%`;
            return [
                {
                    icon: "mdi-chart-bell-curve",
                    color: "green",
                    title: "Valor Médio Aportes",
                    value: this.chartsData.average,
                    isCurrency: true,
                },
                {
                    icon: "mdi-calendar",
                    color: "orange",
                    title: "Meses Aportados",
                    value: this.chartsData.numberMonthsEntries,
                },
                {
                    icon: "mdi-bullseye-arrow",
                    color: "purple",
                    title: "Valor Faltante",
                    value: `${valueCurrency} / ${percent}`,
                    isCurrency: false,
                },
            ];
        },
    },
    async mounted() {
        this.$store.dispatch("handleLoading", true);
        await this.fetchReserveEmergencyValue();
        await this.fetchAllReservaEmergencia();
        this.$store.dispatch("handleLoading");
    },
    methods: {
        async fetchAllReservaEmergencia() {
            await this.$store.dispatch("reservaEmergencia/getAll");
        },
        async fetchReserveEmergencyValue() {
            await this.$store.dispatch("calculation/getAll");
        },
    },
};
</script>

<style lang="scss" scoped>
.evolution {
    height: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
}
</style>
