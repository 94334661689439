<template>
    <ContaBancariaNovaTransacaoSecao ds-titulo="Informações gerais">
        <v-row>
            <v-col
                v-if="getSnNumeroParcelas === false"
                class="pb-0"
            >
                <BaseAutoComplete
                    v-model="vModel.cd_fatura"
                    label="Fatura"
                    item-value="cd_fatura"
                    :item-text="getDsFatura"
                    hide-no-data
                    clearable
                    :items="arrFaturas"
                    :disabled="getSnFaturasVazio === true"
                />
            </v-col>
            <v-col
                v-if="getSnTransacao || getSnCartaoCredito"
                class="pb-0"
            >
                <BaseAutoComplete
                    v-if="getSnTransacao"
                    v-model="handleObjTransacaoEstorno"
                    label="Transação *"
                    item-value="cd_transacao"
                    :item-text="getDsDescricao"
                    return-object
                    validation-empty
                    :items="arrTransacoesEstorno"
                    :disabled="getSnDesabilitarTransacaoEstorno"
                />
                <BaseAutoComplete
                    v-else-if="getSnCartaoCredito"
                    v-model="handleCartaoCredito"
                    label="Cartão Crédito *"
                    item-value="cd_cartao_credito"
                    :item-text="getItemText"
                    validation-empty
                    return-object
                    :items="arrCartoes"
                    :loading="sn_carregando_cartoes"
                    :disabled="getSnEditar === true"
                />
            </v-col>
            <v-col
                v-if="getSnNumeroParcelas"
                class="pb-0"
            >
                <BaseAutoComplete
                    v-model="vModel.nr_parcelas"
                    label="Numero Parcelas *"
                    validation-empty
                    :items="getArrNrParcelas"
                    :disabled="getSnEditar === true"
                />
            </v-col>
        </v-row>

        <v-row>
            <v-col
                class="pb-0"
                cols="8"
            >
                <BaseTextField
                    v-model="vModel.ds_descricao"
                    label="Descrição *"
                    validation-empty
                    :disabled="getSnEditar === true"
                />
            </v-col>

            <v-col
                v-if="handleValor || getSnEditar === false"
                class="pb-0"
            >
                <BaseTextFieldMoney
                    ref="inputCurrency"
                    v-model="handleValor"
                    :label="getDsValor"
                    :validation-empty="getSnTransacao === false"
                    :disabled="getSnTransacao === true || getSnEditar === true"
                    :rules="getArrValidacoesValor"
                />
            </v-col>
        </v-row>
    </ContaBancariaNovaTransacaoSecao>
</template>

<script>
import { cleanCurrency, toCurrencyToLocaleString } from "@/modules/Main/helpers/toCurrencyHelper";
import getSnAdiantamento from "../../mixins/getSnAdiantamento";
import getSnEstorno from "../../mixins/getSnEstorno";
import getSnParcelamento from "../../mixins/getSnParcelamento";

export default {
    mixins: [
        getSnEstorno,
        getSnParcelamento,
        getSnAdiantamento,
    ],

    props: {
        value: {
            type: Object,
            default: () => ({}),
        },
        snEditar: {
            type: Boolean,
            default: false,
        },
        vlDisponivel: {
            type: Number,
            default: 0,
        },
        vlPagar: {
            type: Number,
            default: 0,
        },
        arrFaturas: {
            type: Array,
            default: () => [],
        },
        arrTransacoesEstorno: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            arrCartoes: [],
            sn_carregando_cartoes: false,
        };
    },

    computed: {
        getSnFaturasVazio() {
            return this.arrFaturas.length === 0;
        },
        getSnDesabilitarTransacaoEstorno() {
            const sn_sem_transacoes = this.arrTransacoesEstorno.length === 0
                && this.sn_buscou_transacoes_estorno === true;
            return this.getSnEditar === true || sn_sem_transacoes;
        },
        getSnTransacao() {
            return this.getSnEstorno(this.vModel.cd_transacao_tipo) === true;
        },
        getSnCartaoCredito() {
            return this.getSnAdiantamento(this.vModel.cd_transacao_tipo) === false;
        },
        getSnNumeroParcelas() {
            return this.getSnParcelamento(this.vModel.cd_transacao_tipo) === true;
        },
        getSnEditar() {
            return this.snEditar;
        },
        vModel: {
            get() {
                return this.value;
            },
            set(ds_valor) {
                this.$emit("input", ds_valor);
            },
        },
        getArrNrParcelas() {
            return Array.from({ length: 12 }, (_, i) => i + 1);
        },
        getDsValor() {
            return this.getSnParcelamento(this.vModel.cd_transacao_tipo) === true ? "Valor total *" : "Valor *";
        },
        handleObjTransacaoEstorno: {
            get() {
                return this.vModel.cd_transacao_principal;
            },
            set(objTransacaoEstorno) {
                let vModel = {};
                if (objTransacaoEstorno) {
                    const vl_valor = -Number(objTransacaoEstorno.vl_valor);
                    vModel = {
                        ...this.vModel,
                        vl_valor,
                        ds_descricao: `Estorno de "${objTransacaoEstorno.ds_descricao}"`,
                        cd_transacao_principal: objTransacaoEstorno.cd_transacao,
                    };
                    this.setValueInputCurrency(vl_valor);
                }
                this.vModel = vModel;
            },
        },
        handleCartaoCredito: {
            get() {
                return this.vModel.cd_cartao_credito;
            },
            set(objCartaoCredito) {
                this.vModel = {
                    ...this.vModel,
                    objCartaoCredito,
                    cd_cartao_credito: objCartaoCredito.cd_cartao_credito,
                };
            },
        },
        getArrValidacoesValor() {
            if (this.getSnTransacao === true) return [];
            const arrValidacoes = [
                this.validacaoSaldoDisponivel,
                this.validacaoNumeroNegativo,
            ];
            if (this.getSnAdiantamento(this.vModel.cd_transacao_tipo) === true) {
                arrValidacoes.push(this.validacaoValorAntecipado);
            }
            return arrValidacoes;
        },
        handleValor: {
            get() {
                if (!this.vModel.vl_valor) return this.vModel.vl_valor;
                return toCurrencyToLocaleString(this.vModel.vl_valor);
            },
            set(ds_valor) {
                this.vModel.vl_valor = ds_valor ? Number(cleanCurrency(ds_valor)) : ds_valor;
            },
        },
    },

    watch: {
        arrFaturas(novoArrFaturas) {
            if (!this.vModel.cd_fatura && novoArrFaturas.length > 0) {
                const objFaturaAtual = novoArrFaturas.find(({ objStatus }) => objStatus.ds_nome_status === "Atual");
                const cd_fatura = objFaturaAtual?.cd_fatura || novoArrFaturas.at(-1)?.cd_fatura;
                this.vModel.cd_fatura = cd_fatura;
            }
        },
    },

    mounted() {
        this.buscarCartoes();
    },

    methods: {
        getDsFatura(objFatura) {
            const { objMes, objStatus } = objFatura;
            const { ds_completo, nr_mes_formatado } = objMes;
            const { ds_nome_status } = objStatus;
            return `${ds_completo} - ${nr_mes_formatado} - ${ds_nome_status}`;
        },
        getDsDescricao(objTransacao) {
            const { ds_descricao, vl_valor, dt_data } = objTransacao;
            const ds_data = this.$dayjs(dt_data).format("DD/MM/YYYY HH:mm");
            return `${ds_descricao} - ${toCurrencyToLocaleString(vl_valor)} - ${ds_data}`;
        },
        async buscarCartoes() {
            this.sn_carregando_cartoes = true;
            const { cd_conta_bancaria } = this.$route.params;
            const arrCartoes = await this.$cartaoCreditoHttpGateway.getByContaBancaria(
                cd_conta_bancaria,
            );
            this.arrCartoes = arrCartoes;
            this.sn_carregando_cartoes = false;
        },
        getItemText(objCartaoCredito) {
            return `${objCartaoCredito.ds_nome} - ${objCartaoCredito.nr_finais} - ${objCartaoCredito.CartaoCreditoTipo.ds_tipo}`;
        },
        setValueInputCurrency(vl_valor = 0) {
            if (this.$refs.inputCurrency) {
                const input = this.$refs.inputCurrency.$el.querySelector("input");
                const vl_valor_formatado = toCurrencyToLocaleString(vl_valor);
                input.value = vl_valor_formatado;
            }
        },
        validacaoSaldoDisponivel(ds_valor) {
            const nr_valor = Number(cleanCurrency(ds_valor));
            const vl_disponivel_formatado = toCurrencyToLocaleString(this.vlDisponivel);
            return nr_valor <= this.vlDisponivel || `Saldo não disponível (${vl_disponivel_formatado})`;
        },
        validacaoNumeroNegativo(ds_valor) {
            const nr_valor = Number(cleanCurrency(ds_valor));
            return nr_valor > 0 || "O valor não pode ser negativo";
        },
        validacaoValorAntecipado(ds_valor) {
            const nr_valor = Number(cleanCurrency(ds_valor));
            const vl_pagar_formatado = toCurrencyToLocaleString(this.vlPagar);
            const ds_mensagem = `Valor do adiantamento excede o valor em aberto (${vl_pagar_formatado})`;
            return nr_valor <= this.vlPagar || ds_mensagem;
        },
    },
};
</script>
