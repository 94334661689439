<template>
    <div>
        <tiptap-vuetify
            v-model="vModel"
            :extensions="extensions"
            :card-props="cardProps"
        />
    </div>
</template>

<script>
import {
    TiptapVuetify,
    Heading,
    Bold,
    Underline,
    Paragraph,
    BulletList,
    OrderedList,
    ListItem,
    Blockquote,
    History,
} from "tiptap-vuetify";

export default {
    components: { TiptapVuetify },

    props: {
        value: {
            type: String,
            default: "",
        },
    },

    data: () => ({
        extensions: [
            History,
            Blockquote,
            Underline,
            ListItem,
            BulletList,
            OrderedList,
            [Heading, {
                options: {
                    levels: [1, 2, 3],
                },
            }],
            Bold,
            Paragraph,
        ],
        cardProps: {
            flat: true,
            outlined: true,
        },
    }),

    computed: {
        vModel: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },
};
</script>
