<template>
    <BaseButton
        dark
        icon
        @click="handleIsHideValues"
    >
        <v-icon>
            mdi-eye{{ getSuffixIcon }}
        </v-icon>
    </BaseButton>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    computed: {
        ...mapGetters(["getIsHideValues"]),
        getSuffixIcon() {
            return this.getIsHideValues ? "" : "-off";
        },
        getStatusButton() {
            return this.getIsHideValues ? "Mostrar" : "Esconder";
        },
    },
    methods: {
        handleIsHideValues() {
            this.$store.dispatch("handleIsHideValues", !this.getIsHideValues);
        },
    },
};
</script>
