<template>
    <BaseModalSave
        v-model="isOpen"
        name-view="Cálculo"
        :action-name="saveModal.actionName"
        :is-loading="loading"
        max-width="650px"
        @save="updateOrInsert"
        @close="closeDialog"
    >
        <CalculationFields v-model="data" />
    </BaseModalSave>
</template>

<script>
import { mapGetters } from "vuex";

import { cleanCurrency } from "@helpers";

import CalculationModel from "../../api/Calculation/CalculationModel";

export default {
    data() {
        return {
            loading: false,
        };
    },

    computed: {
        ...mapGetters({
            getNewId: "calculation/getNewId",
            saveModal: "calculation/getSaveModal",
            getSaveModalData: "calculation/getSaveModalData",
        }),
        isOpen: {
            get() {
                return this.saveModal.isOpen;
            },
            set(value) {
                this.$store.dispatch("calculation/handleSaveModalIsOpen", value);
            },
        },
        data: {
            get() {
                return this.getSaveModalData;
            },
            set(value) {
                this.$store.dispatch("calculation/handleSaveModalData", value);
            },
        },
    },

    methods: {
        async updateOrInsert() {
            this.loading = true;
            const actionName = this.saveModal.actionName === "Novo" ? "create" : "update";
            const data = {
                ...this.data,
                value: cleanCurrency(this.data.value),
            };
            if (actionName === "create") {
                const id = this.getNewId;
                data.id = id;
            }
            await this.$store.dispatch(`calculation/${actionName}`, data);
            this.loading = false;
            this.isOpen = false;
        },
        closeDialog() {
            this.isOpen = false;
            this.data = {
                ...new CalculationModel(),
                value: "R$ 0,00",
            };
        },
    },
};
</script>
