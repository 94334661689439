export default class CartaoCreditoHttpGateway {
    httpClient;

    basePath = "gerenciamento-cartao-credito/cartao-credito";

    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    async getAll() {
        const { data } = await this.httpClient.get(this.basePath);
        return data;
    }

    async getByContaBancaria(cd_conta_bancaria) {
        const { data } = await this.httpClient.get(this.basePath, {
            params: {
                cd_conta_bancaria
            }
        });
        return data;
    }

    async create(body = {}) {
        const { data } = await this.httpClient.post(this.basePath, body);
        return data;
    }

    async update(id = -1, body = {}) {
        const { data } = await this.httpClient.patch(`${this.basePath}/${id}`, body);
        return data;
    }

    async remove(id = -1) {
        const { data } = await this.httpClient.delete(`${this.basePath}/${id}`);
        return data;
    }
}
