<template>
    <BaseModal
        v-model="sn_modal_aberto"
        max-width="550px"
        eager
    >
        <template #activator="{ on, attrs }">
            <v-btn
                v-bind="attrs"
                color="vdPrimary"
                dark
                v-on="on"
            >
                <v-icon left>
                    mdi-plus
                </v-icon>

                Adicionar
            </v-btn>
        </template>

        <v-card>
            <BaseFormHeader
                ds-nome-tela="Lançamento"
                :ds-nome-acao="ds_nome_acao"
            />
            <ControleMensalLancamentosModalSalvarForm
                ref="controleMensalLancamentosModalSalvarForm"
                v-model="payload"
                :is-edit="getSnEditar"
            />
            <BaseFormActions
                :sn-loading="sn_loading"
                @save="salvarAcao"
                @close="fecharModal"
            />
        </v-card>
    </BaseModal>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
    data() {
        return {
            payload: {
                dt_data: null,
                nr_valor: 0,
            },
            sn_loading: false,
            sn_modal_aberto: false,
            ds_nome_acao: "Criar",
        };
    },

    computed: {
        getSnEditar() {
            return this.ds_nome_acao === "Editar";
        },
    },

    methods: {
        abrirModal(objPayload = null) {
            if (objPayload) {
                this.ds_nome_acao = "Editar";
                this.payload = { ...objPayload };
                this.setValueInputCurrency(objPayload.nr_valor);
            }
            this.sn_modal_aberto = true;
        },
        fecharModal() {
            this.sn_modal_aberto = false;
            this.ds_nome_acao = "Criar";
            this.resetData();
            this.resetValidation();
        },

        getFormIsValid() {
            return this.$refs.controleMensalLancamentosModalSalvarForm.validate();
        },

        resetValidation() {
            if (this.$refs.controleMensalLancamentosModalSalvarForm) {
                this.$refs.controleMensalLancamentosModalSalvarForm.resetValidation();
            }
        },

        async salvarAcao() {
            const formIsValid = this.getFormIsValid();
            if (formIsValid) {
                this.sn_loading = true;
                let ds_nome_acao = "criado";
                if (this.payload.cd_lancamento) {
                    const objLancamento = await this.$controleMensalLancamentosHttpGateway.update(
                        this.payload.cd_lancamento,
                        this.payload,
                    );
                    this.$emit("atualizarLancamento", objLancamento);
                    ds_nome_acao = "editado";
                } else {
                    const objLancamento = await this.$controleMensalLancamentosHttpGateway.create(
                        this.payload,
                    );
                    this.$emit("adicionarLancamento", objLancamento);
                }
                this.$toast.success(`Lançamento ${ds_nome_acao} com sucesso!`);
                this.sn_loading = false;
                this.fecharModal();
            }
        },

        resetData() {
            this.payload = {
                dt_data: this.$dayjs().format("YYYY-MM-DD"),
                nr_valor: 0,
            };
            this.setValueInputCurrency();
        },

        setValueInputCurrency(nr_valor = 0) {
            if (this.$refs.controleMensalLancamentosModalSalvarForm) {
                this.$refs.controleMensalLancamentosModalSalvarForm.setValueInputCurrency(nr_valor);
            }
        },
    },
});
</script>
