export default class EstudosCursosHttpGateway {
    httpClient;

    basePath = "/estudos/cursos";

    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    async getById(id = 1) {
        const { data } = await this.httpClient.get(`${this.basePath}/${id}`);
        return data;
    }

    async getAll() {
        const { data } = await this.httpClient.get(this.basePath);
        return data;
    }
}
