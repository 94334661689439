<template>
    <v-row class="ma-0">
        <v-col class="py-0 pl-0">
            <BaseTextField
                v-model="vModel.category"
                label="Categoria *"
                validation-empty
            />
        </v-col>

        <v-col class="py-0 pr-0">
            <BaseTextFieldMoney
                v-model="vModel.value"
                label="Valor Unitário *"
                validation-empty
            />
        </v-col>
    </v-row>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        vModel: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },
};
</script>
