function getObjFaturaSelecionada(arrFaturas = []) {
    const objFaturaSelecionada = arrFaturas.find(
        (objFatura) => objFatura.sn_ativo === true,
    );
    return objFaturaSelecionada ?? arrFaturas.at(-1);
}

export default {
    methods: {
        async getArrFaturasFormatado(cd_conta_bancaria = -1) {
            return this.$cartaoCredito.faturaHttpGateway.getFaturasFormatado(
                cd_conta_bancaria,
            );
        },
        async buscarFaturasMixin(cd_conta_bancaria = -1) {
            const arrFaturasFormatado = await this.getArrFaturasFormatado(cd_conta_bancaria);
            const objFaturaSelecionada = getObjFaturaSelecionada(arrFaturasFormatado);
            return {
                objFaturaSelecionada,
                arrFaturas: arrFaturasFormatado,
            };
        },
    },
};
