import { mainStore } from "@module/Main";
import { rangeSemanasStore } from "@module/ControleDiario";
import {
    categoriasStore,
    paginasStore,
    planilhasStore,
    statusStore,
} from "@module/Configuracoes";

export default function storeFactory() {
    mainStore.registerModule("categorias", categoriasStore);
    mainStore.registerModule("status", statusStore);
    mainStore.registerModule("rangeSemanas", rangeSemanasStore);
    mainStore.registerModule("planilhas", planilhasStore);
    mainStore.registerModule("paginas", paginasStore);
}
