<template>
    <v-textarea
        outlined
        v-bind="$attrs"
        :rules="getRules"
        v-on="$listeners"
    />
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        validationEmpty: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        getRules() {
            let rules = [];
            if (this.validationEmpty) {
                rules.push((v) => !!v || `O campo ${this.$attrs.label} é obrigatório`);
            }
            if (this.$attrs.rules) {
                rules = [...rules, ...this.$attrs.rules];
            }
            return rules;
        },
    },
});
</script>
