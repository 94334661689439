<template>
    <div class="lancamentos">
        <BaseHeaderPage class="lancamentos__header">
            <template #right>
                <div class="lancamentos__actions">
                    <BaseButtonFilter
                        v-model="viewFilters"
                        color="vdPrimary"
                    />

                    <ControleMensalLancamentosModalSalvar
                        ref="controleMensalLancamentosModalSalvar"
                        @atualizarLancamento="atualizarLancamento"
                        @adicionarLancamento="adicionarLancamento"
                    />
                </div>
            </template>
        </BaseHeaderPage>

        <BaseFilters
            v-model="filters"
            :view-filters="viewFilters"
            class="mb-4"
            view-month-year
            @clearAction="clearAction"
            @searchAction="searchAction"
        />

        <ControleMensalLancamentosExpansion
            :arr-categorias="getArrCategoriasReceitas"
            class="mb-4"
            @editAction="editAction"
            @deleteAction="deleteAction"
        />

        <ControleMensalLancamentosExpansion
            :arr-categorias="getArrCategorias"
            @editAction="editAction"
            @deleteAction="deleteAction"
        />
    </div>
</template>

<script>
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);

export default {
    data() {
        return {
            arrCategorias: [],
            viewFilters: false,
            filters: {
                monthYear: "",
            },
        };
    },

    computed: {
        getArrCategoriasReceitas() {
            return this.arrCategorias.slice(0, 1);
        },
        getArrCategorias() {
            return this.arrCategorias.slice(1);
        },
    },

    async mounted() {
        this.clearAction();
    },

    methods: {
        editAction(objPayload) {
            this.$refs.controleMensalLancamentosModalSalvar.abrirModal({
                ...objPayload,
                nr_valor: Number(objPayload.nr_valor).toFixed(2),
                dt_data: dayjs(objPayload.dt_data).utc().format("YYYY-MM-DD"),
            });
        },
        clearAction() {
            const monthYear = this.$dayjs().format("MM/YYYY");
            this.filters.monthYear = monthYear;
            this.searchAction({ monthYear });
        },
        async searchAction({ monthYear }) {
            const [nr_mes, nr_ano] = monthYear.split("/");
            const dt_mes_ano = this.$dayjs(`${nr_ano}-${nr_mes}-01`).format("YYYY-MM");
            const response = await this.$controleMensalLancamentosHttpGateway.getByMesAno(
                dt_mes_ano,
            );
            this.arrCategorias = response;
        },
        async deleteAction(item) {
            await this.$controleMensalLancamentosHttpGateway.remove(item.cd_lancamento);
            this.searchAction(this.filters);
            this.$toast.success("Lançamento deletado com sucesso!");
        },
        adicionarLancamento() {
            this.searchAction(this.filters);
        },
        atualizarLancamento() {
            this.searchAction(this.filters);
        },
    },
};
</script>

<style lang="scss" scoped>
.lancamentos {
    $ref: &;

    &__header {
        margin-bottom: 0.75rem;

        #{$ref}__actions {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            column-gap: 16px;
            height: 100%;
        }
    }
}
</style>
