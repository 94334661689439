import * as views from "./views";
import TheDefaultVue from "../Main/layouts/TheDefault.vue";

export default [
    {
        path: "/controle-diario",
        name: "controle-diario",
        component: TheDefaultVue,
        meta: {
            icon: "mdi-calendar-week",
            viewMenu: true,
            title: "Controle Diário",
        },
        redirect() {
            return { name: "controle-diario-lancamentos" };
        },
        children: [
            {
                path: "dashboard",
                name: "controle-diario-dashboard",
                component: views.DashboardView,
                meta: {
                    icon: "mdi-view-dashboard",
                    viewMenu: true,
                    title: "Dashboard",
                },
            },
            {
                path: "lancamentos",
                name: "controle-diario-lancamentos",
                component: views.LancamentosView,
                meta: {
                    icon: "mdi-clipboard-list",
                    viewMenu: true,
                    title: "Lançamentos",
                },
            },
            {
                path: "range-semanas",
                name: "controle-diario-range-semanas",
                component: views.RangeSemanasView,
                meta: {
                    icon: "mdi-calendar-range",
                    viewMenu: true,
                    title: "Range Semanas",
                },
            },
        ],
    },
];
