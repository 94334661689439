export default class LeagueOfLegendsHttpGateway {
    httpClient;

    basePath = "/league-of-legends";

    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    async getHistoryByPuuid(puuid = "") {
        const { data } = await this.httpClient.get(`${this.basePath}/dashboard`, {
            params: {
                puuid
            }
        });
        return data;
    }

    async importar(puuid = "") {
        const { data } = await this.httpClient.post(`${this.basePath}/import`, null, {
            params: {
                puuid
            }
        });
        return data;
    }
}
