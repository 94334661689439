import Vue from "vue";
import { CalculationAdapter } from "./api/Calculation";

export default {
    namespaced: true,
    state: {
        list: [],
        total: {
            all: 0,
            three: 0,
            six: 0,
            twelve: 0,
        },
        saveModal: {
            data: {},
            isOpen: false,
            actionName: "Novo",
        },
    },
    getters: {
        getList: (state) => state.list,
        getTotal: ({ total }) => total,
        getReserveEmergencyValue: ({ total }) => total.twelve,
        listIsEmpty: (state) => state.list && state.list.length === 0,
        getNewId: (state) => (state.list.length > 0 ? String(Number(state.list.at(-1).id) + 1) : "1"),
        getSaveModal: (state) => state.saveModal,
        getSaveModalData: (state) => state.saveModal.data,
    },
    mutations: {
        setList(state, payload) {
            state.list = payload;
        },
        setSaveModalIsOpen(state, payload) {
            state.saveModal.isOpen = payload;
        },
        setSaveModalData(state, payload) {
            state.saveModal.data = payload;
        },
        setSaveModalActionName(state, payload) {
            state.saveModal.actionName = payload;
        },
        resetSaveModal(state) {
            state.saveModal = {
                actionName: "Novo",
                data: {},
                isOpen: false,
            };
        },
        setTotal(state, payload) {
            state.total = payload;
        },
    },
    actions: {
        async getAll({ commit, getters, dispatch }) {
            try {
                if (getters.listIsEmpty) {
                    const response = await this.$calculationHttpGateway.getAll();
                    commit("setList", response);
                    dispatch("getTotalByMonth");
                }
                Promise.resolve();
            } catch (e) {
                Promise.reject(e);
            }
        },
        async create({ commit, getters, dispatch }, payload) {
            try {
                const response = await this.$calculationHttpGateway.new(payload, payload.id);
                const calculationAdapter = new CalculationAdapter();
                const result = calculationAdapter.dtoToModel(response);
                const incrementList = [...getters.getList, result];
                commit("setList", incrementList);
                dispatch("getTotalByMonth");
                Vue.$toast.success("Item criado com sucesso!");
                Promise.resolve();
            } catch (e) {
                Vue.$toast.error("Não foi possível criar o item!");
                Promise.reject(e);
            }
        },
        async update({ commit, getters, dispatch }, payload) {
            try {
                const response = await this.$calculationHttpGateway.update(payload, payload.id);
                const calculationAdapter = new CalculationAdapter();
                const dtoToModel = calculationAdapter.dtoToModel(response);
                const result = getters.getList.map((item) => {
                    if (item.id === payload.id) return dtoToModel;
                    return item;
                });
                commit("setList", result);
                dispatch("getTotalByMonth");
                Vue.$toast.success("Item atualizado com sucesso!");
                Promise.resolve();
            } catch (e) {
                Vue.$toast.error("Não foi possível atualizar o item!");
                Promise.reject(e);
            }
        },
        async delete({ commit, getters, dispatch }, idItem) {
            try {
                const result = getters.getList.filter((item) => item.id !== idItem);
                await this.$calculationHttpGateway.delete(idItem);
                commit("setList", result);
                dispatch("getTotalByMonth");
                Vue.$toast.success("Item deletado com sucesso!");
                Promise.resolve();
            } catch (e) {
                Vue.$toast.error("Não foi possível deletar o item!");
                Promise.reject(e);
            }
        },
        handleSaveModalIsOpen({ commit }, payload) {
            if (payload) {
                commit("setSaveModalIsOpen", true);
                return;
            }
            commit("resetSaveModal");
            commit("setSaveModalIsOpen", false);
        },
        handleSaveModalData({ commit }, payload) {
            commit("setSaveModalActionName", "Editar");
            commit("setSaveModalData", payload);
        },
        handleSaveModal({ dispatch }, payload) {
            const { isOpen, data } = payload;
            dispatch("handleSaveModalIsOpen", isOpen);
            dispatch("handleSaveModalData", data);
        },
        getTotalByMonth({ getters, commit }) {
            const { listIsEmpty, getList } = getters;
            const total = {
                all: 0,
                three: 0,
                six: 0,
                twelve: 0,
            };
            if (listIsEmpty) {
                commit("setTotal", total);
                return;
            }
            for (const item of getList) {
                total.all += item.value;
                total.three += item.threeMonths;
                total.six += item.sixMonths;
                total.twelve += item.twelveMonths;
            }
            commit("setTotal", total);
        },
    },
};
