export default {
    namespaced: true,
    state: {
        userInfos: localStorage.getItem("userInfos") || null,
    },
    getters: {
        getUserInfos: ({ userInfos }) => userInfos,
    },
    mutations: {
        setUserInfos(state, payload) {
            state.userInfos = payload;
            if (payload) {
                localStorage.setItem("userInfos", JSON.stringify(payload));
            } else {
                localStorage.removeItem("userInfos");
            }
        },
    },
    actions: {
        handleUserInfos({ commit }, payload) {
            commit("setUserInfos", payload);
        },
        clearUserInfos({ commit }) {
            commit("setUserInfos", null);
        },
    },
};
