<template>
    <div class="categoria__header">
        <div class="categoria__column">
            {{ dsNome }}
        </div>

        <div class="categoria__column">
            <span>{{ getDsStatus }}:</span>
            <strong>{{ vlPago | toCurrencyToLocaleString }}</strong>
        </div>

        <div class="categoria__column">
            <span>Total:</span>
            <strong>{{ vlTotal | toCurrencyToLocaleString }}</strong>
        </div>

        <div class="categoria__column">
            <span>Porcentagem:</span>
            <strong :class="getColor">{{ nrPorcentagem | toPercent }}</strong>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        dsNome: {
            type: String,
            required: true,
        },
        vlTotal: {
            type: Number,
            required: true,
        },
        vlPago: {
            type: Number,
            required: true,
        },
        nrPorcentagem: {
            type: Number,
            required: true,
        },
    },
    computed: {
        getDsStatus() {
            return this.dsNome === "Receitas" ? "Recebido" : "Pago";
        },
        getColor() {
            const block = "categoria__column";
            if (this.nrPorcentagem <= 1) return `${block}--green`;
            return `${block}--red`;
        },
    },
};
</script>

<style lang="scss" scoped>
.categoria__header {
    $espacamento: 64px;
    display: grid;
    grid-template-columns: 1fr 155px 130px 155px;
    align-items: center;
    column-gap: $espacamento;
    padding-right: $espacamento;

    .categoria__column {
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
            display: flex;
            justify-content: space-between;
        }

        &--green {
            color: #0b8043;
        }
        &--red {
            color: #cc0000;
        }
    }
}
</style>
