import Vue from "vue";

import { mainRouter } from "@module/Main";

export default function registerRoutes(newRoutes = []) {
    for (const route of newRoutes) {
        mainRouter.addRoute(route);
    }
    Vue.prototype.$routesApp = [...Vue.prototype.$routesApp, ...newRoutes];
}
