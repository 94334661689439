<template>
    <BaseSheet
        width="100%"
        height="100%"
        control-show-hidden
        class="d-flex align-center justify-space-between flex-column pa-4"
    >
        <h3>Entradas X Saídas por Mês</h3>

        <template v-if="isEmpty">
            <LineChartGenerator
                ref="lineChart"
                :chart-options="chartOptions"
                :chart-data="chartData"
                :chart-id="chartId"
                :dataset-id-key="datasetIdKey"
                :plugins="plugins"
                :css-classes="cssClasses"
                :styles="styles"
                :width="width"
                :height="height"
            />
        </template>

        <template v-else>
            <div
                class="d-flex align-center"
                style="height: 100%;"
            >
                Sem dados para exibir
            </div>
        </template>
    </BaseSheet>
</template>

<script>
import { Line as LineChartGenerator } from "vue-chartjs/legacy";

import { cleanCurrency, toCurrencyToLocaleString } from "@helpers";

import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement,
} from "chart.js";

ChartJS.register(
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement,
);

const footer = (tooltipItems) => {
    let sum = 0;
    tooltipItems.forEach((tooltipItem) => {
        sum += tooltipItem.parsed.y;
    });
    return `Sobra: ${toCurrencyToLocaleString(sum)}`;
};

export default {
    components: {
        LineChartGenerator,
    },
    props: {
        chartId: {
            type: String,
            default: "line-chart",
        },
        datasetIdKey: {
            type: String,
            default: "label",
        },
        width: {
            type: Number,
            default: 400,
        },
        height: {
            type: Number,
            default: 400,
        },
        cssClasses: {
            default: "",
            type: String,
        },
        styles: {
            type: Object,
            default: () => ({}),
        },
        plugins: {
            type: Object,
            default: () => ({}),
        },
        data: {
            type: Array,
            default: () => [],
        },
        labels: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            chartOptions: {
                interaction: {
                    intersect: false,
                    mode: "index",
                },
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    y: {
                        ticks: {
                            callback(value) {
                                return toCurrencyToLocaleString(value);
                            },
                        },
                    },
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            label(t) {
                                const rawValue = Number(cleanCurrency(t.formattedValue));
                                const valueFormatted = toCurrencyToLocaleString(rawValue);
                                return `${t.dataset.label}: ${valueFormatted}`;
                            },
                            footer,
                        },
                    },
                },
            },
        };
    },
    computed: {
        chartData() {
            return {
                labels: this.labels,
                datasets: this.data,
            };
        },
        isEmpty() {
            return this.data && this.data.length > 0;
        },
    },
};
</script>
