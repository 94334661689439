<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
    <div
        class="informacao"
        :class="getClasses"
    >
        <h4 class="informacao__subtitulo text--secondary">
            {{ dsNome }}
        </h4>
        <v-tooltip
            :disabled="!snTooltip"
            bottom
        >
            <template #activator="{ on, attrs }">
                <h4
                    class="informacao__subtitulo"
                    v-bind="attrs"
                    v-on="on"
                    @click="actionLink"
                >
                    <template v-if="snValorMonetario">
                        {{ dsValor | toCurrencyToLocaleString }}
                    </template>
                    <template v-else-if="snDataHora">
                        {{ dsValor | formatDateWithTime }}
                    </template>
                    <template v-else>
                        {{ dsValor }}
                    </template>
                </h4>
            </template>
            <span>{{ dsValor }}</span>
        </v-tooltip>
    </div>
</template>

<script>
export default {
    props: {
        dsNome: {
            type: String,
            required: true,
        },
        dsValor: {
            type: [String, Number],
            default: "",
        },
        snValorMonetario: {
            type: Boolean,
            default: false,
        },
        snDataHora: {
            type: Boolean,
            default: false,
        },
        snLink: {
            type: Boolean,
            default: false,
        },
        snTooltip: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        getClasses() {
            return {
                "informacao--link": this.snLink,
                "vdPrimary--text": this.snLink,
            };
        },
    },
    methods: {
        actionLink() {
            this.$emit("action-link");
        },
    },
};
</script>

<style lang="scss">
.informacao {
    text-align: left;
    font-weight: 500;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    align-items: center;
    justify-content: space-between;
    column-gap: 8px;

    &--link {
        .informacao__subtitulo {
            &:last-child {
                text-decoration: underline;
                cursor: pointer;
                transition: all .5s;

                &:hover {
                    opacity: .7;
                }
            }
        }
    }

    &__subtitulo {
        font-weight: 500;
        &:first-child {
            text-align: left;
            white-space: nowrap;
        }
        &:last-child {
            text-align: right;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}
</style>
