<template>
    <BaseSheet v-if="reserveEmergencyValue">
        <v-data-table
            :headers="headers"
            :items="data"
            multi-sort
            :items-per-page="data.length"
        >
            <template #[`item.valueEntry`]="{ item }">
                {{ item.valueEntry | toCurrencyToLocaleString | hiddenValue }}
            </template>

            <template #[`item.valueOutput`]="{ item }">
                {{ item.valueOutput | toCurrencyToLocaleString | hiddenValue }}
            </template>

            <template #[`item.currentBalance`]="{ item }">
                {{ item.currentBalance | toCurrencyToLocaleString | hiddenValue }}
            </template>

            <template #[`item.goal`]>
                {{ reserveEmergencyValue | toCurrencyToLocaleString | hiddenValue }}
            </template>

            <template #[`item.diff`]="{ item }">
                {{ item.diff | toCurrencyToLocaleString | hiddenValue }}
            </template>

            <template #[`item.percentGoal`]="{ item }">
                {{ `${item.percentGoal.toFixed(2)}%` | hiddenValue }}
            </template>
        </v-data-table>
    </BaseSheet>
</template>

<script>
import { mapGetters } from "vuex";

import { headersEvolutionTable } from "../../constants";

export default {
    props: {
        data: {
            type: Array,
            default: () => [],
        },
    },

    data() {
        return {
            headers: headersEvolutionTable,
        };
    },

    computed: {
        ...mapGetters({
            reserveEmergencyValue: "calculation/getReserveEmergencyValue",
        }),
    },

    async mounted() {
        try {
            this.$store.dispatch("handleLoading", true);
            await this.$store.dispatch("calculation/getAll");
            await this.$store.dispatch("reservaEmergencia/getAll");
            this.$store.dispatch("handleLoading");
        } catch (e) {
            console.error(e);
            this.$toast.error("Não foi buscar a lista!");
        }
    },

    methods: {
        async deleteItem(id) {
            this.$store.dispatch("handleLoading", true);
            await this.$store.dispatch("reservaEmergencia/deleteItem", id);
            this.$store.dispatch("handleLoading");
        },
    },
};
</script>
