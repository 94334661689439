<template>
    <v-container class="fatura">
        <BaseHeaderPage>
            <template #right>
                <div class="fatura__actions">
                    <v-btn
                        color="#2D8F8C"
                        dark
                        class="text-none"
                        @click="recalcularValoresFatura"
                    >
                        <v-icon left>
                            mdi-cash-sync
                        </v-icon>

                        Recalcular valores fatura
                    </v-btn>
                    <v-btn
                        color="vdPrimary"
                        dark
                        class="text-none"
                        @click="redirecionarNovaTransacao"
                    >
                        <v-icon left>
                            mdi-plus
                        </v-icon>

                        Criar transação
                    </v-btn>
                </div>
            </template>
        </BaseHeaderPage>

        <div class="fatura__main">
            <ContaBancariaFaturaSidebar :obj-fatura="objFaturaSelecionado" />

            <ContaBancariaFaturaMain
                ref="contaBancariaFaturaMain"
                v-model="objFaturaSelecionado"
                @abrir-modal="abrirModal"
            />
        </div>
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            objFaturaSelecionado: null,
        };
    },
    methods: {
        abrirModal(objTransacao) {
            if (this.$refs.contaBancariaFaturaModalSalvar) {
                this.$refs.contaBancariaFaturaModalSalvar.abrirModal(objTransacao);
            }
        },
        redirecionarNovaTransacao() {
            this.$router.push({ name: "conta-bancaria-nova-transacao" });
        },
        async recalcularValoresFatura() {
            try {
                const cd_fatura = this.objFaturaSelecionado?.cd_fatura;
                await this.$cartaoCredito.faturaHttpGateway.recalcularValores(cd_fatura);
                this.buscarTodasFaturas();
                const { objMes } = this.objFaturaSelecionado;
                const ds_mes = `${objMes.ds_completo} - ${objMes.nr_mes_formatado}`;
                this.$toast.success(`Valores da fatura ${ds_mes} recalculados`);
            } catch (e) {
                console.error(e);
                this.$toast.error("Não foi possível recalcular os valores da fatura");
            }
        },
        buscarTransacoes() {
            if (this.$refs.contaBancariaFaturaMain) {
                const cd_fatura = this.objFaturaSelecionado?.cd_fatura;
                this.$refs.contaBancariaFaturaMain.atualizarListagemTransacoes(cd_fatura);
            }
        },
        buscarTodasFaturas() {
            if (this.$refs.contaBancariaFaturaMain) {
                this.$refs.contaBancariaFaturaMain.buscarTodasFaturas();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.fatura {
    $ref: &;
    display: grid;
    grid-template-rows: 95px 1fr;
    row-gap: 1rem;
    $header-height: 64px;
    $padding: 12px;
    $padding-y: $padding * 2;
    padding-top: 0;
    padding-bottom: 0;
    min-height: calc(100vh - #{$padding-y} - #{$header-height});

    &__actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        column-gap: 1rem;
        height: 100%;
    }

    &__main {
        display: grid;
        grid-template-columns: .34fr 1fr;
        column-gap: 1rem;
        $header-page: 96px;
        $row-gap: 16px;
        max-height: calc(
            100vh -
            #{$header-page} -
            #{$row-gap} -
            #{$padding-y} -
            #{$header-height}
        );
    }
}
</style>
