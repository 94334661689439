<template>
    <div class="migracao">
        <ConfiguracoesMigracaoGoogleSheetConfirmar
            v-model="sn_modal_aberto"
            :ds-pagina="ds_pagina"
            @migrar="callbackConfirmar"
        />

        <v-tabs
            v-if="anos.length > 0"
            id="tabsAnos"
            :value="getTabSelecionado"
        >
            <v-tab
                v-for="ano in anos"
                :key="ano"
                @click="anoSelecionado = ano"
            >
                {{ ano }}
            </v-tab>
        </v-tabs>

        <template v-if="getPlanilhasAnoSelecionado.length > 0">
            <ConfiguracoesMigracaoGoogleSheetSecao
                v-for="objPlanilha of getPlanilhasAnoSelecionado"
                :key="objPlanilha.cd_controle_migracao_planilha"
                :ds-titulo="objPlanilha.ds_nome"
                :arr-planilhas-migradas="objPlanilha.arrPlanilhasMigradas"
                :cd-planilha="objPlanilha.cd_controle_migracao_planilha"
                :ds-nome-planilha="objPlanilha.ds_nome"
                @confirmar="confirmar"
            />
        </template>
    </div>
</template>

<script>
import dayjs from "dayjs";
import { mapGetters } from "vuex";

export default {
    data() {
        return {
            arrPlanilhasMigradas: [],
            arrPlanilhasAgrupadoAno: [],
            arrPlanilhasAnoAtual: [],
            sn_modal_aberto: false,
            ds_pagina: "",
            anos: [],
            anoSelecionado: -1,
            callbackConfirmar: () => {},
        };
    },
    computed: {
        ...mapGetters({
            arrPlanilhas: "planilhas/getArrPlanilhas",
        }),
        getAnoSelecionado() {
            return this.anoSelecionado || 2024;
        },
        getPlanilhasAnoSelecionado() {
            const ano = this.getAnoSelecionado;
            return this.arrPlanilhasAgrupadoAno[ano] || [];
        },
        getTabSelecionado() {
            const ano = this.getAnoSelecionado;
            return this.anos.indexOf(ano);
        },
    },
    async mounted() {
        this.anoSelecionado = dayjs().year();
        await this.$store.dispatch("planilhas/fetchAll");
        await this.$store.dispatch("paginas/fetchAll");
        const arrPlanilhasMigradasResponse = await this.controleMigracaoHttpGateway().getAll();
        this.arrPlanilhasMigradas = arrPlanilhasMigradasResponse;
        this.getArrPlanilhasAgrupadoAno();
    },
    methods: {
        getArrPlanilhasAgrupadoAno() {
            const arrPlanilhasAgrupadoAno = [];
            const anos = [];
            for (const objPlanilha of this.arrPlanilhas) {
                const [ds_nome, ds_ano] = objPlanilha.ds_nome.split("-");
                const ano = +ds_ano.trim();
                if (!anos.includes(ano)) {
                    anos.push(ano);
                }
                if (arrPlanilhasAgrupadoAno[ano] === undefined) {
                    arrPlanilhasAgrupadoAno[ano] = [];
                }
                const arrPlanilhasMigradas = this.arrPlanilhasMigradas.filter(
                    (objPagina) => objPagina.cd_controle_migracao_planilha
                    === objPlanilha.cd_controle_migracao_planilha,
                );
                arrPlanilhasAgrupadoAno[ano].push({
                    ...objPlanilha,
                    ds_nome,
                    arrPlanilhasMigradas,
                });
            }
            this.arrPlanilhasAgrupadoAno = arrPlanilhasAgrupadoAno;
            this.anos = anos;
        },
        controleMigracaoHttpGateway() {
            return this.$configuracoes.controleMigracaoHttpGateway;
        },
        confirmar(ds_pagina, callbackConfirmar) {
            this.sn_modal_aberto = true;
            this.ds_pagina = ds_pagina;
            this.callbackConfirmar = callbackConfirmar;
        },
    },
};
</script>

<style lang="scss" scoped>
.migracao {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
}

#tabsAnos::v-deep .v-tabs-bar__content {
    border-bottom: 1px solid rgba(128, 128, 128, 0.216);
}
</style>
