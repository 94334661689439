/* eslint-disable class-methods-use-this */
import { Timestamp } from "firebase/firestore/lite";

import {
    formatDate,
    toCurrencyToLocaleString,
} from "@helpers";

// eslint-disable-next-line import/no-unresolved
import { actionColors, banks } from "@constants/ReservaEmergencia";

import HistoryDTO from "./HistoryDTO";
import HistoryModel from "./HistoryModel";

export default class HistoryAdapter {
    static getActionColor(actionName) {
        return actionColors[actionName];
    }

    static getLogoBank(bankName) {
        const result = banks.filter((bank) => bank.name === bankName);
        return result[0].logo;
    }

    static getActionName(actionName, value) {
        if (actionName) return actionName;
        return Number(value) > 0 ? "Adicionado" : "Retirado";
    }

    static getDate(date) {
        const [day, month, year] = date.split("/");
        const dateInstance = new Date(`${year}-${month}-${day}T00:00:00`);
        const formatFirebase = Timestamp.fromDate(dateInstance);
        return formatFirebase;
    }

    dtoToModel(value = new HistoryDTO()) {
        const historyModel = new HistoryModel();
        historyModel.id = value.id;
        historyModel.value = toCurrencyToLocaleString(Number(value.value));
        historyModel.description = value.description;
        historyModel.observation = value.observation || "-";
        historyModel.date = formatDate(value.date.toDate());
        historyModel.actionName = value.actionName;
        historyModel.actionColor = HistoryAdapter.getActionColor(value.actionName);
        historyModel.bankName = value.bankName;
        historyModel.logoBank = HistoryAdapter.getLogoBank(value.bankName);
        return historyModel;
    }

    modelToDTO(value = new HistoryModel()) {
        const historyDTO = new HistoryDTO();
        historyDTO.id = value.id;
        historyDTO.date = HistoryAdapter.getDate(value.date);
        historyDTO.bankName = value.bankName;
        historyDTO.description = value.description;
        if (value?.observation) {
            historyDTO.observation = value?.observation === "-" ? "" : value.observation;
        } else {
            historyDTO.observation = "";
        }
        historyDTO.value = Number(value.value);
        historyDTO.actionName = HistoryAdapter.getActionName(value.actionName, value.value);
        return historyDTO;
    }
}
