import FirebaseAdapter from "@module/Main/config/FirebaseAdapter";
import AxiosAdapter from "@module/Main/config/AxiosAdapter";

import { CalculationGateway, HistoryGateway } from "@api/ReservaEmergencia";
import { AuthHttpGateway } from "@api/Auth";
import { LancamentosHttpGateway, RangeSemanasHttpGateway } from "@api/ControleDiario";
import { ControleMensalLancamentosHttpGateway } from "@api/ControleMensal";
import cartaoCreditoFactory, { CartaoCreditoHttpGateway } from "@api/CartaoCredito";

import { CuboMagicoHttpGateway } from "@api/CuboMagico";

import {
    EstudosHttpGateway,
    EstudosObjetivosHttpGateway,
    EstudosAnotacoesHttpGateway,
    EstudosAulasHttpGateway,
    EstudosCursosHttpGateway,
    EstudosTopicosHttpGateway,
} from "@api/Estudos";

import {
    CofrinhosHttpGateway,
    BancosHttpGateway,
    CofrinhosHistoricosHttpGateway,
} from "@api/Cofrinhos";

import configuracoesFactory from "@api/Configuracoes";

import { LeagueOfLegendsHttpGateway } from "@api/LeagueOfLegends";

import { getFirestore } from "firebase/firestore/lite";
import firebaseInit from "./initFirebase";

export default function gatewaysFactory() {
    const firestore = getFirestore(firebaseInit);
    const httpClient = new FirebaseAdapter(firestore);
    const historyHttpGateway = new HistoryGateway.HistoryHttpGateway(httpClient);
    const calculationHttpGateway = new CalculationGateway.CalculationHttpGateway(httpClient);
    const authHttpGateway = new AuthHttpGateway(firebaseInit);

    const httpClientApi = new AxiosAdapter({
        baseURL: import.meta.env.VITE_BASE_URL_API,
    });
    const lancamentosHttpGateway = new LancamentosHttpGateway(httpClientApi);
    const rangeSemanasHttpGateway = new RangeSemanasHttpGateway(httpClientApi);
    const controleMensalLancamentosHttpGateway = new ControleMensalLancamentosHttpGateway(
        httpClientApi,
    );
    const configuracoes = configuracoesFactory(httpClientApi);
    const cartaoCredito = cartaoCreditoFactory(httpClientApi);
    const cartaoCreditoHttpGateway = new CartaoCreditoHttpGateway(httpClientApi);

    const cuboMagicoHttpGateway = new CuboMagicoHttpGateway(httpClientApi);

    const estudosHttpGateway = new EstudosHttpGateway(httpClientApi);
    const estudosObjetivosHttpGateway = new EstudosObjetivosHttpGateway(httpClientApi);
    const estudosAnotacoesHttpGateway = new EstudosAnotacoesHttpGateway(httpClientApi);
    const estudosAulasHttpGateway = new EstudosAulasHttpGateway(httpClientApi);
    const estudosCursosHttpGateway = new EstudosCursosHttpGateway(httpClientApi);
    const estudosTopicosHttpGateway = new EstudosTopicosHttpGateway(httpClientApi);

    const cofrinhosHttpGateway = new CofrinhosHttpGateway(httpClientApi);
    const bancosHttpGateway = new BancosHttpGateway(httpClientApi);
    const cofrinhosHistoricosHttpGateway = new CofrinhosHistoricosHttpGateway(httpClientApi);

    const leagueOfLegendsHttpGateway = new LeagueOfLegendsHttpGateway(httpClientApi);

    return {
        leagueOfLegendsHttpGateway,
        cofrinhosHistoricosHttpGateway,
        bancosHttpGateway,
        cofrinhosHttpGateway,
        estudosHttpGateway,
        estudosObjetivosHttpGateway,
        estudosAnotacoesHttpGateway,
        estudosAulasHttpGateway,
        estudosCursosHttpGateway,
        estudosTopicosHttpGateway,
        cuboMagicoHttpGateway,
        cartaoCreditoHttpGateway,
        cartaoCredito,
        configuracoes,
        controleMensalLancamentosHttpGateway,
        rangeSemanasHttpGateway,
        lancamentosHttpGateway,
        historyHttpGateway,
        calculationHttpGateway,
        authHttpGateway,
        httpClient,
    };
}
