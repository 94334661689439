import {
    HistoryView,
    DashboardView,
    EvolutionView,
    CalculationView,
} from "@views/ReservaEmergencia";
import TheDefaultVue from "../Main/layouts/TheDefault.vue";

export default [
    {
        path: "/reserva-emergencia",
        name: "emergency-reserve",
        component: TheDefaultVue,
        meta: {
            icon: "mdi-piggy-bank",
            viewMenu: true,
            title: "Reserva de emergência",
        },
        redirect() {
            return { name: "emergency-reserve-dashboard" };
        },
        children: [
            {
                path: "",
                name: "emergency-reserve-dashboard",
                component: DashboardView,
                meta: {
                    icon: "mdi-view-dashboard",
                    viewMenu: true,
                    title: "Dashboard",
                },
            },
            {
                path: "historico",
                name: "emergency-reserve-history",
                component: HistoryView,
                meta: {
                    icon: "mdi-history",
                    viewMenu: true,
                    title: "Histórico",
                },
            },
            {
                path: "evolucao",
                name: "emergency-reserve-evolution",
                component: EvolutionView,
                meta: {
                    icon: "mdi-trending-up",
                    viewMenu: true,
                    title: "Evolução",
                },
            },
            {
                path: "calculo",
                name: "emergency-reserve-calculation",
                component: CalculationView,
                meta: {
                    icon: "mdi-calculator-variant",
                    viewMenu: true,
                    title: "Cálculo",
                },
            },
        ],
    },
];
