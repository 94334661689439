/* eslint-disable max-len */
import * as views from "./views";
import TheDefaultVue from "../Main/layouts/TheDefault.vue";
import TheView from "../Main/components/layout/TheView.vue";

export default [
    {
        path: "/cartao-credito",
        name: "cartao-credito-main",
        component: TheDefaultVue,
        meta: {
            icon: "mdi-credit-card-multiple",
            viewMenu: true,
            title: "Cartões de Crédito",
        },
        redirect() {
            return { name: "conta-bancaria" };
        },
        children: [
            {
                path: "conta-bancaria",
                component: TheView,
                meta: {
                    icon: "mdi-bank",
                    viewMenu: false,
                    title: "Contas Bancárias",
                },
                children: [
                    {
                        path: "",
                        component: TheView,
                        children: [
                            {
                                path: "",
                                name: "conta-bancaria",
                                component: views.ContaBancariaView,
                                meta: {
                                    icon: "mdi-bank",
                                    viewMenu: true,
                                    title: "Contas Bancárias",
                                },
                            },
                            {
                                path: ":cd_conta_bancaria",
                                component: TheView,
                                children: [
                                    {
                                        path: "faturas",
                                        component: TheView,
                                        meta: {
                                            icon: "mdi-receipt-text",
                                            viewMenu: false,
                                            title: "Faturas",
                                        },
                                        children: [
                                            {
                                                path: "",
                                                name: "conta-bancaria-faturas",
                                                component: views.ContaBancariaFaturaView,
                                                meta: {
                                                    icon: "mdi-receipt-text",
                                                    viewMenu: false,
                                                    title: "Faturas",
                                                },
                                            },
                                            {
                                                path: "nova-transacao",
                                                name: "conta-bancaria-nova-transacao",
                                                component: views.ContaBancariaNovaTransacaoView,
                                                meta: {
                                                    icon: "mdi-receipt-text",
                                                    viewMenu: false,
                                                    title: "Nova Transação",
                                                },
                                            },
                                            {
                                                path: "editar-transacao",
                                                component: TheView,
                                                children: [
                                                    {
                                                        path: ":cd_transacao",
                                                        name: "conta-bancaria-editar-transacao",
                                                        component: views.ContaBancariaNovaTransacaoView,
                                                        meta: {
                                                            icon: "mdi-receipt-text",
                                                            viewMenu: false,
                                                            title: "Editar Transação",
                                                        },
                                                    },
                                                ],
                                            },
                                        ],
                                    },
                                    {
                                        path: "cartoes",
                                        component: views.ContaBancariaCartoesView,
                                        name: "conta-bancaria-cartoes",
                                        meta: {
                                            icon: "mdi-credit-card-multiple",
                                            viewMenu: false,
                                            title: "Cartões",
                                        },
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
];
