<template>
    <v-menu
        ref="menu"
        v-model="sn_aberto"
        :close-on-content-click="false"
        :nudge-right="40"
        :return-value.sync="vModel"
        transition="scale-transition"
        color="vdPrimary"
        offset-y
        :disabled="disabled"
        v-bind="$attrs"
        max-width="290px"
        min-width="290px"
    >
        <template #activator="{ on, attrs }">
            <BaseTextField
                v-model="vModel"
                :label="`${$attrs.label ?? 'Hora'} ${getSufixo}`"
                readonly
                :validation-empty="validationEmpty"
                :rules="[validationIsMore]"
                outlined
                v-bind="attrs"
                :disabled="disabled"
                v-on="on"
            />
        </template>
        <v-time-picker
            v-if="sn_aberto"
            ref="timePicker"
            v-model="vModel"
            full-width
            format="24hr"
            :use-seconds="true"
            @click:hour="clickInHour"
            @click:minute="clickInMinute"
        >
            <div class="d-flex justify-space-between w-100">
                <v-btn
                    icon
                    color="vdPrimary"
                    @click="decrementar"
                >
                    <v-icon>mdi-minus</v-icon>
                </v-btn>
                <v-chip
                    class="mx-2"
                    @click="incrementarTempoDiario"
                >
                    {{ tempoDiarioFormatado }}
                </v-chip>
                <v-btn
                    icon
                    color="vdPrimary"
                    @click="incrementar"
                >
                    <v-icon>mdi-plus</v-icon>
                </v-btn>
            </div>
            <BaseButton
                color="blue darken-1"
                text
                @click="$refs.menu.save(vModel)"
            >
                Salvar
            </BaseButton>
        </v-time-picker>
    </v-menu>
</template>

<script>
import dayjs from "dayjs";

export default {
    props: {
        value: {
            type: String,
            default: "",
        },
        validationEmpty: {
            type: Boolean,
            default: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        tempoDiarioFormatado: {
            type: String,
            default: "",
        },
        tempoDiarioSegundos: {
            type: Number,
            default: 0,
        },
        nrTempoAssistido: {
            type: Number,
            default: 0,
        },
        nrTempoTotal: {
            type: Number,
            default: 0,
        },
    },

    data() {
        return {
            sn_aberto: false,
            time: null,
            hour: true,
            minute: false,
            second: false,
            isInit: true,
        };
    },

    computed: {
        vModel: {
            get() {
                return this.value;
            },
            set(ds_valor) {
                this.$emit("input", ds_valor);
            },
        },
        getSufixo() {
            return this.validationEmpty === true ? "*" : "";
        },
    },
    watch: {
        sn_aberto(newValue) {
            if (newValue && this.isInit) {
                this.isInit = false;
                this.$nextTick(() => {
                    if (this.$refs.timePicker) {
                        const elements = this.$refs.timePicker.$el.querySelectorAll(".v-picker__title__btn");
                        elements.forEach((element, index) => {
                            element.addEventListener("click", () => this.updateActivePicker(index));
                        });
                    }
                });
            }
        },
    },
    methods: {
        validationIsMore() {
            const nr_tempo_atual = this.timeStringToSeconds(this.vModel);
            const nr_tempo_faltante = this.nrTempoTotal - this.nrTempoAssistido;
            const nr_tempo_faltante_formatado = this.formatTime(nr_tempo_faltante);
            return nr_tempo_atual <= nr_tempo_faltante || `Tempo assistido excedido do tempo faltante (${nr_tempo_faltante_formatado})`;
        },
        incrementarTempoDiario() {
            const nr_tempo_diario = this.tempoDiarioSegundos;
            const nr_tempo = this.timeStringToSeconds(this.vModel);
            const nr_tempo_incrementado = nr_tempo + nr_tempo_diario;
            this.vModel = this.formatTime(nr_tempo_incrementado);
        },
        clickInHour() {
            this.updateActivePicker(1);
        },
        clickInMinute() {
            this.updateActivePicker(2);
        },
        updateActivePicker(index) {
            this.hour = index === 0;
            this.minute = index === 1;
            this.second = index === 2;
        },
        timeStringToSeconds(timeString) {
            // Verifica se o formato da string é HH:mm:ss
            const regex = /^(\d{2}):(\d{2}):(\d{2})$/;
            const match = timeString.match(regex);
            if (!match) {
                throw new Error("Formato de string inválido. Utilize HH:mm:ss");
            }
            // Extrai horas, minutos e segundos da string
            const [, hours, minutes, seconds] = match;
            // Converte horas, minutos e segundos para segundos
            // eslint-disable-next-line max-len
            const totalSeconds = parseInt(hours, 10) * 3600 + parseInt(minutes, 10) * 60 + parseInt(seconds, 10);
            return totalSeconds;
        },
        adjustTime(currentTimeInSeconds, unit, operation = "increment") {
            let adjustmentInSeconds;

            switch (unit) {
            case "hours":
                adjustmentInSeconds = 3600; // 1 hora em segundos
                break;
            case "minutes":
                adjustmentInSeconds = 60; // 1 minuto em segundos
                break;
            case "seconds":
                adjustmentInSeconds = 1; // 1 segundo
                break;
            default:
                throw new Error("Invalid unit. Use \"hours\", \"minutes\", or \"seconds\".");
            }

            if (operation === "increment") {
                return currentTimeInSeconds + adjustmentInSeconds;
            } if (operation === "decrement") {
                // Garante que o tempo não será negativo
                return Math.max(0, currentTimeInSeconds - adjustmentInSeconds);
            }
            throw new Error("Invalid operation. Use \"increment\" or \"decrement\".");
        },
        formatTime(secondsInput, withDots = true) {
            const durationDayjs = dayjs.duration(secondsInput, "seconds");
            const hours = String(Math.floor(durationDayjs.asHours())).padStart(2, "0");
            const minutes = String(durationDayjs.minutes()).padStart(2, "0");
            const seconds = String(durationDayjs.seconds()).padStart(2, "0");
            let result = `${hours}h ${minutes}m ${seconds}s`;
            if (withDots) {
                result = `${hours}:${minutes}:${seconds}`;
            }
            return result;
        },
        handleAction(isIncrement = true) {
            const nr_tempo_segundos = this.timeStringToSeconds(this.vModel);
            let unit = "hours";
            if (this.minute) {
                unit = "minutes";
            }
            if (this.second) {
                unit = "seconds";
            }
            const operation = isIncrement ? "increment" : "decrement";
            const nr_tempo_decrementado = this.adjustTime(nr_tempo_segundos, unit, operation);
            this.vModel = this.formatTime(nr_tempo_decrementado);
        },
        decrementar() {
            this.handleAction(false);
        },
        incrementar() {
            this.handleAction();
        },
    },
};
</script>

<style lang="scss" scoped></style>
