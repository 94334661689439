<template>
    <v-container>
        <BaseHeaderPage />

        <v-row class="mt-4">
            <v-col cols="3">
                <CofrinhosNovo
                    ref="cofrinhosNovo"
                    @atualizar-listagem="buscarCofrinhos"
                />
            </v-col>

            <v-col
                v-for="objCofrinho in arrCofrinhos"
                :key="objCofrinho.cd_cofrinho"
                cols="3"
            >
                <Cofrinhos
                    :ds-nome="objCofrinho.ds_nome"
                    :ds-imagem="objCofrinho.ds_imagem"
                    :ds-banco="objCofrinho.CofrinhosBancos.ds_nome"
                    @click="redirectToHistorico(objCofrinho.cd_cofrinho)"
                />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
export default {
    data() {
        return {
            arrCofrinhos: [],
            sn_atualizando_status: false,
        };
    },

    async mounted() {
        await this.buscarCofrinhos();
    },

    methods: {
        redirectToHistorico(cd_cofrinho) {
            this.$router.push({ name: "cofrinhos-historico", params: { cd_cofrinho } });
        },
        async buscarCofrinhos() {
            const arrCofrinhos = await this.$cofrinhosHttpGateway.getAll();
            this.arrCofrinhos = arrCofrinhos;
        },
        abrirModal(objPayload) {
            if (this.$refs.cofrinhosNovo) {
                this.$refs.cofrinhosNovo.abrirModal(objPayload);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
</style>
