<template>
    <BaseSheet
        v-if="getDsMes"
        class="fatura__sheet"
    >
        <ContaBancariaFaturaTitulo
            v-if="getDsMes"
            ds-icone="receipt-text"
            :ds-nome="getDsMes"
        />
        <ContaBancariaFaturaInformacao
            v-if="getDsStatus"
            ds-nome="Status"
            :ds-valor="getDsStatus"
        />
        <ContaBancariaFaturaInformacao
            ds-nome="Fatura atual"
            :ds-valor="getVlGasto"
            sn-valor-monetario
        />
        <ContaBancariaFaturaInformacao
            ds-nome="Antecipado"
            :ds-valor="getVlAntecipado"
            sn-valor-monetario
        />
        <ContaBancariaFaturaInformacao
            ds-nome="A pagar"
            :ds-valor="getVlPagar"
            sn-valor-monetario
        />
        <template v-if="getCdFaturaStatus === 1">
            <ContaBancariaFaturaInformacao
                ds-nome="Fecha em"
                :ds-valor="getDsFechamento"
            />
            <ContaBancariaFaturaInformacao
                ds-nome="Vence em"
                :ds-valor="getDsVencimento"
            />
        </template>
    </BaseSheet>
</template>

<script>
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/pt-br";
import numeroMesNomeEnum from "../../constants/contaBancariaFatura/numeroMesNomeEnum";

dayjs.locale("pt-br");
dayjs.extend(relativeTime);

export default {
    props: {
        objFatura: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        getVlPagar() {
            let vl_antecipado = 0;
            let vl_gasto = 0;
            if (this.objFatura) {
                const vl_gasto_formatado = this.objFatura?.vl_gasto
                    ? +this.objFatura.vl_gasto
                    : 0;
                const vl_estorno_formatado = this.objFatura?.vl_estorno
                    ? +this.objFatura.vl_estorno
                    : 0;
                vl_antecipado = this.objFatura?.vl_antecipado;
                vl_gasto = vl_gasto_formatado + vl_estorno_formatado;
            }
            return Math.abs(vl_antecipado - vl_gasto);
        },
        getDsMes() {
            if (!this.objFatura?.nr_mes) return null;
            return this.objFatura?.objMes?.ds_mes_ano;
        },
        getVlAntecipado() {
            return Number(this.objFatura?.vl_antecipado) || 0;
        },
        getVlGasto() {
            return Number(this.objFatura?.vl_gasto) || 0;
        },
        getCdFaturaStatus() {
            return this.objFatura?.cd_fatura_status;
        },
        getDsStatus() {
            return this.objFatura?.FaturaStatus?.ds_status || null;
        },
        getDtVencimento() {
            if (!this.objFatura?.dt_vencimento) return null;
            const dt_vencimento = this.objFatura?.dt_vencimento?.replace("0Z", "");
            return dayjs(dt_vencimento);
        },
        getDtFechamento() {
            if (!this.objFatura?.dt_fechamento) return null;
            const dt_fechamento = this.objFatura?.dt_fechamento?.replace("0Z", "");
            return dayjs(dt_fechamento);
        },
        getNrDiasFechamento() {
            if (!this.getDtFechamento) return "";
            return this.getDtFechamento.toNow(true);
        },
        getNrDiasVencimento() {
            if (!this.getDtVencimento) return "";
            return this.getDtVencimento.toNow(true);
        },
        getNrDiaFechamento() {
            if (!this.getDtFechamento) return "";
            return this.$dayjs(this.getDtFechamento).format("DD/MM");
        },
        getNrDiaVencimento() {
            if (!this.getDtVencimento) return "";
            return this.$dayjs(this.getDtVencimento).format("DD/MM");
        },
        getDsFechamento() {
            return `${this.getNrDiasFechamento} (${this.getNrDiaFechamento})`;
        },
        getDsVencimento() {
            return `${this.getNrDiasVencimento} (${this.getNrDiaVencimento})`;
        },
    },
};
</script>
