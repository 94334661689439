export default class CuboMagicoHttpGateway {
    httpClient;

    basePath = "/cubo-magico";

    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    async importacao(body = {}) {
        const { data } = await this.httpClient.post(`${this.basePath}/cstimer/importacao`, body);
        return data;
    }

    async getByData(dt_data) {
        const { data } = await this.httpClient.get(`${this.basePath}/sessions?dt_data=${dt_data}`);
        return data;
    }

    async dashboard(dt_data) {
        const { data } = await this.httpClient.get(`${this.basePath}/dashboard`);
        return data;
    }
}
