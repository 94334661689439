<template>
    <div class="history">
        <BaseHeaderPage>
            <template #right>
                <HistoryHeaderButtons
                    :new-modal="newModal"
                    :view-filters="viewFilters"
                    @updateNewModalIsOpen="updateNewModalIsOpen"
                    @updateViewFilters="updateViewFilters"
                />
            </template>
        </BaseHeaderPage>

        <div>
            <HistoryFilters :view-filters="viewFilters" />
            <HistoryTable @openDialog="openDialog" />
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            newModal: {
                isOpen: false,
                actionName: "Novo",
                data: null,
            },
            viewFilters: false,
        };
    },

    methods: {
        openDialog(item) {
            this.newModal = {
                actionName: "Editar",
                isOpen: true,
                data: item,
            };
        },
        updateNewModalIsOpen(value) {
            if (value) {
                this.newModal.isOpen = value;
            } else {
                this.newModal = {
                    isOpen: value,
                    actionName: "Novo",
                    data: null,
                };
            }
        },
        updateViewFilters() {
            this.viewFilters = !this.viewFilters;
        },
    },
};
</script>

<style lang="scss" scoped>
.history {
    height: 100%;
    display: grid;
    grid-template-rows: 95px 1fr;
    row-gap: 0.75rem;
}
</style>
