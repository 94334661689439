import dayjs from "dayjs";
import getSnRangeInicioMesFechamentoFatura from "./getSnRangeInicioMesFechamentoFatura";

export default {
    methods: {
        getMesFatura() {
            const objDayjs = dayjs().clone();
            const nr_dia = objDayjs.get("date");
            const sn_range_inicio_mes_fechamento_fatura = getSnRangeInicioMesFechamentoFatura(
                nr_dia,
            );
            /**
             * o mês do dayjs começa apartir do 0
             * 1 = pegar o mês atual
             * 2 = pegar próximo mes
             */
            let nr_meses_incrementar = 1;
            if (!sn_range_inicio_mes_fechamento_fatura) {
                nr_meses_incrementar = 2;
            }
            const nr_mes = objDayjs.get("month");
            return nr_mes + nr_meses_incrementar;
        },
    },
};
