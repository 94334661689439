<template>
    <header>
        <v-row :no-gutters="noGutters">
            <v-col>
                <div class="d-flex">
                    <v-icon
                        v-if="viewIconTitle"
                        color="black"
                        size="2rem"
                        class="mr-3"
                    >
                        {{ getIcon }}
                    </v-icon>

                    <h1>{{ titlePage || getTitle }}</h1>
                </div>

                <v-breadcrumbs
                    v-if="viewBreadcrumb"
                    class="px-0 pt-0 pb-1"
                    :items="getItems"
                >
                    <template #divider>
                        <v-icon>mdi-chevron-right</v-icon>
                    </template>
                </v-breadcrumbs>

                <h3
                    v-if="viewCurrentDate"
                    class="subtitle-2 text--secondary"
                >
                    {{ getCurrentDate | formatDate }}
                </h3>
            </v-col>

            <v-col v-if="$slots.right">
                <slot name="right" />
            </v-col>
        </v-row>
    </header>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
    props: {
        titlePage: {
            type: String,
            default: null,
        },
        viewBreadcrumb: {
            type: Boolean,
            default: true,
        },
        viewCurrentDate: {
            type: Boolean,
            default: true,
        },
        viewIconTitle: {
            type: Boolean,
            default: true,
        },
        noGutters: {
            type: Boolean,
            default: true,
        },
    },

    computed: {
        getTitle() {
            return this.$route.meta?.title;
        },

        getCurrentDate() {
            return new Date();
        },

        getItems() {
            const tmp = [];
            const { params, name: nameCurrentRoute, matched } = this.$route;
            let ds_path_rota_anterior = null;
            matched.forEach((objRota) => {
                if (Object.keys(objRota.meta).length === 0) return;
                if (ds_path_rota_anterior !== null && ds_path_rota_anterior.match(objRota.regex)) {
                    ds_path_rota_anterior = objRota.path;
                    return;
                }
                let ds_path = objRota.path;
                for (const key in params) {
                    if (Object.hasOwnProperty.call(params, key)) {
                        ds_path = ds_path.replace(`:${key}`, params[key]);
                    }
                }
                tmp.push({
                    text: objRota.meta?.title,
                    to: ds_path,
                    exact: true,
                    disabled: objRota.name === nameCurrentRoute,
                });
                ds_path_rota_anterior = objRota.path;
            });
            return tmp;
        },

        getIcon() {
            return this.$route.meta?.icon;
        },
    },
});
</script>
