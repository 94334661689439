function validations(valueClean, rawValue) {
    const isValueAfterPrefix = rawValue.match(/([0-9]R\$ )/);
    if (isValueAfterPrefix) {
        const valueWithoutFirst = valueClean.slice(1, valueClean.length - 1);
        return `${valueWithoutFirst}${valueClean[0]}`;
    }

    const isRemoveBeforeComma = valueClean.length <= 3;

    if (isRemoveBeforeComma) return valueClean.padStart(3, "0");
    const isBeforeComma = rawValue.match(/^([-]?R\$ )(0,.{2}[-]?)/);
    if (isBeforeComma) return valueClean.slice(1);
    return valueClean;
}

function getValueFormatted(value) {
    const withoutNegative = value.replace("-", "");

    const isHundred = withoutNegative.length <= 5;

    if (isHundred) return withoutNegative.replace(/(.+)(.{2})/, "R$ $1,$2");
    const isThousand = withoutNegative.length <= 8;
    if (isThousand) return withoutNegative.replace(/(.+)(.{3})(.{2})/, "R$ $1.$2,$3");
    const isMillion = withoutNegative.length <= 9;
    if (isMillion) return withoutNegative.replace(/(.{1})(.{3})(.{3})(.+)/, "R$ $1.$2.$3,$4");
    return value;
}

export function cleanCurrency(rawValue) {
    if (!rawValue && rawValue !== "R$ 0,00") return 0;
    const removePrefix = rawValue.replaceAll(/R\$\s/g, "");
    const removePoint = removePrefix.replaceAll(/\./g, "");
    const changeCommaToPoint = removePoint.replaceAll(/,/g, ".");
    return changeCommaToPoint;
}

export default function toCurrency(rawValue) {
    const rawValueToString = String(rawValue);
    const valueClean = rawValueToString.replaceAll(/[R$ ]|[,]|[.]|[-]/g, "");
    const newValue = validations(valueClean, rawValueToString);
    const valueFormatted = getValueFormatted(newValue);
    let newValue2 = valueFormatted;
    if (rawValueToString.match("-")) {
        newValue2 = `-${valueFormatted}`;
    }
    return newValue2;
}

export function toCurrencyToLocaleString(rawValue) {
    if (!rawValue && rawValue !== 0) return String(rawValue);
    const locale = "pt-BR";
    const options = {
        style: "currency",
        currency: "BRL",
    };
    return Number(rawValue).toLocaleString(locale, options);
}
