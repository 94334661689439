<!-- eslint-disable vue/html-self-closing -->
<template>
    <div style="width: 100%; height: 400px">
        <canvas
            :id="getChartId"
        />
    </div>
</template>

<script>
import ChartDataLabels from "chartjs-plugin-datalabels";

import { cleanCurrency, toCurrencyToLocaleString } from "@helpers";

import Chart from "chart.js/auto";

export default {
    props: {
        chartData: {
            type: [Object, Array],
            default: () => [],
            required: true,
        },
        chartId: {
            type: String,
            default: "",
            required: true,
        },
        chartOptions: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            chartJS: null,
        };
    },
    computed: {
        getChartId() {
            return `chart-bar-${this.chartId}`;
        },
        getChartData() {
            return this.chartData;
        },
        getChartOptions() {
            return {
                ...this.chartOptions,
                responsive: true,
                maintainAspectRatio: false,
                interaction: {
                    intersect: false,
                    mode: "index",
                },
                scales: {
                    ...this.chartOptions?.scales,
                    y: {
                        ticks: {
                            callback(value) {
                                return toCurrencyToLocaleString(value);
                            },
                        },
                        ...this.chartOptions?.scales?.y,
                    },
                },
                plugins: {
                    tooltip: {
                        callbacks: {
                            label(context) {
                                let label = context.dataset.label || "";
                                if (label) {
                                    label += ": ";
                                }
                                if (context.parsed.y !== null) {
                                    label += toCurrencyToLocaleString(context.parsed.y);
                                }
                                return label;
                            },
                            ...this.chartOptions?.plugins?.tooltip?.callbacks,
                        },
                    },
                    datalabels: {
                        ...this.chartOptions.plugins.datalabels,
                        color: "white",
                        formatter: toCurrencyToLocaleString,
                        display(context) {
                            const index = context.dataIndex;
                            const value = context.dataset.data[index];
                            return value !== 0;
                        },
                    },
                },
            };
        },
    },
    watch: {
        chartData(newData) {
            if (this.chartJS) {
                this.chartJS.data = newData;
                this.chartJS.update();
            }
        },
        chartOptions() {
            if (this.chartJS) {
                this.chartJS.options = this.getChartOptions;
                this.chartJS.update();
            }
        },
    },
    mounted() {
        const element = document.getElementById(this.getChartId);
        this.chartJS = this.createChart(element);
    },
    methods: {
        createChart(element) {
            if (element) {
                const config = {
                    type: "bar",
                    data: this.getChartData,
                    options: this.getChartOptions,
                    plugins: [ChartDataLabels],
                };
                return new Chart(element, config);
            }
            return null;
        },
    },
};
</script>
