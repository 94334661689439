export default class EstudosTopicosHttpGateway {
    httpClient;

    basePath = "/estudos/topicos";

    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    async create(payload) {
        const { data } = await this.httpClient.post(this.basePath, payload);
        return data;
    }
}
