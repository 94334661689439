import CalculationAdapter from "./CalculationAdapter";

export default class CalculationHttpGateway {
    httpClient;

    basePath = "reserva-emergencia-calculo";

    constructor(httpClient) {
        this.httpClient = httpClient;
    }

    static getPayload(body) {
        const calculationAdapter = new CalculationAdapter();
        const result = calculationAdapter.modelToDTO(body);
        return { ...result };
    }

    async update(body) {
        const payload = CalculationHttpGateway.getPayload(body);
        this.httpClient.put(this.basePath, payload.id, payload);
        return payload;
    }

    async getAll() {
        const response = await this.httpClient.get(this.basePath);
        const data = [];
        const calculationAdapter = new CalculationAdapter();
        for (const item of response) {
            data.push(calculationAdapter.dtoToModel(item));
        }
        return data;
    }

    async new(body, id) {
        const payload = CalculationHttpGateway.getPayload(body);
        this.httpClient.post(this.basePath, payload, id);
        return payload;
    }

    async delete(id) {
        this.httpClient.delete(this.basePath, id);
    }
}
