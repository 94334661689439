<template>
    <div class="configuracoes">
        <BaseHeaderPage />

        <BaseSheet>
            <v-tabs
                class="configuracoes__tabs"
                vertical
                color="vdPrimary"
            >
                <template v-for="(tab, index) in tabs">
                    <v-tab :key="`tab-${index}`">
                        <v-icon left>
                            mdi-{{ tab.icon }}
                        </v-icon>
                        {{ tab.text }}
                    </v-tab>

                    <v-tab-item
                        :key="`tab-item-${index}`"
                        class="pa-6"
                    >
                        <component :is="tab.component" />
                    </v-tab-item>
                </template>
            </v-tabs>
        </BaseSheet>
    </div>
</template>

<script>
import MigracaoGoogleSheet from "../components/ConfiguracoesMigracaoGoogleSheet/ConfiguracoesMigracaoGoogleSheet.vue";
import MigracaoGoogleSheetPlanilha from "../components/ConfiguracoesMigracaoGoogleSheetPlanilha/ConfiguracoesMigracaoGoogleSheetPlanilha.vue";
import MigracaoGoogleSheetPagina from "../components/ConfiguracoesMigracaoGoogleSheetPagina/ConfiguracoesMigracaoGoogleSheetPagina.vue";
import ConfiguracoesCategorias from "../components/ConfiguracoesCategorias/ConfiguracoesCategorias.vue";
import ConfiguracoesStatus from "../components/ConfiguracoesStatus/ConfiguracoesStatus.vue";

export default {
    components: {
        MigracaoGoogleSheet,
        MigracaoGoogleSheetPlanilha,
        MigracaoGoogleSheetPagina,
        ConfiguracoesCategorias,
        ConfiguracoesStatus,
    },

    data() {
        return {
            tabs: [
                {
                    text: "Status",
                    icon: "list-status",
                    component: "ConfiguracoesStatus",
                },
                {
                    text: "Categorias",
                    icon: "format-list-bulleted-type",
                    component: "ConfiguracoesCategorias",
                },
                {
                    text: "Migrar Dados Google Sheet Planilhas",
                    icon: "google-spreadsheet",
                    component: "MigracaoGoogleSheetPlanilha",
                },
                {
                    text: "Migrar Dados Google Sheet Páginas",
                    icon: "google-spreadsheet",
                    component: "MigracaoGoogleSheetPagina",
                },
                {
                    text: "Migrar Dados Google Sheet",
                    icon: "google-spreadsheet",
                    component: "MigracaoGoogleSheet",
                },
            ],
        };
    },
};
</script>

<style lang="scss" scoped>
.configuracoes {
  display: flex;
  flex-direction: column;
  row-gap: 1rem;

  &__tabs::v-deep {
    height: calc(100vh - 199px);
    > .v-tabs-bar {
      border-right: 1px solid #8080802e;
      width: auto;

      .v-tabs-slider-wrapper {
        left: unset !important;
        right: 0;
    }

      .v-tab {
        display: flex;
        justify-content: flex-start;
        text-align: left;
        text-transform: capitalize;
      }
    }

    > .v-window {
        overflow-y: auto;
    }
  }
}
</style>
