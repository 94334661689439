<template>
    <BaseTextField
        v-model.lazy="vModel"
        v-money="money"
        v-bind="$attrs"
        :rules="getRules"
        v-on="$listeners"
    />
</template>

<script>
import { VMoney } from "v-money";
import { cleanCurrency } from "@/modules/Main/helpers";

export default {
    directives: { money: VMoney },

    props: {
        value: {
            type: [Number, String],
            default: 0,
        },
        validationEmpty: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            money: {
                decimal: ",",
                thousands: ".",
                prefix: "R$ ",
                precision: 2,
            },
        };
    },

    computed: {
        vModel: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
        getRules() {
            let rules = [];
            if (this.$attrs.rules) {
                rules = [...rules, ...this.$attrs.rules];
            }
            if (this.validationEmpty) {
                rules.push((v) => {
                    if (Number(cleanCurrency(v)) === 0) return "O valor não pode ser zero";
                    return true;
                });
            }
            return rules;
        },
    },
};
</script>
