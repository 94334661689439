import CofrinhosView from "./views/CofrinhosView.vue";
import CofrinhosHistoricosView from "./views/CofrinhosHistoricosView.vue";
import TheDefaultVue from "../Main/layouts/TheDefault.vue";

export default [
    {
        path: "/cofrinhos",
        name: "cofrinhos",
        component: TheDefaultVue,
        meta: {
            icon: "mdi-hand-coin",
            viewMenu: true,
            title: "Cofrinhos",
        },
        redirect() {
            return { name: "cofrinhos-main" };
        },
        children: [
            {
                path: "",
                name: "cofrinhos-main",
                component: CofrinhosView,
                meta: {
                    icon: "mdi-hand-coin",
                    viewMenu: false,
                    title: "Cofrinhos",
                },
            },
            {
                path: ":cd_cofrinho/historico",
                name: "cofrinhos-historico",
                component: CofrinhosHistoricosView,
                meta: {
                    icon: "mdi-history",
                    viewMenu: false,
                    title: "Histórico",
                },
            },
        ],
    },
];
