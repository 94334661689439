<template>
    <BaseModal
        v-model="sn_modal_aberto"
        max-width="1200px"
    >
        <template #activator="{ on, attrs }">
            <v-icon
                v-bind="attrs"
                color="grey"
                style="cursor: pointer"
                v-on="on"
            >
                mdi-plus
            </v-icon>
        </template>

        <v-card>
            <BaseFormHeader
                ds-nome-tela="Registro"
                :ds-nome-acao="ds_nome_acao"
            />
            <v-form
                ref="estudosForm"
                v-model="valid"
                style="padding: 1.5rem"
                lazy-validation
            >
                <v-row>
                    <v-col>
                        <BaseTextField
                            v-model="payload.ds_nome"
                            label="Nome *"
                            validation-empty
                        />
                    </v-col>
                </v-row>
            </v-form>
            <BaseFormActions
                :sn-loading="sn_loading"
                @save="salvarAcao"
                @close="fecharModal"
            />
        </v-card>
    </BaseModal>
</template>

<script>
import { defineComponent } from "vue";

const defaultPayload = {
    ds_nome: "",
    cd_estudo_curso: -1,
};

export default defineComponent({
    data() {
        return {
            payload: { ...defaultPayload },
            valid: true,
            sn_loading: false,
            sn_modal_aberto: false,
            ds_nome_acao: "Criar",
        };
    },

    computed: {
        getSnEditar() {
            return this.ds_nome_acao === "Editar";
        },
    },

    methods: {
        // utilizado via ref
        abrirModal(objPayload = null) {
            if (objPayload) {
                this.ds_nome_acao = "Editar";
                this.payload = {
                    ...objPayload,
                };
            }
            this.sn_modal_aberto = true;
        },
        fecharModal() {
            this.sn_modal_aberto = false;
            this.ds_nome_acao = "Criar";
            this.resetData();
            this.resetValidation();
        },

        getFormIsValid() {
            return this.$refs.estudosForm.validate();
        },

        resetValidation() {
            if (this.$refs.estudosForm) {
                this.$refs.estudosForm.resetValidation();
            }
        },
        async salvarAcao() {
            const formIsValid = this.getFormIsValid();
            if (formIsValid) {
                try {
                    this.sn_loading = true;
                    const ds_nome_acao = "criado";
                    const { id } = this.$route.params;
                    await this.$estudosTopicosHttpGateway.create({
                        ...this.payload,
                        cd_estudo_curso: +id,
                    });
                    this.$emit("atualizar-listagem");
                    this.$toast.success(`Tópico ${ds_nome_acao} com sucesso!`);
                    this.sn_loading = false;
                    this.fecharModal();
                } catch (e) {
                    this.sn_loading = false;
                }
            }
        },

        resetData() {
            this.payload = { ...defaultPayload };
        },
    },
});
</script>
