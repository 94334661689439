<template>
    <BaseSheet class="fatura__conta-bancaria">
        <ContaBancariaFaturaTitulo
            v-if="objContaBancaria?.ds_nome"
            ds-icone="bank"
            :ds-nome="objContaBancaria.ds_nome"
        />
        <ContaBancariaFaturaInformacao
            ds-nome="Próximas fatura"
            :ds-valor="objContaBancaria.vl_proximas_faturas || 0"
            sn-valor-monetario
        />
        <ContaBancariaFaturaInformacao
            ds-nome="Limite disponível"
            :ds-valor="objContaBancaria.vl_disponivel"
            sn-valor-monetario
        />
        <div class="fatura__saldo">
            <div class="fatura__infos">
                <h5 class="font-weight-medium">
                    Saldo
                </h5>
                <h5 class="font-weight-medium">
                    <strong>
                        {{ objContaBancaria.vl_utilizado | toCurrencyToLocaleString }}
                    </strong>
                    /{{ objContaBancaria.vl_limite | toCurrencyToLocaleString }}
                </h5>
            </div>

            <v-progress-linear
                color="vdPrimary"
                :value="getNrPorcentagem"
                rounded
            />
        </div>
    </BaseSheet>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            objContaBancaria: {},
        };
    },

    computed: {
        getNrPorcentagem() {
            if (!this.objContaBancaria) return 0;
            const { vl_utilizado, vl_limite } = this.objContaBancaria;
            return (vl_utilizado / vl_limite) * 100;
        },
        vModel: {
            get() {
                return this.value;
            },
            set(ds_valor) {
                this.$emit("input", ds_valor);
            },
        },
    },

    mounted() {
        this.buscarContaBancaria();
    },

    methods: {
        async buscarContaBancaria() {
            const { cd_conta_bancaria } = this.$router.currentRoute.params;
            const objContaBancaria = await this.$cartaoCredito.contaBancariaHttpGateway.getById(
                cd_conta_bancaria,
            );
            this.objContaBancaria = objContaBancaria;
            this.vModel = { ...objContaBancaria };
        },
    },
};
</script>

<style lang="scss">
.fatura__conta-bancaria {
    padding: 16px 24px;

    .fatura__saldo {
        width: 100%;
        margin-top: 4px;
        display: flex;
        flex-direction: column;
        row-gap: 4px;

        .fatura__infos {
            display: flex;
            justify-content: space-between;
        }
    }
}
</style>
