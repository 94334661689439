import CategoriasHttpGateway from "./categoriasHttpGateway";
import MigracaoSheetsHttpGateway from "./migracaoSheetsHttpGateway";
import StatusHttpGateway from "./statusHttpGateway";
import ControleMigracaoHttpGateway from "./controleMigracaoHttpGateway";
import PlanilhasHttpGateway from "./planilhasHttpGateway";
import PaginasHttpGateway from "./paginasHttpGateway";

const configuracoesFactory = (httpClient) => ({
    categoriasHttpGateway: new CategoriasHttpGateway(httpClient),
    migracaoSheetsHttpGateway: new MigracaoSheetsHttpGateway(httpClient),
    statusHttpGateway: new StatusHttpGateway(httpClient),
    controleMigracaoHttpGateway: new ControleMigracaoHttpGateway(httpClient),
    planilhasHttpGateway: new PlanilhasHttpGateway(httpClient),
    paginasHttpGateway: new PaginasHttpGateway(httpClient),
});

export default configuracoesFactory;
